import React from 'react';
import Accordion from '../../../components/accordion';
import GoBtn from '../../../components/gobtn';
import NavHead from '../../../components/navhead';
import survival2 from '../../../img/erleada/mMGCHRPJ/survival2.svg';

const MgchrpjSurvival = () => {
  return (
    <div className="content_x__block">
      <NavHead title="Эффективность" />
      <div className="content_x mt-2 pl-2 pr-2">
        <div className="mt-2">
          <Accordion title="Общая выживаемость">
            <div className="content mt-2 pl-2 pr-2">
              <div style={{ position: 'relative', display: 'block' }}>
                <div style={{ paddingTop: '56%' }}>
                  <iframe
                    src="https://players.brightcove.net/1475651728001/default_default/index.html?videoId=6317982714112"
                    allowfullscreen=""
                    allow="encrypted-media"
                    style={{
                      position: 'absolute',
                      top: '1px',
                      right: '1px',
                      bottom: '1px',
                      left: '1px',
                      width: '98%',
                      height: '98%',
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
            <h3 className="mt-2">
              При раннем назначении комбинации Эрлеады+АДТ
            </h3>
            <p>
              Больше половины пациентов оставались живы в течение более 4.5 лет.
              Снижение риска смерти составило 48% (с учетом перехода 40%
              пациентов из группы плацебо в группу Эрлеада + АДТ).
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Выживаемость без рентгенологического прогрессирования">
            <div className="content mt-2 pl-2 pr-2">
              <div style={{ position: 'relative', display: 'block' }}>
                <div style={{ paddingTop: '56%' }}>
                  <iframe
                    src="https://players.brightcove.net/1475651728001/default_default/index.html?videoId=6347734288112"
                    allowfullscreen=""
                    allow="encrypted-media"
                    style={{
                      position: 'absolute',
                      top: '1px',
                      right: '1px',
                      bottom: '1px',
                      left: '1px',
                      width: '98%',
                      height: '98%',
                    }}
                  ></iframe>
                </div>
              </div>
            </div>

            <h4>При раннем назначении Эрлеады+АДТ </h4>
            <p>
              Больше половины пациентов не имели признаков рентенологического
              прогрессирования. Риск ренгенологического прогрессирования и
              смерти составил 52%.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Качество жизни">
            <div className="content mt-2 pl-2 pr-2">
              <div style={{ position: 'relative', display: 'block' }}>
                <div style={{ paddingTop: '56%' }}>
                  <iframe
                    src="https://players.brightcove.net/1475651728001/default_default/index.html?videoId=6347732924112"
                    allowfullscreen=""
                    allow="encrypted-media"
                    style={{
                      position: 'absolute',
                      top: '1px',
                      right: '1px',
                      bottom: '1px',
                      left: '1px',
                      width: '98%',
                      height: '98%',
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
            <h4 className="mt-2">
              Качество жизни при терапии комбинацией Эрлеада + АДТ
            </h4>
            <p className="mb-2">
              Комбинация Эрлеада + АДТ не снижает качество жизни пациентов с
              мГЧРПЖ, согласно опроснику FACT-P.
            </p>
            <h4 className="mb-2">
              Качество жизни, связанное со здоровьем, сохранялось в группе
              Эрлеада + АДТ и не отличалось от группы плацебо + АДТ
            </h4>
            <img
              src={survival2}
              style={{ width: '100%' }}
              alt="survival-graph2"
            />
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Сокращения">
            <ul>
              <li> АДТ – андрогенная депривационная терапия</li>
              <li>ДИ – доверительный интервал</li>
              <li>ОР – относительный риск</li>
              <li>ПБО – плацебо</li>
              <li>
                мГЧРПЖ – метастатический гормон-чувствительный рак
                предстательной железы
              </li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>
                Kim N. Chi et al, Journal of Clinical Oncology 39, no. 20 (July
                10, 2021) 2294-2303.DOI: 10.1200/JCO.20.03488.
              </li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default MgchrpjSurvival;
