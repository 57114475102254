<template>
  <div>
    <div class="wrap_bg">
      <section class="_section intro-component">
        <div class="wrap">
          <form-view
            v-if="showForm"
            class="form"
            @submit="submit"
            @show="showResult"
          />
          <greeting-view v-else />
        </div>
      </section>
    </div>
    <div class="result-legend__container">
      <div class="result-legend">
        <div v-if="showResults" class="result-legend__list">
          <div class="result-legend__list-item">
            <div class="result-legend__list-icon">
              <svg
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="16"
                  height="7.99951"
                  rx="3.99976"
                  fill="#98C7E0"
                />
              </svg>
            </div>
            <p>
              Голубая зона представляет собой доверительный интервал для каждой временной точки
            </p>
          </div>
          <div class="result-legend__list-item">
            <div class="result-legend__list-icon">
              <svg
                width="16"
                height="5"
                viewBox="0 0 16 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="2.5"
                  x2="16"
                  y2="2.5"
                  stroke="#2888BD"
                />
                <line
                  x1="12.2334"
                  x2="12.2334"
                  y2="5"
                  stroke="#2888BD"
                />
                <line
                  x1="4.7666"
                  x2="4.7666"
                  y2="5"
                  stroke="#2888BD"
                />
              </svg>
            </div>
            <p>Синяя линия обозначает цензурированные случаи в данной временной точке</p>
          </div>
          <div class="result-legend__list-item">
            <div class="result-legend__list-icon">
              <svg
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              />
            </div>
            <p>
              Цензурирование используется, когда не все наблюдения из выборки имеют полные данные
              о продолжительности выживаемости.
            </p>
          </div>
        </div>
        <div class="result-legend__reduction">
          <div
            class="result-legend__reduction-title"
            :class="[reductions ? '_active' : '']"
            @click="showReductions"
          >
            <p>Список сокращений</p>
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 0.795013L0 6.79501L1.41 8.20501L6 3.62501L10.59
              8.20501L12 6.79501L6 0.795013Z"
                fill="#003366"
              />
            </svg>
          </div>
          <div
            v-show="reductions"
            class="result-legend__reduction-list"
          >
            <div class="result-legend__reduction-item"><b>ПСА</b> – простат-специфический антиген</div>
          </div>
        </div>
        <div class="result-legend__literature">
          <div class="result-legend__literature-title">Используемая литература</div>
          <div class="result-legend__literature-list">
            <div class="result-legend__literature-item">
              <div>[1]</div>
              <p>
                Результаты анализа мировой базы данных больных РПЖ (US Health EMR Data)
                за 2016 – 2020 г.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @desc Показывает приветствие и по условию отображает
 * форму для заполнения данных о пациенте
 */
import { mapState } from 'vuex';
import store from '@/store/index';
import GreetingView from '@/components/intro-view/GreetingView';
import FormView from '@/components/common/form-view/';

export default {
  store,
  name: 'IntroView',
  components: {
    GreetingView,
    FormView,
  },
  data() {
    return {
      showResults: false,
      reductions: false,
    };
  },
  computed: {
    ...mapState({
      showForm: state => state.showForm,
    }),
  },
  methods: {
    /**
     * инициирует отображение формы
     */
    showReductions() {
      this.reductions = !this.reductions;
    },
    showResult() {
      this.showResults = !this.showResults;
    },
    submit() {
      this.$store.commit('SET_SHOW_RESULTS', true, { root: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.intro-component {
  background-color: #EFF2F3;
  padding-left: 32px;
  padding-right: 32px;
  .wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 866px;
    background-color: #eff2f3;
  }

  .form {
    @include _padding-around;
    @include _br4;
    width: 100%;
    background-color: $white;
  }

  .form-res {
    background-color: transparent;
    padding: 0;
  }

  .wrap-res {
    max-width: 1168px;
  }

  ::v-deep .buttons-select-component,
  ::v-deep .basic-select-comp,
  ::v-deep .input-select-component {
    display: flex;
    flex-direction: column;

    .title {
      margin-right: unset;
      margin-bottom: 8px;
    }
  }

  ::v-deep .choice-therapy-component {
    .button {
      white-space: pre-wrap;
      text-align: left;
    }
  }

  ::v-deep .additional-factor-component {
    .buttons-select-component {
      .buttons {
        flex-direction: column;
      }

      .button-component {
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }

  ::v-deep .detail-add-factor-component {
    .buttons-select {
      flex-direction: column;
    }

    .subtitle {
      margin-right: unset;
      margin-bottom: 8px;
    }

  }

  @include x-mobile {
    ::v-deep .buttons-select-component,
    ::v-deep .basic-select-comp,
    ::v-deep .input-select-component {
      flex-direction: row;

      .title {
        margin-right: 8px;
        margin-bottom: unset;
      }
    }

    ::v-deep .additional-factor-component {
      .buttons-select-component {
        .buttons {
          flex-direction: row;
        }

        .button-component {
          &:first-child {
            margin-bottom: unset;
            margin-right: 8px;
          }
        }
      }
    }

    ::v-deep .detail-add-factor-component {
      .buttons-select {
        flex-direction: row;
      }

      .subtitle {
        margin-right: 8px;
        margin-bottom: unset;
      }

    }
  }

  @include x-tablets {
    .wrap {
      flex-direction: row;
    }
  }
}

.result-legend {
  font-family: "Verdana Pro", sans-serif;
  padding-top: 40px;
  padding-bottom: 56px;
  width: 100%;
  &__container {
    display: flex;
  }

  &__list {
    padding-bottom: 32px;
    width: 100%;

    @media screen and (min-width: 960px) {
      width: 50%;
    }

    & > div {
      display: flex;
      flex-direction: row;
    }

    &-item {
      font-size: 12px;
      line-height: 14.58px;
      letter-spacing: -0.01em;
      color: #333333;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &-icon {
      width: 18px;
      margin-right: 8px;
    }
  }

  &__reduction {
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    padding: 16px;
    margin-bottom: 16px;
    cursor: pointer;

    @media screen and (min-width: 960px) {
      margin-bottom: 32px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 35px;
      color: #003366;

      @media screen and (min-width: 960px) {
        font-size: 25px;
      }

      &._active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &-list {
      padding-top: 22px;

      @media screen and (min-width: 960px) {
        padding-top: 32px;
      }
    }

    &-item {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.02em;

      @media screen and (min-width: 960px) {
        font-size: 16px;
      }

      b {
        font-weight: 600;
      }
    }
  }

  &__literature {
    &-title {
      font-weight: 600;
      color: #003366;
      font-size: 18px;
      margin-bottom: 16px;
      line-height: 30px;

      @media screen and (min-width: 960px) {
        line-height: 40.92px;
        margin-bottom: 32px;
        font-size: 31px;
      }
    }
    &-item {
      display: flex;
      flex-direction: row;
      div {
        font-size: 14px;
        line-height: 22px;
        color: #019CDC;
        margin-right: 12px;

        @media screen and (min-width: 960px) {
          margin-right: 24px;
        }
      }

      p {
        font-size: 12px;
        line-height: 22px;
        color: #606060;
      }
    }
  }
}

</style>
