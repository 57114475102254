import React from 'react';
import Accordion from '../../../components/accordion';
import GoBtn from '../../../components/gobtn';
import NavHead from '../../../components/navhead';
import survival1 from '../../../img/erleada/nmKRRPJ/survival1.svg';
import survival2 from '../../../img/erleada/nmKRRPJ/survival2.svg';
import survival3 from '../../../img/erleada/nmKRRPJ/survival3.svg';
import lifequality from '../../../img/erleada/nmKRRPJ/life-quality.webp';

const NmkrrpjSurvival = () => {
  return (
    <div className="content_x__block">
      <NavHead title="Эффективность" />
      <div className="content_x mt-2 pl-2 pr-2">
        <div className="mt-2">
          <Accordion title="Выживаемость без метастази­рования">
            <h4>Выживаемость без метастази­рования</h4>
            <p>72% снижение риска отдаленных метастазов или смерти</p>
            <p className="mb-2">
              Препарат ЭРЛЕАДА обеспечивает значимое улучшение выживаемости без
              метастазирования (ВБМ) у пациентов с нмКРРПЖ группы высокого риска
            </p>
            <img src={survival1} style={{ width: '100%' }} alt="survival1" />
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Время до симптоматического прогрессирования">
            <h4>Время до симптоматического прогрессирования</h4>
            <p>55% снижение риска симптоматического прогрессирования</p>
            <p className="mb-2">
              Комбинация ЭРЛЕАДА + АДТ обеспечивает снижение риска
              симптоматического прогрессирования более чем в два раза в
              сравнении с применением только АДТ
            </p>
            <img src={survival2} style={{ width: '100%' }} alt="survival2" />
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Общая выживаемость">
            <h4>Общая выживаемость</h4>
            <p>21.1* дополнительный месяц жизни</p>
            <p className="mb-2">
              Добавление Эрлеады к АДТ существенно увеличивает продолжительность
              жизни пациентов с нмКРРПЖ высокого риска
            </p>
            <img src={survival3} style={{ width: '100%' }} alt="survival3" />
            <p>
              * В проспективном исследовании III фазы приняли участие 1207
              пациента с неметастатическим КРРПЖ, 401 из которых были
              рандомизированы в группу плацебо. Согласно рекомендациям
              EAU-ESTRO-ESUR-SIOG, понятие кастрационной резистентности также
              включает в себя рентгенологическое прогрессирование: появление как
              минимум двух костных поражений на остеосцинтиграмме или одного
              мягкотканого поражения, соответствующего критериям RECIST
              (Response Evaluation Criteria in Solid Tumours, критерии оценки
              ответа на лечение для солидных опухолей). Однако в регистрационном
              исследовании (SPARTAN) учитывалось только прогрессирование по
              уровню ПСА, в то время как с рентгенологической точки зрения
              пациенты не имели метастазов (по данным КТ/остеосцинтиграфии).
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Качество жизни">
            <h4>
              Эрлеада позволяет сохранить качество жизни, связанное со
              здоровьем, на высоком уровне
            </h4>
            <ul style={{ listStyle: 'inside' }}>
              <li className="mb-1">
                У мужчин с выявленными нмКРРПЖ группы высокого риска качество
                жизни, связанное со здоровьем, было сравнимо с наблюдаемым у
                здоровых людей (США). В связи с этим, важно как можно дольше
                сохранять такое качество жизни у пациентов с преимущественно
                бессимптомным течением заболевания.
              </li>
              <li>
                В исследовании SPARTAN качество жизни, связанное со здоровьем,
                оценивалось с помощью опросников FACT-P и EQ-5D-3L.
              </li>
            </ul>

            <h4 className="mb-2 mt-2">
              Качество жизни (поисковая конечная точка)
            </h4>
            <img
              src={lifequality}
              style={{ width: '100%' }}
              alt="lifequality"
            />
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Сокращения">
            <ul>
              <li>АДТ – андрогендепривационная терапия</li>
              <li>ПБО – плацебо</li>
              <li>АПА – апалутамид</li>
              <li>ОР – относительный риск</li>
              <li>ДИ – доверительный интервал</li>
              <li>
                нмКРРПЖ – неметастатический кастрационно-резистентный рак
                предстательной железы
              </li>
              <li>США – Соединенные Штаты Америки</li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>Smith MR, et al. N Engl J Med. 2018; 378:1408–18.</li>
              <li>Smith MR, et al. J Clin Oncol. 2013; 31:3800–6.</li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default NmkrrpjSurvival;
