import React from 'react';
import GoBtn from '../components/gobtn';
import GoBtnArrow from '../components/gobtnarrow';
import NavHead from '../components/navhead';

const Balversa = () => {
  return (
    <>
      <div className="content_x__block">
        <div className="content_x">
          <NavHead title="Балверса" />
          <div className="content mt-2 pl-2 pr-2">
            <div>
              <GoBtnArrow
                url="/balversa/instruction"
                title="ОХЛП (Инструкция)"
                // ymt="page_mgchrpj"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/balversa/diagnostics"
                title="Диагностика"
                ymt="page_mechan"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/balversa/mechanism"
                title="Механизм действия"
                ymt="page_instruction"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/balversa/efficiency"
                title="Эффективность"
                // ymt="page_mgchrpj"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/balversa/safety"
                title="Безопасность"
                // ymt="page_mgchrpj"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/balversa/for-doctor"
                title="Материалы врачу "
                // ymt="page_mgchrpj"
              />
            </div>
          </div>
        </div>
        <div className="mt-2 pl-2 pr-2 back_btn_x">
          <p
            style={{ textAlign: 'right', marginBottom: '15px', color: 'grey' }}
          >
            СР-440198 | март 2024
          </p>
          <GoBtn title="Вернуться на главную" />
        </div>
      </div>
    </>
  );
};

export default Balversa;
