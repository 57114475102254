import React from 'react';
import Accordion from '../../components/accordion';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';

const BalversaMechanism = () => {
  return (
    <>
      <NavHead title="Механизм действия" />
      <div className="content mt-2 pl-2 pr-2">
        <h2>
          Какие виды злокачественных опухолей связаны с FGFR-аберрациями, и как
          их выявляют?
        </h2>
        <p>
          Изменения в генах, кодирующих рецепторы к факторам роста фибробластов
          (FGFR), - достаточно распространенный феномен, приводящий к развитию
          различных злокачественных опухолей за счет стойкой активации
          соответствующего сигнального пути и ряда онкогенных процессов.
          Поскольку патологические изменения сигнального пути FGFR часто
          наблюдаются при широком спектре онкологических заболеваний,
          FGFR-аберрации представляют собой потенциальную терапевтическую
          мишень. Чаще всего такие изменения наблюдаются при уротелиальном раке
          (УР), при этом аберрации генов FGFR были выявлены при раке мочевого
          пузыря всех степеней злокачественности и стадий. Понимание роли
          FGFR-аберраций в онкогенезе поможет вам в поиске оптимальных подходов
          к лечению ваших пациентов.
        </p>
        <div className="content mt-2 pl-2 pr-2 mb-2">
          <div style={{ position: 'relative', display: 'block' }}>
            <div style={{ paddingTop: '56%' }}>
              <iframe
                src="https://players.brightcove.net/1475651728001/default_default/index.html?videoId=6308862625112"
                allowfullscreen=""
                allow="encrypted-media"
                style={{
                  position: 'absolute',
                  top: '1px',
                  right: '1px',
                  bottom: '1px',
                  left: '1px',
                  width: '98%',
                  height: '98%',
                }}
              ></iframe>
            </div>
          </div>
        </div>
        <Accordion title="Источники">
          <ul>
            <li>Presta M et al. Pharmacol Ther 2017; 179: 171–187.</li>
            <li>Ahmad I et al. Biochim Biophys Acta 2012; 1823(4): 850–860.</li>
            <li>Helsten T et al. Clin Cancer Res 2016; 22(1): 259–267.</li>
            <li>Corn PG et al. Clin Cancer Res 2013; 19(21): 5856–5866.</li>
            <li>Di Martino E et al. Future Oncol 2016; 12(19): 2243–2263.</li>
            <li>
              Mosele F et al. Ann Oncol 2020; S0923-7534(20): 39971–39973.
            </li>
            <li>Malone ER et al. Genome Med 2020; 12: 8.</li>
          </ul>
        </Accordion>
        <h2>Типы FGFR-аберраций и механизмы онкогенеза</h2>
        <p>
          Большинство аберраций генов FGFR относятся к «мутациям с приобретением
          функции» (gain-of-function) и включают:
        </p>
        <ul style={{ listStyle: 'inside' }}>
          <li>
            Активирующие мутации FGFR во внеклеточном, трансмембранном или
            тирозинкиназном доменах рецептора
          </li>
          <li>Хромосомные перестройки</li>
          <li>
            Амплификацию, транслокации генов FGFR или нарушения регуляции
            транскрипции
          </li>
        </ul>
        <p>
          Сигнальные пути, запускающиеся в результате активации FGFR, приводят к
          онкогенезу посредством нескольких лиганд-зависимых и
          лиганд-независимых механизмов:
        </p>
        <ul style={{ listStyle: 'inside' }}>
          <li>
            Гиперэкспрессия соответствующего белка вследствие амплификации
            одного из генов FGFR
          </li>
          <li>
            Лиганд-независимая димеризация FGFR вследствие активирующих мутаций
          </li>
          <li>
            Лиганд-независимая гиперактивация рецепторов вследствие хромосомных
            транслокаций
          </li>
          <li>
            Аутокринная или паракринная генерация сигнала вследствие синтеза FGF
            опухолевыми клетками или стромой опухоли соответственно
          </li>
          <li>
            Ангиогенез или эпителиально-мезенхимальный переход (ЭМП) вследствие
            секреции FGF опухолевыми клетками
          </li>
          <li>
            Гиперактивация FGFR-опосредованных сигнальных путей в результате
            амплификации генов или гиперэкспрессии белков-партнеров FGFR по
            связыванию
          </li>
        </ul>

        <h2>Виды злокачественных опухолей, связанных с FGFR-аберрациями</h2>
        <div className="content mt-2 pl-2 pr-2 mb-2">
          <div style={{ position: 'relative', display: 'block' }}>
            <div style={{ paddingTop: '56%' }}>
              <iframe
                src="https://players.brightcove.net/1475651728001/default_default/index.html?videoId=6307758816112"
                allowfullscreen=""
                allow="encrypted-media"
                style={{
                  position: 'absolute',
                  top: '1px',
                  right: '1px',
                  bottom: '1px',
                  left: '1px',
                  width: '98%',
                  height: '98%',
                }}
              ></iframe>
            </div>
          </div>
        </div>
        <p>
          Аберрации в генах FGFR характерны для широкого спектра злокачественных
          новообразований (ЗНО) и представляют собой важную потенциальную мишень
          для терапии. По результатам анализа 853 случаев ЗНО FGFR-аберрации
          встречаются с указанной ниже частотой при следующих видах патологии:
        </p>
        <ul style={{ listStyle: 'inside' }}>
          <li>уротелиальный рак (31,7%)</li>
          <li>рак молочной железы (17,4%)</li>
          <li>рак эндометрия (11,3%)</li>
          <li>рак яичников (8,6%)</li>

          <li>глиома (7,6%)</li>
          <li>холангиокарцинома (7,0%)</li>
          <li>немелкоклеточный рак легкого (5,2%)</li>
          <li>рак головы и шеи (4,6%)</li>

          <li>саркома (4,0%)</li>
        </ul>

        <h2>
          Для разных типов ЗНО могут быть характерны разные виды FGFR-аберраций:
        </h2>
        <ul style={{ listStyle: 'inside' }}>
          <li>
            Амплификация гена FGFR1 чаще наблюдается при плоскоклеточном раке
            легкого, раке молочной железы, раке яичников и уротелиальном раке
          </li>
          <li>
            Мутации гена FGFR3 чаще наблюдаются при раке мочевого пузыря и
            прочих видах уротелиального рака
          </li>
        </ul>

        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>Helsten T et al. Clin Cancer Res 2016; 22(1): 259–267.</li>
              <li>Presta M et al. Pharmacol Ther 2017; 179: 171–187.</li>
              <li>Corn PG et al. Clin Cancer Res 2013; 19(21): 5856–5866.</li>
              <li>Yang J et al. Mol Cancer 2019; 18(1): 26.</li>
              <li>
                Babina IS, Turner NC. Nat Rec Cancer 2017; 17(5): 318–332.
              </li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default BalversaMechanism;
