import React, { useState, useRef, useEffect } from 'react';
import GoBtn from '../components/gobtn';
import SaveBtn from '../components/savebtn';
import NavHead from '../components/navhead';
import ModalSendPDF from '../components/modalSendPDF';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import ym from 'react-yandex-metrika';

const Calc = ({ url }) => {
  let userId;

  useEffect(() => {
    if (window.location.href.includes('tguser_data=')) {
      userId = window.location.href.split('tguser_data=')[1].split('__')[0];
    }
    if (userId && userId.includes('&')) {
      userId = userId.split('&')[0];
    }

    if (
      !localStorage.getItem('jnj-user-id') ||
      (userId && userId !== localStorage.getItem('jnj-user-id'))
    ) {
      localStorage.setItem('jnj-user-id', userId);
    }
  }, [userId]);

  const [dataList, setDataList] = useState([
    { id: 0, startDate: '', psa: '' },
    { id: 1, startDate: '', psa: '' },
  ]);
  const [startDate, setStartDate] = useState('');
  const [calcResult, setCalcResult] = useState('');
  const [psa, setPsa] = useState('');
  const [showGraph, setShowGraph] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const addDatePicker = () => {
    setDataList((dataList) => [
      ...dataList,
      { id: dataList.length, startDate: '', psa: '' },
    ]);
  };
  const resetDatePicker = () => {
    setShowResult(false);
    setDataList([
      { id: 0, startDate: '', psa: '' },
      { id: 1, startDate: '', psa: '' },
    ]);
  };
  const formatDate = (date) => {
    if (date) {
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let yyyy = date.getFullYear();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      const formattedDate = yyyy + '-' + mm + '-' + dd;
      return formattedDate;
    }
  };
  const dataForGraph = dataList
    .map((item) => ({
      psa: item.psa,
      name: formatDate(item.startDate),
    }))
    .sort((a, b) => (Date.parse(a.name) > Date.parse(b.name) ? 1 : -1));
  const calculatePSA = () => {
    ym('reachGoal', 'upsa_calculate');
    setShowResult(true);
    let months = new Date(
      Date.parse(dataForGraph[dataForGraph.length - 1].name) -
        Date.parse(dataForGraph[0].name)
    ).getMonth();
    let psa1 = Number(dataForGraph[0].psa);
    let psa2 = Number(dataForGraph[dataForGraph.length - 1].psa);
    let result;
    if (months === 0) {
      months = 1;
    }
    result = (Math.log(2) * months) / (Math.log(psa2) - Math.log(psa1));
    setCalcResult(result.toFixed(4));
  };
  const graphRef = useRef();
  const sendPDF = () => {
    html2canvas(graphRef.current, {
      // x: 0,
      y: -10,
      width: 300,
      height: 250,
      scrollY: -window.scrollY,
      scrollX: -window.scrollX,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
      });
      pdf.addImage(imgData, 'JPEG', 0, 0);
      let formData = new FormData();
      formData.append('user_id', localStorage.getItem('jnj-user-id'));
      formData.append('file_pdf', pdf.output('blob'), 'graph.pdf');
      axios
        .post(`${url}/api/webapp/send_pdf`, formData)
        .then((res) => {
          console.log(res);
          setIsModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setIsModalOpen(false);
        });
      // pdf.save("graph.pdf");
      // window.open(pdf.output("bloburl", { filename: "graph.pdf" }), "_blank");
    });
  };
  return (
    <>
      <div className="content_x__block">
        <NavHead title="Калькулятор времени удвоения ПСА" />
        <div className="content_x">
          <div className="calc">
            <div className="calc-item pd-2">
              <div className="calc-item-title">
                <h3 className>Дата</h3>
                <h3 className>ПСА</h3>
              </div>
              {dataList.map((item) => (
                <div className="flex gap-2 mt-2" key={item.id}>
                  <DatePicker
                    id={item.id}
                    dateFormat="dd/MM/20yy"
                    selected={item.startDate}
                    onChange={(date) => setStartDate((item.startDate = date))}
                    placeholderText="дд.мм.гггг"
                    customInput={
                      <MaskedTextInput
                        type="text"
                        mask={[
                          /[1-3]/,
                          /\d/,
                          '/',
                          /[0-1]/,
                          /\d/,
                          '/',
                          2,
                          0,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    }
                  />
                  <input
                    type="number"
                    value={item.psa}
                    onChange={(e) => setPsa((item.psa = e.target.value))}
                  />
                </div>
              ))}
              <button className="add mt-2" onClick={addDatePicker}>
                + Добавить
              </button>
              <div className="flex gap-2 mt-2">
                <button className="clear" onClick={resetDatePicker}>
                  Сбросить
                </button>
                <button className="send" onClick={calculatePSA}>
                  Расчитать
                </button>
              </div>
            </div>
            {showResult && (
              <div className="graph mt-2">
                <h3>Результаты</h3>
                <p className="mb-2">
                  Время удвоения ПСА <b>{calcResult}</b>
                </p>
                {showGraph === false ? (
                  <button
                    className="hide"
                    onClick={() => {
                      ym('reachGoal', 'show_graph');
                      setShowGraph(true);
                    }}
                  >
                    Показать на графике
                  </button>
                ) : (
                  <>
                    <div ref={graphRef}>
                      <LineChart width={300} height={200} data={dataForGraph}>
                        <Line type="monotone" dataKey="psa" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="name" />
                        <YAxis />
                      </LineChart>
                    </div>
                    <button
                      className="hide"
                      onClick={() => setShowGraph(false)}
                    >
                      Скрыть график
                    </button>
                    <div className="flex justify-center" onClick={sendPDF}>
                      <SaveBtn />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <ModalSendPDF
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
        <div className="mt-2 mb-2 pl-2 pr-2 back_btn_x">
          <GoBtn title="Вернуться на главную" />
        </div>
      </div>
    </>
  );
};

export default Calc;
