import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import prevIcon from '../img/prev.svg';
import nextIcon from '../img/next.svg';
// import backwardIcon from "../img/back.svg";
// import forwardIcon from "../img/forw.svg";
import playIcon from '../img/play.svg';
import pauseIcon from '../img/paus.svg';
import ym from 'react-yandex-metrika';

const ControlsAudio = ({ audio, maxId, minId }) => {
  const { podcastId } = useParams();
  const navigate = useNavigate();

  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // references
  const progressBar = useRef();
  const animationRef = useRef();

  useEffect(() => {
    (audio.onloadedmetadata = () => {
      const seconds = Math.floor(audio.duration);
      setDuration(seconds);
      progressBar.current.max = seconds;
    })();
  }, [audio?.onloadedmetadata, audio?.readyState, audio]);
  useEffect(() => {
    return () => {
      navigate(0);
    };
  }, [navigate]);
  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      ym('reachGoal', 'play_podcast');
      audio.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audio.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audio.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    progressBar.current.value = audio.currentTime;

    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(progressBar.current.value / duration) * 100}%`
    );
    setCurrentTime(audio.currentTime);
  };

  //   const backThirty = () => {
  //     progressBar.current.value = Number(progressBar.current.value - 10);
  //     changeRange();
  //   };

  //   const forwardThirty = () => {
  //     progressBar.current.value = Number(progressBar.current.value + 10);
  //     changeRange();
  //   };

  const PauseOnPodcastChange = () => {
    audio.pause();
    audio.currentTime = 0;
    setIsPlaying(false);
  };

  const StopPodcast = () => {
    PauseOnPodcastChange();
    audio.stop();
  };
  return (
    <div>
      <audio src={audio} />
      <input
        type="range"
        className="progressBar"
        defaultValue="0"
        ref={progressBar}
        onChange={changeRange}
      />
      <div className="times">
        <p>{calculateTime(currentTime)}</p>
        <p>{duration ? calculateTime(duration) : '00:00'}</p>
      </div>
      <div className="controls">
        {podcastId.slice(1) > minId ? (
          <Link
            to={`/media/podcastList/:${Number(podcastId.slice(1)) - 1}`}
            onClick={StopPodcast}
          >
            <img src={prevIcon} alt="player-icon" />
          </Link>
        ) : (
          <Link to={`/media/podcastList/:${maxId}`} onClick={StopPodcast}>
            <img src={prevIcon} alt="player-icon" />
          </Link>
        )}

        {/* <img src={backwardIcon} onClick={backThirty} /> */}
        {isPlaying === false ? (
          <button onClick={togglePlayPause}>
            <img src={playIcon} alt="player-icon" />
            Воспроизвести
          </button>
        ) : (
          <button onClick={togglePlayPause}>
            <img src={pauseIcon} className="pause" alt="player-icon" />
            Остановить
          </button>
        )}
        {/* <img src={forwardIcon} onClick={forwardThirty} /> */}
        {podcastId.slice(1) < maxId ? (
          <Link
            to={`/media/podcastList/:${Number(podcastId.slice(1)) + 1}`}
            onClick={StopPodcast}
          >
            <img src={nextIcon} alt="player-icon" />
          </Link>
        ) : (
          <Link to={`/media/podcastList/:${minId}`} onClick={StopPodcast}>
            <img src={nextIcon} alt="player-icon" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default ControlsAudio;
