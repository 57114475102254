import React from 'react';

export default function NoAccess() {
  return (
    <div className="flex flex-column justify-center aling-center noaccess gap-2">
      <p className="text">
        Извините, но мы не смогли найти ваши данные в базе 🧐
      </p>
      <p className="text">
        Чтобы продолжить, пожалуйста, отправьте в бот команду{' '}
        <span className="span">/start</span>
      </p>
    </div>
  );
}
