import React from 'react';
import GoBtn from '../../../components/gobtn';
import NavHead from '../../../components/navhead';
import schema from '../../../img/erleada/mMGCHRPJ/schema.webp';
import Accordion from '../../../components/accordion';

const MgchrpjAbout = () => {
  return (
    <div className="content_x__block">
      <NavHead title="О заболевании" />
      <div className="content_x mt-2 pl-2 pr-2">
        <h2>Сохранение чувствительности к гормональной терапии</h2>
        <p>
          Отсутствие кастрационной резистентности в виде биохимического рецидива
          и/или рентгенологического прогрессирования на фоне продолжающейся АДТ
          при сохранении кастрационного уровня тестостерона (кастрационный
          уровень тестостерона - 20 нг/дл)
        </p>
        <h2>Выявленные метастазы по данным методов визуализации</h2>
        <p>
          Сцинтиграфии скелета и/или КТ грудной клетки, брюшной полости и таза*
        </p>
        <h2>Выделяют две когорты пациентов с мГЧРПЖ:</h2>
        <ul style={{ listStyle: 'inside' }} className="mb-2">
          <li>
            Пациенты с РПЖ, первично диагностированным на стадии
            метастазирования
          </li>
          <li>
            Пациенты с прогрессированием ранее диагностированного
            локализованного или местнораспространенного РПЖ (рецидивный мГЧРПЖ)
          </li>
        </ul>

        <img src={schema} alt="schema" style={{ width: '100%' }} />

        <p>
          * Данные методы визуализации использовались в основных крупных
          клинических исследованиях по мГЧРПЖ. В настоящее время доступны более
          чувствительные методы для выявления метастазов, однако их клиническая
          значимость еще не определена.
        </p>
        <div className="mt-2">
          <Accordion title="Сокращения">
            <ul>
              <li>АДТ – андрогенная депривационная терапия</li>
              <li>КТ – компьютерная томография</li>
              <li>РПЖ – рак предстательной железы</li>
              <li>
                мГЧРПЖ – метастатический гормон-чувствительный рак
                предстательной железы
              </li>
              <li>
                нмКРРПЖ – неместатический кастрационно-резистентный рак
                предстательной железы
              </li>
              <li>
                мКРРПЖ – метастатический кастрационно-резистентный рак
                предстательной железы
              </li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>
                Клинические рекомендации «Рак предстательной железы», МЗ РФ,
                2021
              </li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default MgchrpjAbout;
