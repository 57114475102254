import React from 'react';
import Accordion from '../../components/accordion';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import safety1 from '../../img/balversa/safety1.png';
import safety2 from '../../img/balversa/safety2.png';
import safety3 from '../../img/balversa/safety3.png';
import safety4 from '../../img/balversa/safety4.png';

const BalversaSafety = () => {
  return (
    <>
      <NavHead title="Безопасность" />
      <div className="content mt-2 pl-2 pr-2">
        <p>
          Безопасность препарата Балверса (эрдафитиниб) изучалась в
          сравнительном рандомизированном исследовании III фазы THOR (BLC3001).
        </p>
        <p>Дизайн исследования THOR, 1 когорта:</p>
        <ul style={{ listStyle: 'inside' }}>
          <li>Наличие FGFR-аберраций</li>
          <li>1 или 2 линии предшествующей терапии</li>
          <li>Предшествующая терапия анти-PD-L(1)</li>
          <li>
            Метастатический или нерезектабельный местно-распространенный
            уротелиальный рак
          </li>
          <li>Документированный PD-статус</li>
          <li>ECOG 0-2</li>
          <li>Адекватные функции костного мозга, печени, почек</li>
        </ul>
        <p>
          Профили безопасности в исследовании соответствовали имеющимся данным
          для эрдафитиниба и химиотерапии. В большинстве случаев нежелательные
          реакции на фоне эрдафитиниба поддавались коррекции путем модификации
          дозы и поддерживающей терапии.
        </p>
        <p>Распределение серьезных нежелательных реакций между группами:</p>
        <img src={safety1} style={{ width: '100%' }} />
        <p>
          Основные нежелательные реакции на эрдафитинибе и в группе сравнения:
        </p>
        <img src={safety2} style={{ width: '100%' }} />
        <img src={safety3} style={{ width: '100%' }} />
        <p>
          <sup>a</sup> НР указаны, если НР любой степени имели место у ≥30%
          пациентов в группе эрдафитиниба или если НР 3-4 степени имели место у
          ≥ 5% пациентов.
        </p>
        <p>
          <sup>b</sup> НР, наиболее часто приводившие к прекращению терапии
          эрдафитинибом, включали офтальмологические расстройства (n = 3) и
          патологию кожи и подкожной клетчатки (n = 3).
        </p>
        <p>
          <sup>c</sup> НР по предпочтительному термину указаны, если НР любой
          степени имела место у ≥20% пациентов в группе химиотерапии или если НР
          3-4 степени имели место у ≥5% пациентов.
        </p>
        <p>
          <sup>d</sup> НР, наиболее часто приводившие к прекращению терапии в
          группе химиотреапии, включали расстройства со стороны крови и
          лимфатической системы (n = 5), инфекции и инвазии (n = 3), аплазию (n
          = 2), фебрильную нейтропению (n = 1), септический шок (n = 2) и
          атипичную пневмонию (n = 1). НР – нежелательная реакция; ХТ –
          химиотерапия.
        </p>
        <p>
          Нежелательные явления, представлявшими особый интерес при
          использовании эрдафитиниба, - патология ногтей, кожи и глаз, включая
          центральную серозную ретинопатию:
        </p>
        <img src={safety4} style={{ width: '100%' }} />
        <p>
          <sup>a</sup> Патология ногтей: кровоточивость ногтевого ложа,
          изменение цвета ногтей, расстройство со стороны ногтей, дистрофия
          ногтей, исчерченность или бугристость ногтей, ногтевая токсичность,
          боль в области ногтей, ломкость ногтей, онихолизис, паронихий,
          расслоение ногтей;
        </p>
        <p>
          <sup>b</sup> Патология кожи: пузыри, сухость кожи, эритема,
          гиперкератоз, ладонная эритема, синдром ладонно-подошвенной
          эритродизэстезии, подошвенная эритема, сыпь, эритематозная сыпь,
          генерализованная сыпь, пятнистая сыпь, пятнисто-папулёзная сыпь,
          атрофия кожи, шелушение кожи, кожные трещины, повреждение кожи, кожная
          язва, токсическая кожная сыпь, ксеродерма;
        </p>
        <p>
          <sup>c</sup> Патология глаз (кроме ЦСР): блефарит, катаракта,
          подкапсульная катаракта, кровоизлияние в конъюнктиву, гиперемия
          конъюнктивы, раздражение конъюнктивы, эрозия роговицы, инфильтраты в
          роговице, сухость глаз, воспаление глаз, раздражение глаз, боль в
          области глаз, ощущение инородного тела в глазу, кератит, усиленное
          слезотечение, ночная слепота, гиперемия глаз, светобоязнь,
          затуманенность зрения, снижение остроты зрения, нарушение зрения,
          ксантопсия, ксерофтальмия, хориоретинит, конъюнктивит, язвенный
          кератит;
        </p>
        <p>
          <sup>d</sup> ЦСР: отслойка сетчатки, отслойка стекловидного тела, отек
          сетчатки, ретинопатия, хориоретинопатия, отслойка пигментного эпителия
          сетчатки, отслойка пигментного эпителия желтого пятна сетчатки,
          отслойка макулы, серозная отслойка сетчатки, подсетчаточный отек,
          утолщение сетчатки, хориоретинит, серозная ретинопатия, макулопатия,
          хориоидальный выпот.
        </p>
        <p>
          НЯ – нежелательное явление; ЦСР – центральная серозная ретинопатия.
        </p>
        <p>
          Эрдафитиниб имеет управляемый профиль безопасности на основе
          соблюдения четких правил профилактики НЯ и коррекции дозирования.
        </p>
      </div>
      <div className="mt-2">
        <Accordion title="Источники">
          <ul>
            <li>
              Loriot Y., et al. N Engl J Med. 2023 Nov 23;389(21):1961-1971.
              doi: 10.1056/NEJMoa2308849. Epub 2023 Oct 21.
            </li>
          </ul>
        </Accordion>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default BalversaSafety;
