import React, { useState } from "react";
const Accordion = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`accordion ${expanded ? "is-open" : "no-open"}`}>
      <div
        onClick={() => setExpanded(!expanded)}
        className="head flex align-center justify-between"
      >
        <h4 className="title">{props.title}</h4>
        <button
          className={`icon ${expanded ? "is-open" : "no-open"}`}
          onClick={() => setExpanded(!expanded)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
          >
            <path
              d="M15.6834 1.78341L8.59103 8.77189C8.50659 8.85484 8.41513 8.91346 8.31662 8.94774C8.21812 8.98258 8.11258 9 8 9C7.88742 9 7.78188 8.98258 7.68338 8.94774C7.58487 8.91346 7.4934 8.85484 7.40897 8.77189L0.295514 1.78341C0.0985047 1.58986 0 1.34793 0 1.0576C0 0.767282 0.105541 0.518434 0.316623 0.31106C0.527704 0.103687 0.773966 0 1.05541 0C1.33685 0 1.58311 0.103687 1.7942 0.31106L8 6.40783L14.2058 0.31106C14.4028 0.117512 14.6454 0.0207376 14.9336 0.0207376C15.2224 0.0207376 15.4723 0.124424 15.6834 0.331798C15.8945 0.53917 16 0.781106 16 1.0576C16 1.3341 15.8945 1.57604 15.6834 1.78341Z"
              fill="#327CD1"
            />
          </svg>
        </button>
      </div>
      {expanded && <div className="content">{props.children}</div>}
    </div>
  );
};

export default Accordion;
