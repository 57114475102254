import React, { useEffect, useState } from 'react';
import GoBtn from '../components/gobtn.js';
import NavHead from '../components/navhead.js';
// import axios from 'axios';
import '../components/onko_030624/jansen-onko.js';

const ProstateAnalysis = ({ url }) => {
  return (
    <div className="content_x__block">
      <NavHead />

      <jansen-onko></jansen-onko>

      <div className="mt-2 pl-2 pr-2 back_btn_x">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ProstateAnalysis;
