import React from 'react';
import Accordion from '../../components/accordion';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import img1 from '../../img/balversa/image1.png';
import img2 from '../../img/balversa/image2.png';
import img3 from '../../img/balversa/image3.png';

const BalversaDiagnostics = () => {
  return (
    <>
      <NavHead title="Диагностика" />
      <div className="content mt-2 pl-2 pr-2">
        <p>
          Клинический молекулярно-генетический анализ стал незаменимым
          лабораторным методом, который используется для изучения биологии
          опухоли и определяет выбор терапии. Может ли генетическое тестирование
          помочь в выявлении молекулярных драйверов опухолевого роста у ваших
          пациентов
        </p>
        <h2>
          Генетическое тестирование распространенных злокачественных опухолей
        </h2>
        <img src={img1} style={{ width: '40%', marginLeft: '30%' }} />
        <p>
          Потенциальные преимущества рутинного генетического секвенирования при
          метастатических опухолях признаны в клинических рекомендациях. В
          настоящее время Европейское общество специалистов по лекарственной
          терапии злокачественных опухолей (ESMO) рекомендует рутинное
          выполнение секвенирования нового поколения (NGS) при распространенных
          неплоскоклеточном немелкоклеточном раке легких, раке простаты, раке
          яичников и холангиокарциноме. Сегодня при распространенном
          уротелиальном раке (УР) молекулярные биомаркеры пока еще не
          используются в рутинной клинической практике. Действующие рекомендации
          NCCN (Национальной онкологической сети США) и EAU (Европейской
          урологической ассоциации) предписывают выполнение FGFR-тестирования
          при распространенном УР сразу после его диагностирования для
          дальнейшего планирования оптимальной терапии.
        </p>
        <h2>Применяемые методы молекулярно-генетического тестирования</h2>
        <p>
          Широко применяемые методы исследований, используемые для обнаружения
          генетических аберраций, включают NGS и полимеразную цепную реакцию
          (ПЦР) в реальном времени. Аберрации генов, таких как FGFR, могут быть
          обнаружены с помощью NGS, которое позволяет одновременно исследовать
          несколько геномных локусов на предмет генных мутаций . В свою очередь,
          диагностические исследования на основе ПЦР в реальном времени можно
          использовать для выявления конкретных заранее определенных генных
          мутаций.
        </p>
        <h2>
          Анализ на генетические аберрации может улучшить результаты лечения и
          планирование терапии.
        </h2>
        <p>
          Будущее лечения УР – за мультидисциплинарным подходом с тесным
          сотрудничеством между различными специалистами, совместно
          разрабатывающими обоснованные планы ведения пациентов.
        </p>
        <h2>
          Специалист по лабораторной диагностике чрезвычайно важен для
          разработки подхода к лечению ваших онкологических пациентов:
        </h2>
        <img src={img2} style={{ width: '40%', marginLeft: '30%' }} />
        <ul style={{ listStyle: 'inside' }}>
          <li>
            Только он может точно диагностировать УР и определить тип опухоли,
            что имеет решающее значение для достижения наилучших результатов
            лечения ваших пациентов.
          </li>
          <li>
            Обсудите с патологоанатом в вашем ЛПУ, что необходимо для правильной
            подготовки образцов опухолевой ткани, которые предназначены для
            направления на молекулярно-генетическое исследование.
          </li>
        </ul>
        <img src={img3} style={{ width: '40%', marginLeft: '30%' }} />
        <h2>
          При составлении плана тестирования важно находиться в контакте с
          лечащим урологом, чтобы обеспечить доступность биологических образцов
          достаточно высокого качества:
        </h2>
        <ul style={{ listStyle: 'inside' }}>
          <li>
            В целом в онкологии важно обеспечить наличие достаточного количества
            качественных образцов опухолевой ткани для выполнения адекватного
            тестирования. Следует рассмотреть различные протоколы подготовки
            образцов и технологии, используемые для проведения анализов методом
            ПЦР
          </li>
          <li>
            Как правило, для проведения анализов методами NGS и ПЦР в реальном
            времени в качестве исследуемого материала требуются образцы ткани,
            фиксированные в формалине и залитые парафином (FFPE).
          </li>
          <li>
            Образцы РНК для генетического тестирования могут быть получены из
            тканей уротелиальной карциномы, фиксированных в формалине и залитых
            парафином, с использованием таких наборов как QIAGEN RNeasy® (набор
            для приготовления образцов для исследования из FFPE).
          </li>
        </ul>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>Malone ER et al. Genome Med 2020; 12: 8. </li>
              <li>
                Mosele F et al. Ann Oncol 2020; S0923-7534(20): 39971–39973.
              </li>
              <li>
                Erlanger Health system. Molecular profiling. Available from:
                https://www.erlanger.org/centers-of-excellence/cancer-services/cancer-services/molecular-profiling#:~:text=Molecular%20profiling%E2%80%94or%20%E2%80%9Ctumor%20genomic,been%20acquired%20by%20these%20cells.
                Accessed: January 2022.
              </li>
              <li>Flaig et al. J Natl Compr Canc Netw</li>
              <li>Mendiratta P, Grivas P. Ann Transl Med 2018; 6(12): 250.</li>
              <li>
                Gopalakrishnan D et al. Ther Clin Risk Manag 2018; 14:
                1019–1040.
              </li>
              <li>
                Cathomas R et al. Eur Urol 2021; DOI:
                10.1016/j.eururo.2021.09.026.
              </li>
              <li>Cheng YW et al. Respir Med Case Rep 2019; 28: 100901.</li>
              <li>
                TruSight™ Tumor 170. Available at:
                https://emea.illumina.com/content/dam/illumina-marketing/documents/products/datasheets/trusight-tumor-170-data-sheet-1170-2016-017.pdf.
                Accessed: January 2022.
              </li>
              <li>
                AVENIO ctDNA Expanded Kit. Available at:
                https://sequencing.roche.com/content/dam/rochesequence/worldwide/resources/brochure-avenio-ctdna-expanded-kit-SEQ100047.pdf.
                Accessed: January 2022.
              </li>
              <li>Flaig et al. J Natl Compr Canc Netw 2020; 18(3): 329–354.</li>
              <li>
                FoundationOne®CDx. Available at:
                https://www.foundationmedicine.com/test/foundationone-cdx.
                Accessed: January 2022.
              </li>
              <li>
                Analytical Validation of the Oncomine™ Comprehensive Assay v3
                with FFPE and Cell Line Tumor Specimens in a CAP-Accredited and
                CLIA-Certified Clinical Laboratory. Available at:
                http://assets.thermofisher.com/TFS-Assets/CSD/posters/analytical-validation-of-oncomine-comprehensive-assay-v3-in-clinical-lab-poster.pdf.
                Accessed: January 2022.
              </li>
              <li>Duffy et al. Cancer Treat Rev 2011; 37(2): 151–159.</li>
              <li>
                EAU guidelines on the management of muscle-invasive and
                metastatic bladder cancer. Available at:
                https://uroweb.org/guideline/bladder-cancer-muscle-invasive-and-metastatic/#1.
                Accessed: January 2022.
              </li>
              <li>
                Harshman L et al. Clin Genitourin Cancer 2018; 16(3): 213–218.
              </li>
              <li>
                The Pathologist's role. Available at:
                https://pathology.jhu.edu/bladder-cancer/the-pathologists-role.
                Accessed: January 2022.
              </li>
              <li>
                Qiagen. Инструкция по использованию набора (руководство) RNeasy®
                DSP FFPE Kit, версия 1. Август 2017 г.
              </li>
              <li>
                Cystoscopy in bladder carcinoma. Available from:
                https://emedicine.medscape.com/article/1950345-overview.
                Accessed: January 2022.
              </li>
              <li>Gregg et al. Transl Lung Cancer Res 2019; 8(3): 286–301.</li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default BalversaDiagnostics;
