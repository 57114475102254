import React from 'react';
import GoBtn from '../../../components/gobtn';
import NavHead from '../../../components/navhead';
import riskGraph from '../../../img/erleada/nmKRRPJ/risk.svg';
import nmKRRPJImage from '../../../img/erleada/nmKRRPJ/nmKRRPJ.webp';
import KRRPJImage from '../../../img/erleada/nmKRRPJ/graph.png';
import Accordion from '../../../components/accordion';

const NmkrrpjAbout = () => {
  return (
    <div className="content_x__block">
      <NavHead title="О заболевании" />
      <div className="content_x mt-2 pl-2 pr-2">
        <h2>Определение группы высокого риска:</h2>
        <p>
          Считается, что пациент имеет высокий риск метастазирования или смерти
          при времени удвоении уровня ПСА (ВУПСА ≤10 месяцев).
        </p>
        <h2>Определение высокого риска в исследовании SPARTAN:</h2>
        <p className="mb-2">
          В исследовании SPARTAN критерием высокого риска считалось ВУПСА ≤ 10
          месяцев на фоне непрерывной андроген-депривационной терапии (АДТ).*
        </p>
        <img src={riskGraph} style={{ width: '100%' }} alt="riskGraph" />
        <p>
          Неметастатический кастрационно-резистентный рак предстательной железы
          (нмКРРПЖ) с высоким риском метастазов (время удвоения ПСА ≤ 10
          месяцев).
        </p>
        <p>
          * В проспективном исследовании III фазы приняли участие 1207 пациента
          с неметастатическим КРРПЖ, 401 из которых были рандомизированы в
          группу плацебо.
        </p>
        <p>
          Согласно рекомендациям EAU-ESTRO-ESUR-SIOG, понятие кастрационной
          резистентности также включает в себя рентгенологическое
          прогрессирование: появление как минимум двух костных поражений на
          остеосцинтиграмме или одного мягкотканого поражения, соответствующего
          критериям RECIST (Response Evaluation Criteria in Solid Tumours,
          критерии оценки ответа на лечение для солидных опухолей).
        </p>
        <p>
          Однако в регистрационном исследовании (SPARTAN) учитывалось только
          прогрессирование по уровню ПСА, в то время как, с рентгенологической
          точки зрения, пациенты не имели метастазов (по данным
          КТ/остеосцинтиграфии).
        </p>
        <h2>Критерий нмКРРПЖ в исследовании SPARTAN:</h2>
        <p className="mb-2">
          Считается, что пациент имеет высокий риск метастазирования или смерти
          при времени удвоении уровня ПСА (ВУПСА ≤ 10 месяцев).
          <br />
          Кожные симптомы (кожный зуд, покраснение) <br />
          Допускалось наличие определяемых метастазов в лимфатические узлы ниже
          уровня бифуркации аорты при их диаметре менее 2 см (N1).
        </p>
        <img src={nmKRRPJImage} style={{ width: '100%' }} alt="nmKRRPJImage" />
        <h2>Критерии кастрационно-резистентного рака:</h2>
        <ul style={{ listStyle: 'inside' }}>
          <li>
            Рак предстательной железы (РПЖ) соответствует
            кастрационно-резистентной (КР) стадии, если повышение уровня ПСА
            наблюдается на фоне андрогенной депривационной терапии (АДТ).
          </li>
          <li>
            Биохимическое прогрессирование заболевания при кастрационном уровне
            тестостерона в сыворотке крови (&lt; 50 нг/дл, &lt; 1,7 нмоль/л): в
            3 последовательно полученных пробах, с интервалом не менее 1 недели,
            должно двухкратно наблюдаться повышение уровня ПСА как минимум на
            50% от минимального значения (&gt; 2 нг/мл).
          </li>
        </ul>
        <p className="mb-3">
          <br />
        </p>
        <img src={KRRPJImage} style={{ width: '100%' }} alt="KRRPJImage" />
        <div className="mt-2">
          <Accordion title="Сокращения">
            <ul>
              <li> ПСА – простат-специфический антиген</li>
              <li>
                КРРПЖ – кастрационно-резистентный рак предстательной железы
              </li>
              <li>КТ – компьютерная томография</li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>Smith MR, et al. N Engl J Med. 2018; 378:1408–18.</li>
              <li>Smith MR, et al. J Clin Oncol. 2013; 31:3800–6.</li>
              <li>
                EAU Guidelines. Edn. presented at the EAU Annual Congress
                Amsterdam 2022. ISBN 978-94-92671-16-5.
              </li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default NmkrrpjAbout;
