<template>
  <div id="app">
    <div class="container">
      <transition name="fade">
        <intro-view />
      </transition>
    </div>
  </div>
</template>

<script>
/**
 * @desc Инициализирующий файл Vue. В зависимости от условия
 * отображает либо начальный экран, либо результаты
 *  @vue-computed {Boolean} showResults - показать/не показать результаты
 */
import IntroView from '@/pages/IntroView.vue';

export default {
  name: 'App',
  components: {
    IntroView,
  },
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0' },
    ],
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Verdana Pro", "Verdana", "Arial", "Helvetica", sans-serif;
  position: relative;
  min-width: 375px;
  width: 100%;
  scroll-behavior: smooth;
  color: #222222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-rendering: optimizeSpeed;
}
</style>
