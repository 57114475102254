import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const GoBtn = ({ title, refresh }) => {
  const navigate = useNavigate();
  return (
    <>
      {refresh ? (
        <Link to="/" className="gobtn" onClick={() => navigate(0)}>
          {title}
        </Link>
      ) : (
        <Link to="/" className="gobtn">
          {title}
        </Link>
      )}
    </>
  );
};

export default GoBtn;
