import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import NavHead from "../../components/navhead";
import GoBtn from "../../components/gobtn";
import axios from "axios";

const ClinicEnd = ({ url }) => {
  const nav = useNavigate();
  const { testId } = useParams();
  const [cases, setCases] = useState([]);
  useEffect(() => {
    axios.get(`${url}/api/webapp/clinical_cases`).then((res) => {
      setCases(res.data.filter((item) => item.id === Number(testId.slice(1))));
    });
  }, [testId]);
  return (
    <div className="content_x__block">
      <div className="content_x">
        <NavHead title="Клинические случаи" />
        <div className="test mt-2 pl-2 pr-2">
          <h3 className="title_0">Спасибо!</h3>
          <p className="mb-2">Вы очень помогли пациенту.</p>
          <img src={cases[0]?.picture} width={350} alt="patient" />
        </div>
        <div className="mt-2 pl-2 pr-2">
          <button className="end" onClick={() => nav(`/clinic`)}>
            Следующий пациент
          </button>
        </div>
      </div>
      <div className="mt-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ClinicEnd;
