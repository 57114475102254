import React from 'react';
import GoBtn from '../../../components/gobtn';
import NavHead from '../../../components/navhead';
import Accordion from '../../../components/accordion';
import spartanImage from '../../../img/erleada/nmKRRPJ/spartan.svg';
import circle1 from '../../../img/erleada/nmKRRPJ/circle1.png';
import circle2 from '../../../img/erleada/nmKRRPJ/circle2.png';
import circle3 from '../../../img/erleada/nmKRRPJ/circle3.png';
import circle4 from '../../../img/erleada/nmKRRPJ/circle4.png';

const NmkrrpjResult = () => {
  return (
    <div className="content_x__block">
      <NavHead title="Результаты исследования" />
      <div className="content_x mt-2 pl-2 pr-2">
        <h2>Дизайн исследования III Фазы SPARTAN</h2>
        <p>Пациенты группы высокого риска с нмКРРПЖ#</p>
        <img src={spartanImage} style={{ width: '100%' }} alt="spartan-image" />
        <p>
          #Неметастатический кастрационно-резистентный рак предстательной железы
          (нмКРРПЖ) с высоким риском метастазов (время удвоения ПСА ≤ 10
          месяцев).
        </p>
        <h2>Результаты исследования III Фазы SPARTAN</h2>
        <p>
          Эрлеада – инновация среди препаратов, ингибирующих андрогеновые
          рецепторы, для лечения нмКРРПЖ высокого риска. <br />
          Эрлеада может отдалить развитие метастазов у пациентов с нмКРРПЖ#
          высокого риска на 2 года (ВБМ), при этом бессимптомное течение
          заболевания (вторичная конечная точка) у них сохраняется дольше, чем в
          случае только АДТ.
        </p>
        <img src={circle1} style={{ width: '50%' }} className="mt-2" />
        <img src={circle2} style={{ width: '50%' }} className="mt-2" />
        <img src={circle3} style={{ width: '50%' }} className="mt-2" />
        <img src={circle4} style={{ width: '50%' }} className="mt-2" />
        <p>* Второй промежуточный анализ ОВ проводился через 41 месяц.</p>
        <div className="mt-2">
          <Accordion title="Сокращения">
            <ul>
              <li>АДТ – андрогендепривационная терапия</li>
              <li> ВБМ – выживаемость без метастазирования</li>
              <li>
                нмКРРПЖ – неметастатический кастрационно-резистентный рак
                предстательной железы
              </li>
              <li>HR – hazard ratio (относительный риск)</li>
              <li>ДИ – доверительный интервал</li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>Smith MR, et al. N Engl J Med. 2018; 378:1408–18.</li>
              <li>Smith MR, et al. J Clin Oncol. 2013; 31:3800–6</li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default NmkrrpjResult;
