import Vue from 'vue';
import Vuex from 'vuex';
import form from './form';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    form,
  },
  state: () => ({
    showResults: false,
    showForm: false,
    showHint: false,
    graphNotFound: false,
    parametersGraph: '',
  }),
  mutations: {
    // показать результат
    SET_SHOW_RESULTS(state, boolean) {
      state.showResults = boolean;
    },
    // показать форму
    SET_SHOW_FORM(state, boolean) {
      state.showForm = boolean;
    },
    // показать предупреждение
    SET_SHOW_HINT(state, boolean) {
      state.showHint = boolean;
    },
    // флаг для оторажения, что график не найден
    SET_GRAPH_NOT_FOUND(state, boolean) {
      state.graphNotFound = boolean;
    },
    // устанавливает данные для графика
    SET_PARAMETERS_GRAPH(state, value) {
      state.parametersGraph = value;
    },
  },
});
