import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavHead from "../../components/navhead";
import GoBtn from "../../components/gobtn";
import axios from "axios";
import loader from "../../img/loader.gif";

const VideoList = ({ navHead, dataList, css, url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/video`)
      .then((resp) => {
        setIsLoading(false);
        setVideos(resp.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <div className={css ? `${css}` : "media-wrapper-2"}>
          {navHead ? <NavHead title="Видео с мероприятий" /> : null}
          <div className="media-main">
            {dataList
              ? dataList.map((item, index) => (
                  <Link
                    to={`/media/videoList/:${item.id}`}
                    className="media-item gap-1 pt-1 pb-1"
                    key={index}
                  >
                    <div className="content ml-2">
                      <div className="title">{item.header}</div>
                    </div>
                    <div className="img">
                      <img
                        src={item.video_picture}
                        width={110}
                        height={65}
                        alt="poster"
                      />
                    </div>
                  </Link>
                ))
              : videos.map((item, index) => (
                  <Link
                    to={`/media/videoList/:${item.id}`}
                    className="media-item gap-1 pt-1 pb-1"
                    key={index}
                  >
                    <div className="content ml-2">
                      <div className="title">{item.header}</div>
                    </div>
                    <div className="img">
                      <img
                        src={item.video_picture}
                        width={110}
                        height={65}
                        alt="poster"
                      />
                    </div>
                  </Link>
                ))}
          </div>
          <div className="mt-2 mb-2 pl-2 pr-2">
            <GoBtn title="Вернуться на главную" refresh={true} />
          </div>
        </div>
      )}
    </>
  );
};

export default VideoList;
