import React from 'react';
import GoBtn from '../components/gobtn';
import GoBtnArrow from '../components/gobtnarrow';
import NavHead from '../components/navhead';

const Erleada = () => {
  return (
    <>
      <div className="content_x__block">
        <div className="content_x">
          <NavHead title="Эрлеада" />
          <div className="content mt-2 pl-2 pr-2">
            <div>
              <GoBtnArrow
                url="/erleada/instruction"
                title="ОХЛП (Инструкция)"
                ymt="page_instruction"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/erleada/about"
                title="Механизм действия"
                ymt="page_mechan"
              />
            </div>

            {/* <div className="mt-2">
              <GoBtnArrow url="/erleada/application" title="Применение" />
            </div> */}
            <div className="mt-2">
              <GoBtnArrow
                url="/erleada/mgchrpj"
                title="мГЧРПЖ"
                ymt="page_mgchrpj"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/erleada/nmkrrpj"
                title="нмКРРПЖ"
                ymt="page_nmkrrpj"
              />
            </div>
          </div>
        </div>

        <div className="mt-2 pl-2 pr-2 back_btn_x">
          <p
            style={{ textAlign: 'right', marginBottom: '15px', color: 'grey' }}
          >
            CP-440147 | март 2024
          </p>
          <GoBtn title="Вернуться на главную" />
        </div>
      </div>
    </>
  );
};

export default Erleada;
