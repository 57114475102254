import React, { useEffect, useState } from "react";
import GoBtn from "../../components/gobtn";
import NavHead from "../../components/navhead";
import SaveBtn from "../../components/savebtn";
import ModalSendPDF from "../../components/modalSendPDF";
import { infoList, tips } from "./dataList";
import { useParams } from "react-router-dom";
import { Popover } from "antd";
import axios from "axios";
import clinicGuidePdf from "../../pdf/rec.pdf";

const ResultRpj = ({ url }) => {
  const params = useParams();
  const id = params.id.slice(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [PDF, setPDF] = useState();

  useEffect(() => {
    fetch(clinicGuidePdf)
      .then((resp) => resp.blob())
      .then((d) => {
        const file = new File([d], "rec.pdf", { type: "application/pdf" });
        setPDF(file);
      });
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendPDF = () => {
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("jnj-user-id"));
    formData.append("file_pdf", PDF, "rec.pdf");
    axios
      .post(`${url}/api/webapp/send_pdf`, formData, {
        maxBodyLength: 10000000,
        maxContentLength: 10000000,
      })
      .then((res) => {
        console.log(res);
        setIsModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setIsModalOpen(false);
      });
  };
  //   console.log(457591039);
  console.log(PDF instanceof Blob);
  return (
    <div>
      <NavHead />
      <div className="content mt-2 pl-2 pr-2">
        <div className="board-form-item pd-2 mb-2">{infoList[id].title}</div>
        {infoList[id].first ? (
          <div className="board-form-item pd-2 mb-2">
            <Popover
              content={
                <div dangerouslySetInnerHTML={{ __html: tips.ta0 }}></div>
              }
              trigger="click"
            >
              <p className="title-0">{infoList[id].first?.title}</p>
            </Popover>
            {infoList[id].first?.list.map((item, index) => (
              <li key={index} className="list-0">
                {item}
              </li>
            ))}
          </div>
        ) : null}
        {infoList[id].second ? (
          <div className="board-form-item pd-2 mb-2">
            <p className="title">{infoList[id].second?.title}</p>
            {infoList[id].second?.list.map((item, index) => (
              <div key={index}>
                <p>{item.title}</p>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        ) : null}
        {infoList[id].diagnostics ? (
          <div className="board-form-item pd-2 mb-2">
            <p className="title">Диагностика</p>
            <p>Необходимые диагностические процедуры (анамнез):</p>
            {infoList[id].diagnostics.list.map((item, index) =>
              item.tip ? (
                <Popover
                  key={index}
                  content={item.tip.map((elem, index) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: tips[elem] }}
                    ></div>
                  ))}
                  trigger="click"
                >
                  <li className="list-1">{item.text}</li>
                </Popover>
              ) : (
                <li className="list-1" style={{ textDecoration: "none" }}>
                  {item.text}
                </li>
              )
            )}
          </div>
        ) : null}
        <div className="board-form-item pd-2 mb-2">
          <p className="title">
            Хирургическое лечение/лучевая терапия, включая адъювантную
            лекарственную терапию
          </p>
          <p>Тактика лечения:</p>
          {infoList[id].tactics.list.map((item, index) =>
            item.tip ? (
              <Popover
                key={index}
                content={
                  <div
                    dangerouslySetInnerHTML={{ __html: tips[item.tip] }}
                  ></div>
                }
                trigger="click"
              >
                <li className="list-2">{item.text}</li>
              </Popover>
            ) : (
              <li>{item.text}</li>
            )
          )}
        </div>
        {infoList[id].tactics.additionalList ? (
          <div>
            <div className="board-form-item pd-2 mb-2">
              <h3>{infoList[id].tactics.additionalList.title}</h3>
              <>
                {infoList[id].tactics.additionalList.list.map((item, index) => (
                  <li key={index} className="list-3">
                    {item}
                  </li>
                ))}
              </>
            </div>
            <div className="board-form-item pd-2 mb-2">
              <p className="title">
                Лекарственная терапия распространённых форм, включая
                хирургическую кастрацию
              </p>
              <Popover
                content={
                  <div dangerouslySetInnerHTML={{ __html: tips["ta13"] }}></div>
                }
                trigger="click"
              >
                <p className="list-2">
                  При появлении признаков прогрессирования процесса и
                  мотивированности или по желанию пациента, находящегося на
                  тщательном наблюдении, к активному лечению рекомендовано
                  гормональное лечение
                </p>
              </Popover>
            </div>
          </div>
        ) : null}
      </div>
      <ModalSendPDF
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <div className="flex justify-center" onClick={sendPDF}>
        <SaveBtn />
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};
export default ResultRpj;
