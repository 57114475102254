import React, { useEffect, useState } from "react";

import avatar1 from "../../img/avatars/avatar1.svg";
import avatar2 from "../../img/avatars/avatar2.svg";
import avatar3 from "../../img/avatars/avatar3.svg";
import avatar4 from "../../img/avatars/avatar4.svg";
import avatar5 from "../../img/avatars/avatar5.svg";
import avatar6 from "../../img/avatars/avatar6.svg";
import avatar7 from "../../img/avatars/avatar7.svg";

import close from "../../img/close.svg";
import axios from "axios";

const avatars = [
  {
    id: 1,
    img: avatar1,
  },
  {
    id: 2,
    img: avatar2,
  },
  {
    id: 3,
    img: avatar3,
  },
  {
    id: 4,
    img: avatar4,
  },
  {
    id: 5,
    img: avatar5,
  },
  {
    id: 6,
    img: avatar6,
  },
  {
    id: 7,
    img: avatar7,
  },
];

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const ProfileForm = ({ initProfile, url, userId }) => {
  const [avatarId, setAvatarId] = useState(1);
  const [profile, setProfile] = useState(initProfile);

  const [openOptions, setOpenOptions] = useState(false);
  const [name3, setName3] = useState('');


  useEffect(() => {
    const name3 = localStorage.getItem("name3");
    setName3(name3);
  }, [setName3]);

  const handleEditName = (e) => {
    setName3(e);
    localStorage.setItem("name3", e);
  };

  useEffect(() => {
    const avatar = Number(localStorage.getItem("JNJ-avatar"));
    setAvatarId(avatar || 1);
  }, []);

  const debouncedFirstName = useDebounce(profile.first_name, 500);
  const debouncedLastName = useDebounce(profile.last_name, 500);
  const debouncedPatronymic = useDebounce(profile.patronymic, 500);

  useEffect(() => {
    const avatar = Number(localStorage.getItem("JNJ-avatar"));
    setAvatarId(avatar || 1);
  }, []);

  useEffect(() => {
    if (debouncedFirstName) {
      sendRequest(
        { user_id: userId, first_name: debouncedFirstName },
        () => {},
        "put"
      );
    }
  }, [debouncedFirstName]);

  useEffect(() => {
    if (debouncedLastName) {
      sendRequest(
        { user_id: userId, last_name: debouncedLastName },
        () => {},
        "put"
      );
    }
  }, [debouncedLastName]);
 
  useEffect(() => {
    if (debouncedPatronymic) {
      sendRequest(
        { user_id: userId, patronymic: debouncedPatronymic },
        () => {},
        "put"
      );
    }
  }, [debouncedPatronymic]);

  const handleChangeName = (e) => {
    const value = e.target.value;
    setProfile((p) => ({ ...p, first_name: value }));
  };

  const handleChangeLastName = (e) => {
    const value = e.target.value;
    setProfile((p) => ({ ...p, last_name: value }));
  };
  
  const handleChangePatronymic = (e) => {
    const value = e.target.value;
    setProfile((p) => ({ ...p, patronymic: value }));
  };

  const handleChangeSpetialization = (e) => {
    const callback = () => {
      setProfile((p) => ({ ...p, specialization: e }));
      setOpenOptions((v) => !v);
    };
    sendRequest({ user_id: userId, specialization: e }, callback, "put");
  };

  const sendRequest = (data, callback, method) => {
    axios[method](`${url}/api/webapp/user/${userId}`, data)
      .then(callback)
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAvatar = (id) => {
    setAvatarId(id);
    localStorage.setItem("JNJ-avatar", id);
  };

  return (
    <div className="edit-form">
      <div className="edit-item">
        <h2>Имя</h2>
        <div className="edit-input">
          <input
            value={profile?.first_name}
            onChange={handleChangeName}
            placeholder="Игорь"
          />
          <button onClick={() => setProfile((p) => ({ ...p, first_name: "" }))}>
            <img src={close} alt="close" />
          </button>
        </div>
      </div>

      <div className="edit-item">
        <h2>Фамилия</h2>
        <div className="edit-input">
          <input
            value={profile?.last_name}
            onChange={handleChangeLastName}
            placeholder="Борисевич"
          />
          <button onClick={() => setProfile((p) => ({ ...p, last_name: "" }))}>
            <img src={close} alt="close" />
          </button>
        </div>
      </div>

      <div className="edit-item">
        <h2>Отчество</h2>
        <div className="edit-input">
          <input
            value={profile?.patronymic}
            onChange={handleChangePatronymic}
            placeholder="Святославович"
          />
          <button onClick={() => setProfile((p) => ({ ...p, patronymic: "" }))}>
            <img src={close} alt="close" />
          </button>
        </div>
      </div>

      <div className="edit-item">
        <h2>Специальность</h2>
        <div className="edit-input">
          <input
            value={
              profile?.available_specializations?.find(
                (spec) => spec[0] === profile?.specialization
              )[1]
            }
            placeholder="Онколог"
            onClick={() => setOpenOptions((v) => !v)}
          />
          <button onClick={() => setOpenOptions((v) => !v)}>
            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              className={`a-bottom ${openOptions && "a-top"}`}
            >
              <path
                d="M7.21659 15.6834L0.22811 8.59103C0.145161 8.50659 0.0865436 8.41513 0.0522578 8.31662C0.0174191 8.21812 0 8.11258 0 8C0 7.88742 0.0174191 7.78188 0.0522578 7.68338C0.0865436 7.58487 0.145161 7.4934 0.22811 7.40897L7.21659 0.295514C7.41014 0.0985047 7.65207 0 7.9424 0C8.23272 0 8.48157 0.105541 8.68894 0.316623C8.89631 0.527704 9 0.773966 9 1.05541C9 1.33685 8.89631 1.58311 8.68894 1.7942L2.59217 8L8.68894 14.2058C8.88249 14.4028 8.97926 14.6454 8.97926 14.9336C8.97926 15.2224 8.87558 15.4723 8.6682 15.6834C8.46083 15.8945 8.21889 16 7.9424 16C7.6659 16 7.42396 15.8945 7.21659 15.6834Z"
                fill="#979797"
              />
            </svg>
          </button>
          <div
            className={`edit-options ${openOptions && "edit-options-active"}`}
          >
            {profile?.available_specializations?.map((spec, i) => (
              <button
                className="edit-options-item"
                key={i}
                onClick={() => handleChangeSpetialization(spec[0])}
              >
                <p>{spec[1]}</p>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="edit-item">
        <h2>Аватар</h2>
        <div className="edit-avatars">
          {avatars.map((avatar, i) => (
            <button
              disabled={avatar.id === avatarId}
              onClick={() => handleChangeAvatar(i + 1)}
              className={`edit-avatars-item ${
                avatar.id === avatarId && "avatar-active"
              }`}
              key={avatar.id}
            >
              <img src={avatar.img} alt={avatar.id} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
