import React from 'react';
import { Link } from 'react-router-dom';
import ym from 'react-yandex-metrika';

const GoBtnArrow = ({ url, title, ymt }) => {
  return (
    <>
      <Link
        to={url}
        className="gobtnarrow flex align-center justify-between"
        onClick={() => {
          ym('reachGoal', ymt);
        }}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
        >
          <path
            d="M1.78341 0.316624L8.77189 7.40897C8.85484 7.49341 8.91346 7.58487 8.94774 7.68338C8.98258 7.78188 9 7.88742 9 8C9 8.11258 8.98258 8.21812 8.94774 8.31662C8.91346 8.41513 8.85484 8.5066 8.77189 8.59103L1.78341 15.7045C1.58986 15.9015 1.34793 16 1.0576 16C0.767282 16 0.518434 15.8945 0.31106 15.6834C0.103687 15.4723 0 15.226 0 14.9446C0 14.6631 0.103687 14.4169 0.31106 14.2058L6.40783 8L0.31106 1.7942C0.117512 1.59719 0.0207376 1.35458 0.0207376 1.06639C0.0207376 0.777627 0.124424 0.527705 0.331798 0.316624C0.53917 0.105542 0.781106 9.53674e-07 1.0576 9.53674e-07C1.3341 9.53674e-07 1.57604 0.105542 1.78341 0.316624Z"
            fill="#327CD1"
          />
        </svg>
      </Link>
    </>
  );
};

export default GoBtnArrow;
