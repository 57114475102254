<template>
  <div class="questions-component">
    <question
      v-for="question in commonQuestions"
      :key="question.key"
      :content="question"
      :patient-data="question.patientData"
      class="item"
      @cardSelected="handleCardSelection"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import commonQuestions from '@/mock/common-questions.json';
import patientData from '@/mock/patient-data.json';
import Question from '@/components/common/form-view/CommonQuestions/Question';

export default {
  name: 'CommonQuestions',
  components: {
    Question,
  },
  data() {
    return {
      commonQuestions,
      patientData,
      selectedCard: null,
    };
  },
  computed: {
    ...mapState({
      patientGroup: state => state.form.fields.patientGroup,
    }),
    filteredQuestions() {
      const patientGroupData = this.patientData[this.patientGroup] || [];
      const patientQuestions = this.commonQuestions[this.patientGroup] || [];
      return patientQuestions.map(question => ({
        ...question,
        patientData: patientGroupData.map(data => ({ ...data })),
      }));
    },
  },
  methods: {
    handleCardSelection(selectedCard) {
      this.$emit('cardSelected', selectedCard); // прокидываем событие вверх по цепочке
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-component {
  .item {
    &:not(:last-child) {
      @include _mb-32
    }
  }
}
</style>
