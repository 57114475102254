import React, { useEffect } from 'react';
import GoBtn from '../components/gobtn';
import GoBtnArrow from '../components/gobtnarrow';
import NavHead from '../components/navhead';

const Board = () => {
  let userId;
  useEffect(() => {
    if (window.location.href.includes('tguser_data=')) {
      userId = window.location.href.split('tguser_data=')[1].split('__')[0];
    }
    if (userId && userId.includes('&')) {
      userId = userId.split('&')[0];
    }

    if (
      !localStorage.getItem('jnj-user-id') ||
      (userId && userId !== localStorage.getItem('jnj-user-id'))
    ) {
      localStorage.setItem('jnj-user-id', userId);
    }
  }, [userId]);
  console.log(window.location.href.split('__')[0].split('=')[1]);
  return (
    <>
      <div className="content_x__block">
        <NavHead title="Клинические рекомендации в 7 кликов" />
        <div className="content_x">
          <h2 className="mt-2 pl-2 pr-2">Выберите диагноз</h2>
          <div className="content mt-2 pl-2 pr-2">
            <div>
              <GoBtnArrow
                url="/board/metarpj"
                title="Метастатический РПЖ"
                ymt="clin_reco_met"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/board/localrpj"
                title="Локализованный / местно- распространённый РПЖ"
                ymt="clin_reco_local"
              />
            </div>
          </div>
        </div>

        <div className="mt-2 mb-2 pl-2 pr-2 back_btn_x">
          <GoBtn title="Вернуться на главную" />
        </div>
      </div>
    </>
  );
};

export default Board;
