import React from 'react';
import GoBtn from '../../components/gobtn';
import GoBtnArrow from '../../components/gobtnarrow';
import NavHead from '../../components/navhead';

const ErleadaNmkrrpj = () => {
  return (
    <div className="content_x__block">
      <NavHead title="нмКРРПЖ" />
      <div className="content_x mt-2 pl-2 pr-2">
        <div>
          <GoBtnArrow url="/erleada/nmkrrpj/about" title="О заболевании" />
        </div>
        <div className="mt-2">
          <GoBtnArrow url="/erleada/nmkrrpj/survival" title="Эффективность" />
        </div>
        <div className="mt-2">
          <GoBtnArrow
            url="/erleada/nmkrrpj/result"
            title="Результаты исследования"
          />
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ErleadaNmkrrpj;
