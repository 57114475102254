import React from "react";
import { Link } from "react-router-dom";
import GoBtn from "../../components/gobtn";
import NavHead from "../../components/navhead";

const ErleadaApplication = () => {
  return (
    <div className="content_x__block">
      <NavHead title="Применение" />
      <div className="posts content_x">
        <h2>
          Информационные материалы: <br /> Эрлеада в лечении мГЧРПЖ
        </h2>
        <Link to="/clinic" className="posts-item gap-1 pt-1 pb-1">
          <div className="content">
            <div className="meta">15.03.2023</div>
            <div className="title">
              Влияние рака предстательной железы на потенцию
            </div>
          </div>
          <div className="img">
            <img src="../img/post-2.jpg" alt="img" />
          </div>
        </Link>
        <Link to="/clinic" className="posts-item gap-1 pt-1 pb-1">
          <div className="content">
            <div className="meta">15.03.2023</div>
            <div className="title">
              Рак предстательной железы: международный обмен опытом онлайн
            </div>
          </div>
          <div className="img">
            <img src="../img/post-1.jpg" alt="img" />
          </div>
        </Link>
        <h2>
          Информационные материалы: <br /> Эрлеада в лечении нмКРРПЖ
        </h2>

        <Link to="/clinic" className="posts-item gap-1 pt-1 pb-1">
          <div className="content">
            <div className="meta">15.03.2023</div>
            <div className="title">
              Рак предстательной железы: международный обмен опытом онлайн
            </div>
          </div>
          <div className="img">
            <img src="../img/post-1.jpg" alt="img" />
          </div>
        </Link>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ErleadaApplication;
