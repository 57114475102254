import React from "react";
import { useNavigate } from "react-router-dom";

const NavHead = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="navhead">
        <button onClick={() => navigate(-1, { replace: true })}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.2166 19.6834L7.22811 12.591C7.14516 12.5066 7.08654 12.4151 7.05226 12.3166C7.01742 12.2181 7 12.1126 7 12C7 11.8874 7.01742 11.7819 7.05226 11.6834C7.08654 11.5849 7.14516 11.4934 7.22811 11.409L14.2166 4.29551C14.4101 4.0985 14.6521 4 14.9424 4C15.2327 4 15.4816 4.10554 15.6889 4.31662C15.8963 4.5277 16 4.77397 16 5.05541C16 5.33685 15.8963 5.58311 15.6889 5.7942L9.59217 12L15.6889 18.2058C15.8825 18.4028 15.9793 18.6454 15.9793 18.9336C15.9793 19.2224 15.8756 19.4723 15.6682 19.6834C15.4608 19.8945 15.2189 20 14.9424 20C14.6659 20 14.424 19.8945 14.2166 19.6834Z"
              fill="#222222"
            />
          </svg>
        </button>
        <h1>{title}</h1>
      </div>
    </>
  );
};

export default NavHead;
