import React, { useRef, useState } from 'react';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import { questions, answers1 } from './dataList';
import { useNavigate } from 'react-router-dom';
import ym from 'react-yandex-metrika';

const MetaRpj = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [showExtraQues, setShowExtraQues] = useState(false);
  const setAnswer = (index, value) => {
    answers1[index] = value;
    if (index === 4 && answers1[index] === 2) {
      setShowExtraQues(true);
    }
    if (index === 4 && answers1[index] === 1) {
      setShowExtraQues(false);
      answers1.pop();
    }
    if (
      answers1.filter((item) => item === null).length === 0 &&
      answers1.filter((item) => item === 0).length < 3
    ) {
      ref.current.classList.remove('disabled');
    } else {
      ref.current.classList.add('disabled');
    }
  };
  const seeResult = () => {
    ym('reachGoal', 'met_next');
    if (answers1[5] === 1) {
      return navigate('/board/metarpj/:b5');
    } else if (answers1[5] === 2) {
      return navigate('/board/metarpj/:b6');
    } else if (answers1[5] === 3) {
      return navigate('/board/metarpj/:b7');
    }
    if (answers1[3] === 2) {
      return answers1[1] === 3 || answers1[2] === 4
        ? navigate('/board/metarpj/:b4')
        : navigate('/board/metarpj/:b3');
    } else {
      return answers1[1] === 3 || answers1[2] === 4
        ? navigate('/board/metarpj/:b2')
        : navigate('/board/metarpj/:b1');
    }
  };

  return (
    <>
      <NavHead title="Метастатический РПЖ" />
      <div className="content mt-2 pl-2 pr-2">
        <h2>Введите данные</h2>
        <div className="note mt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <circle cx="12" cy="12" r="12" fill="#29D7F9" />
            <rect x="10.5" y="4" width="3" height="12" rx="1.5" fill="white" />
            <circle cx="12" cy="18.5" r="1.5" fill="white" />
          </svg>
          <span>
            Не допускается одновременный <br /> выбор трёх ответов «нет»
          </span>
        </div>
        <form className="board-form mt-2">
          {questions[1].slice(0, 5).map((item, index) => (
            <div className="board-form-item pd-2 mb-2" key={index}>
              <div className="label">{item.title}</div>
              <div className="inputs mt-2 flex align-center gap-2">
                {item.answers.map((elem, ind) => (
                  <div key={ind}>
                    <input
                      type="radio"
                      name={elem.name}
                      value={elem.value}
                      id={elem.id}
                    />
                    <label
                      htmlFor={elem.id}
                      onClick={() => setAnswer(index, elem.value)}
                    >
                      {elem.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {showExtraQues
            ? questions[1].slice(5).map((item, index) => (
                <div className="board-form-item pd-2 mb-2" key={index}>
                  <div className="label">{item.title}</div>
                  <div className="inputs mt-2 flex align-center gap-2">
                    {item.answers.map((elem, ind) => (
                      <div key={ind}>
                        <input
                          type="radio"
                          name={elem.name}
                          value={elem.value}
                          id={elem.id}
                        />
                        <label
                          htmlFor={elem.id}
                          onClick={() => setAnswer(index + 5, elem.value)}
                        >
                          {elem.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : null}
          <div className="flex gap-2 mt-2">
            <button className="clear" onClick={() => navigate(0)}>
              Сбросить
            </button>
            <button
              ref={ref}
              type="button"
              className="next disabled"
              onClick={seeResult}
            >
              Продолжить
            </button>
          </div>
        </form>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default MetaRpj;
