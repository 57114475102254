import { Button, Modal } from "antd";

const modalSendPDF = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      title=""
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <p className="mb-2" style={{ fontWeight: 600 }}>
        Результаты отправлены в чат с Телеграм ботом On&Co
      </p>
      <Button
        type="primary"
        shape="round"
        size="large"
        onClick={handleOk}
        style={{ width: "100%" }}
      >
        Продолжить
      </Button>
    </Modal>
  );
};

export default modalSendPDF;
