// дефолтные значения для полей для группы пациентов
const initPatientData = {
  age: null,
  gleasonIndex: null,
  lesionRegionalLN: null,
  localMetastases: null,
  stageMetastases: null,
  currentMetastases: null,
  psaLevel: null,
  ecogScale: null,
  previousHormoneTherapy: null,
  initialPSALevel: null,
  psaDoublingTime: null,
  initialOsteomodifyingDrugs: null,
  currentVolume: null,
};

export default {
  state: () => ({
    fields: {
      patientGroup: null,
      dataSource: null,
      ...initPatientData,
      therapy: null,
    },
    additionalFactor: null,
    detailAddFactor: null,
    inputAge: null,
    inputGleasonIndex: null,
  }),
  mutations: {
    // записывает значение по ключу
    SET_FORM_DATA(state, { key, value }) {
      state.fields[key] = value;
    },
    // устанавливает доп фактор
    SET_ADDITIONAL_FACTOR(state, value) {
      state.additionalFactor = value;
    },
    // устанавливает детальный выбор доп фактора
    SET_DETAIL_ADD_FACTOR(state, string) {
      state.detailAddFactor = string;
    },
    // записывает значение при вводе в инпут
    SET_INPUT_VALUE(state, { key, value }) {
      state[key] = value;
    },
    // сброс данных
    CLEAR_PATIENT_DATA(state) {
      state.fields = { ...state.fields, ...initPatientData, therapy: null };
      state.additionalFactor = null;
      state.detailAddFactor = null;
    },
    // сброс всех данных
    CLEAR_ALL(state) {
      state.fields = {
        patientGroup: null,
        dataSource: null,
        ...initPatientData,
        therapy: null,
      };
      state.additionalFactor = null;
      state.detailAddFactor = null;
      state.inputAge = null;
      state.inputGleasonIndex = null;
    },
    // сброс доп фактора
    CLEAR_DETAIL_FACTOR(state) {
      state.detailAddFactor = null;
    },
  },
};
