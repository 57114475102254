import React, { useEffect, useState } from 'react';
import NavHead from '../components/navhead';
import { Link } from 'react-router-dom';
import GoBtn from '../components/gobtn';
import axios from 'axios';
import loader from '../../src/img/loader.gif';
import ym from 'react-yandex-metrika';

const Blogs = ({ navHead, refresh, dataList, css, url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/articles`,{
        headers: {
          'ngrok-skip-browser-warning': '1231'
        }
      })
      .then((res) => {
        setIsLoading(false);
        setBlogs(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <div className={css ? `${css}` : 'posts-wrapper-2'}>
          {navHead ? <NavHead title="Статьи" /> : null}
          <div className="posts-main">
            <div className="posts">
              {dataList
                ? dataList.map((item, index) => (
                    <Link
                      to={`/blog/:${item.id}`}
                      className="posts-item gap-1 pt-1 pb-1 ml-2"
                      key={index}
                      onClick={() => {
                        ym('reachGoal', 'page_article');
                      }}
                    >
                      <div className="content">
                        <div className="meta">
                          {item.created_date.slice(0, 10).split('-')[2] +
                            '.' +
                            item.created_date.slice(0, 10).split('-')[1] +
                            '.' +
                            item.created_date.slice(0, 10).split('-')[0]}
                        </div>
                        <div className="title">{item.header}</div>
                      </div>
                      <div className="img">
                        <img
                          src={
                            item.content.filter((el) =>
                              el.includes('backend/images')
                            )[0]
                          }
                          alt="blog"
                        />
                      </div>
                    </Link>
                  ))
                : blogs.map((item, index) => (
                    <Link
                      to={`/blog/:${item.id}`}
                      className="posts-item gap-1 pt-1 pb-1"
                      key={index}
                    >
                      <div className="content">
                        <div className="meta">
                          {item.created_date.slice(0, 10).split('-')[2] +
                            '.' +
                            item.created_date.slice(0, 10).split('-')[1] +
                            '.' +
                            item.created_date.slice(0, 10).split('-')[0]}
                        </div>
                        <div className="title">{item.header}</div>
                      </div>
                      <div className="img">
                        <img
                          src={
                            item.content.filter((el) =>
                              el.includes('backend/images')
                            )[0]
                          }
                          alt="blog"
                        />
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
          <div className="mt-2 mb-2 pl-2 pr-2">
            <GoBtn title="Вернуться на главную" refresh={refresh} />
          </div>
        </div>
      )}
    </>
  );
};

export default Blogs;
