import React from 'react';
import Accordion from '../../components/accordion';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import table1 from '../../img/erleada/table1.png';
import table2 from '../../img/erleada/table2.png';

const ErleadaInstruction = () => {
  return (
    <>
      <NavHead title="ОХЛП (Инструкция)" />
      <div className="content mt-2 pl-2 pr-2">
        <div className="mt-2">
          <Accordion title="ОБЩАЯ ХАРАКТЕРИСТИКА ЛЕКАРСТВЕННОГО ПРЕПАРАТА">
            <p>
              ▼ Данный лекарственный препарат подлежит дополнительному
              мониторингу. Это позволит быстро выявить новую информацию по
              безопасности. Мы обращаемся к работникам системы здравоохранения с
              просьбой сообщать о любых подозреваемых нежелательных реакциях.
              Порядок сообщения о нежелательных реакциях представлен в разделе
              4.8.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="1. НАИМЕНОВАНИЕ ЛЕКАРСТВЕННОГО ПРЕПАРАТА">
            <p>Эрлеада, 60 мг, таблетки, покрытые пленочной оболочкой</p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="2. КАЧЕСТВЕННЫЙ И КОЛИЧЕСТВЕННЫЙ СОСТАВ">
            <p>Действующее вещество: апалутамид</p>
            <p>
              Каждая таблетка, покрытая пленочной оболочкой, содержит 60 мг
              апалутамида
            </p>
            <p>
              Полный перечень вспомогательных веществ приведен в разделе 6.1.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="3. ЛЕКАРСТВЕННАЯ ФОРМА">
            <p>Таблетки, покрытые пленочной оболочкой</p>
            <p>
              Продолговатые слегка двояковыпуклые таблетки от слегка
              желтовато-зеленого до зеленовато-серого цвета, с гравировкой «AR
              60» на одной стороне.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="4. КЛИНИЧЕСКИЕ ДАННЫЕ">
            <h3>4.1. Показания к применению</h3>
            <p>
              Препарат Эрлеада показан к применению у взрослых мужчин для
              лечения:
            </p>
            <ul style={{ listStyle: 'inside' }}>
              <li>
                неметастатического кастрационно-резистентного рака
                предстательной железы (нмКРРПЖ) с высоким риском развития
                метастазов (см. раздел 5.1);
              </li>
              <li>
                метастатического гормоночувствительного рака предстательной
                железы (мГЧРПЖ) в комбинации с андроген депривационной терапией
                (АДТ) (см. раздел 5.1).
              </li>
            </ul>
            <h3 className="mt-2">4.2. Режим дозирования и способ применения</h3>
            <p>
              Препарат должен назначаться и применяться под регулярным
              наблюдением врача, имеющего опыт лечения онкологических
              заболеваний, и в специализированных отделениях.
            </p>
            <p style={{ textDecoration: 'underline' }}>Режим дозирования</p>
            <p>
              Рекомендуемая доза препарата Эрлеада составляет 240 мг (4 таблетки
              по 60 мг), препарат принимают внутрь один раз в день.
            </p>
            <p>
              В период лечения пациентам, которым не была проведена
              хирургическая кастрация, необходимо выполнять медикаментозную
              кастрацию аналогом гонадотропин-рилизинг- гормона (ГнРГ).
            </p>
            <p style={{ fontStyle: 'italic' }}>Коррекция дозы</p>
            <p>
              Если у пациента отмечается токсичность ≥ 3 степени или
              непереносимость, следует приостановить прием до тех пор, пока
              симптоматика не улучшится до степени ≤ 1 или исходного уровня,
              затем следует возобновить прием в той же дозе или, при
              необходимости, в сниженной дозе (180 или 120 мг).
            </p>
            <p style={{ fontStyle: 'italic' }}>Пропуск дозы</p>
            <p>
              Если пациент пропустил дозу, ее необходимо принять как можно
              быстрее в этот же день; на следующий день следует соблюдать
              обычный режим приема. Пациенту не следует принимать дополнительное
              количество таблеток для восполнения пропущенной дозы.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Особые группы пациентов
            </p>
            <p style={{ fontStyle: 'italic' }}>Пациенты пожилого возраста</p>
            <p>
              Из 1327 пациентов, получавших препарат Эрлеада в клинических
              исследованиях, 19% были младше 65 лет, 41% были в возрасте от 65
              до 74 лет, 40% – 75 лет и старше. Значимых различий по
              безопасности или эффективности между этими пациентами и пациентами
              более младшего возраста обнаружено не было.
            </p>
            <p>
              Не требуется коррекция дозы у пожилых пациентов (см. разделы 5.1 и
              5.2).
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Пациенты с нарушением функции почек
            </p>
            <p>
              Специального исследования препарата Эрлеада у пациентов с
              нарушением функции почек не проводилось. Основываясь на данных
              фармакокинетического анализа в клинических исследованиях у
              субъектов с кастрационно-резистентным РПЖ и здоровых субъектов,
              можно утверждать, что не наблюдалось значимых различий в отношении
              системных уровней у субъектов с изначально наблюдаемым нарушением
              функции почек легкой или умеренной степени (СКФ от 30 до 89
              мл/мин/1,73 м2 ) по сравнению с субъектами с нормальной функцией
              почек (СКФ ≥ 90 мл/мин/1,73 м2 ). Для пациентов с нарушением
              функции почек легкой и умеренной степени коррекция дозы не
              требуется. Нет данных для пациентов с нарушением функции почек
              тяжелой степени или терминальной почечной недостаточностью (СКФ ≤
              29 мл/мин/1,73 м2 ), (см. раздел 5.2).
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Пациенты с нарушением функции печени
            </p>
            <p>
              В специальном исследовании по изучению нарушений функции печени
              сравнивались системные уровни апалутамида и N-дезметилапалутамида
              у субъектов с исходным нарушением функции печени легкой или
              умеренной степени (класс A или B по классификации Чайлд – Пью,
              соответственно) в сравнении со здоровыми участниками с нормальной
              функцией печени. Системные уровни апалутамида и
              N-дезметилапалутамида были сходны у пациентов с нарушением функции
              печени легкой или умеренной степени по сравнению с пациентами с
              нормальной функцией печени. Коррекция дозы у пациентов с
              нарушением функции печени легкой или умеренной степени не
              требуется. Данных по пациентам с нарушением функции печени тяжелой
              степени (класс C по классификации Чайлд – Пью) нет (см. раздел
              5.2).
            </p>
            <p style={{ textDecoration: 'underline' }}>Дети</p>
            <p>
              Безопасность и эффективность препарата Эрлеада у детей до 18 лет
              на данный момент не установлены. Данные отсутствуют.
            </p>
            <p style={{ textDecoration: 'underline' }}>Способ применения</p>
            <p>Для перорального приема.</p>
            <p>
              Таблетки следует проглатывать целиком. Препарат Эрлеада можно
              принимать независимо от приема пищи.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Альтернативный способ применения
            </p>
            <p>
              Для пациентов, которым трудно глотать таблетки целиком,
              рекомендуемую дозу таблеток Эрлеада можно смешать со 120 мл
              яблочного пюре. Не раздавливайте таблетки. Перемешайте яблочное
              пюре после добавления таблеток, а также через 15 минут и 30 минут
              для того, чтобы таблетки полностью диспергировались (хорошо были
              перемешаны с пюре, и не осталось никаких остатков таблетки). С
              помощью ложки сразу же проглотите смесь. Промойте контейнер для
              смеси с 60 мл воды и немедленно выпейте содержимое. Повторите этот
              этап еще раз, чтобы гарантированно принять полную дозу. Смесь
              следует употребить в течение одного часа после приготовления (см.
              раздел 5.2).
            </p>
            <h3 className="mt-2">4.3. Противопоказания</h3>
            <ul style={{ listStyle: 'inside' }}>
              <li>
                Гиперчувствительность к действующему веществу или к любому из
                вспомогательных веществ, перечисленных в разделе 6.1.
              </li>
              <li>
                Беременные женщины или женщины с детородным потенциалом (см.
                раздел 4.6).
              </li>
            </ul>
            <h3 className="mt-2">
              4.4. Особые указания и меры предосторожности при применении
            </h3>
            <p style={{ textDecoration: 'underline' }}>Судороги</p>
            <p>
              Препарат Эрлеада не рекомендуется принимать пациентам с судорогами
              в анамнезе или другими предрасполагающими факторами, включая, но
              не ограничиваясь, черепно-мозговой травмой, недавно перенесенным
              инсультом (в течение одного года), первичными опухолями головного
              мозга или метастазами в головном мозге. Если на фоне применения
              препарата Эрлеада развиваются судороги, применение препарата
              должно быть окончательно прекращено. Риск возникновения судорог
              выше у пациентов, получающих дополнительные препараты, снижающие
              порог судорожной активности.
            </p>
            <p>
              В двух рандомизированных исследованиях (SPARTAN и TITAN) судороги
              отмечались у 0,6% пациентов, получавших апалутамид, и у 0,2%
              пациентов, получавших плацебо. В этих исследованиях исключались
              пациенты с судорогами в анамнезе или предрасполагающими факторами
              к ним.
            </p>
            <p>
              Клинический опыт возобновления применения препарата Эрлеада у
              пациентов, у которых наблюдались судороги, отсутствует.
            </p>
            <p style={{ textDecoration: 'underline' }}>Падения и переломы</p>
            <p>
              Были отмечены случаи падений и переломов у пациентов, получавших
              апалутамид (см. раздел 4.8). Необходимо оценивать риск падений и
              переломов перед началом применения препарата Эрлеада,
              контролировать состояние пациентов во время лечения, а также
              необходимо рассмотреть возможность назначения препаратов,
              направленных на укрепление костной ткани.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Ишемическая болезнь сердца и ишемические цереброваскулярные
              нарушения
            </p>
            <p>
              У пациентов, получавших апалутамид, отмечались случаи ишемической
              болезни сердца и ишемических цереброваскулярных нарушений, включая
              случаи, приводившие к смерти (см. раздел 4.8). У большинства
              пациентов были факторы риска ишемической болезни сердца/головного
              мозга. Пациенты должны находиться под наблюдением на предмет
              появления признаков и симптомов ишемической болезни сердца и
              ишемических цереброваскулярных нарушений. Контроль факторов риска,
              таких как гипертензия, сахарный диабет или дислипидемия, должен
              быть оптимизирован в соответствии со стандартами оказания
              медицинской помощи.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Одновременное применение с другими лекарственными средствами
            </p>
            <p>
              Апалутамид является сильным индуктором ферментов и может являться
              причиной снижения эффективности многих широко используемых
              лекарственных препаратов (см. раздел 4.5). Поэтому до начала
              лечения апалутамидом следует проанализировать 5 применение
              сопутствующих лекарственных препаратов. Следует избегать
              одновременного применения апалутамида с лекарственными средствами,
              которые являются чувствительными субстратами многих
              метаболизирующих ферментов или переносчиков, если их
              терапевтический эффект имеет большое значение для пациента, и,
              если коррекция дозы не может быть легко выполнена на основе
              контроля за эффективностью или концентрацией в плазме.
            </p>
            <p>
              Следует избегать совместного применения апалутамида с варфарином и
              кумарин- подобными антикоагулянтами. Если Эрлеада назначается
              совместно с антикоагулянтом, метаболизируемым CYP2C9 (таким как
              варфарин или аценокумарол), следует проводить дополнительный
              мониторинг международного нормализованного отношения (МНО) (см.
              раздел 4.5).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Недавно перенесенные сердечно-сосудистые заболевания
            </p>
            <p>
              Пациенты с клинически значимыми сердечно-сосудистыми
              заболеваниями, возникшими в течение последних 6 месяцев, включая
              тяжелую/нестабильную стенокардию, инфаркт миокарда, клинически
              выраженную застойную сердечную недостаточность, артериальные или
              венозные тромбоэмболические явления (например, тромбоэмболию
              легочной артерии, нарушение мозгового кровообращения, включая
              транзиторные ишемические атаки), или клинически значимые
              желудочковые аритмии, были исключены из клинических исследований.
              Поэтому безопасность применения апалутамида у этих пациентов не
              установлена. У пациентов с клинически значимыми
              сердечно-сосудистыми заболеваниями при назначении препарата
              Эрлеада требуется мониторинг факторов риска, включая
              гиперхолестеринемию, гипертриглицеридемию и прочие
              кардиометаболические нарушения (см. раздел 4.8). Данные
              патологические состояния следует лечить по необходимости, после
              начала применения препарата Эрлеада, согласно установленному
              протоколу лечения.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Антиандрогенная терапия может увеличивать интервал QT
            </p>
            <p>
              У пациентов с удлиненным интервалом QT в анамнезе или
              соответствующими факторами риска, а также у пациентов, получающих
              сопутствующие лекарственные препараты, которые могут удлинять
              интервал QT (см. раздел 4.5), необходимо оценивать отношение
              пользы и риска, включая вероятность возникновения пируэтной
              тахикардии, до начала терапии препаратом Эрлеада.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Синдром Стивенса-Джонсона/токсический эпидермальный некролиз
            </p>
            <p>
              Постмаркетинговые сообщения о случаях синдрома
              Стивенса-Джонсона/токсического эпидермального некролиза, которые
              могут быть опасными для жизни или смертельными, 6 наблюдались в
              связи с лечением препаратом Эрлеада с неизвестной частотой (см.
              раздел 4.8).
            </p>
            <p>
              Пациентов следует предупреждать о признаках и симптомах,
              указывающих на синдром Стивенса-Джонсона/токсический эпидермальный
              некролиз. Если наблюдаются эти симптомы, следует немедленно
              отменить препарат Эрлеада и немедленно обратиться к врачу.
            </p>
            <p>
              Не допускается возобновление приема препарата Эрлеада у пациентов,
              у которых развился синдром Стивенса-Джонсона/токсический
              эпидермальный некролиз в любой период лечения препаратом Эрлеада,
              следует рассмотреть альтернативный метод лечения.
            </p>
            <h3 className="mt-2">
              4.5. Взаимодействие с другими лекарственными препаратами и другие
              виды взаимодействия
            </h3>
            <p>
              Элиминация апалутамида и образование его активного метаболита, N-
              дезметилапалутамида, опосредованы изоферментами CYP2C8 и CYP3A4 в
              равной степени в равновесном состоянии. Клинически значимых
              изменений в их общей экспозиции в результате взаимодействия
              препарата с ингибиторами или индукторами изофермента CYP2C8 или
              CYP3A4 не ожидается. Апалутамид является индуктором ферментов и
              переносчиков и может быть причиной увеличения выведения многих
              широко используемых лекарственных средств.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Влияние других лекарственных средств на экспозицию апалутамида
            </p>
            <p style={{ fontStyle: 'italic' }}>Ингибиторы изофермента CYP2C8</p>
            <p>
              Ингибиторы изофермента CYP2C8 Изофермент CYP2C8 играет роль в
              выведении апалутамида и в образовании его активного метаболита. В
              исследовании лекарственного взаимодействия наблюдалось снижение
              Cmax апалутамида на 21% и повышение AUC на 68% при совместном
              приеме однократной дозы 240 мг препарата Эрлеада с гемфиброзилом
              (мощным ингибитором изофермента CYP2C8). Для активного вещества
              (суммарного показателя для апалутамида и скорректированного с
              учетом величины эффекта активного метаболита) Cmax снизилась на
              21%, в то время как AUC повысилась на 45%. Нет необходимости в
              коррекции начальной дозы в случае совместного применения препарата
              Эрлеада с мощным ингибитором изофермента CYP2C8 (например,
              гемфиброзил, клопидогрел), однако следует рассматривать
              возможность снижения дозы препарата Эрлеада исходя из его
              переносимости (см. раздел 4.2). Предполагается, что слабые или
              умеренные ингибиторы изофермента CYP2C8 не влияют на
              фармакокинетику апалутамида.
            </p>
            <p style={{ fontStyle: 'italic' }}>Ингибиторы изофермента CYP3A4</p>
            <p>
              Изофермент CYP3A4 участвует в выведении апалутамида и в
              образовании его активного метаболита. В исследовании
              лекарственного взаимодействия наблюдалось снижение Cmax
              апалутамида на 22% с сохранением AUC на прежних уровнях при
              совместном приеме однократной дозы 240 мг препарата Эрлеада с
              итраконазолом (мощным ингибитором CYP3A4). Для активного вещества
              (суммарного показателя для апалутамида и скорректированного с
              учетом величины эффекта активного метаболита) Cmax снизилась на
              22% с сохранением AUC на прежнем уровне. Нет необходимости в
              коррекции начальной дозы в случае совместного применения препарата
              Эрлеада с мощным ингибитором изофермента CYP3A4 (например,
              кетоконазол, ритонавир, кларитромицин), однако следует
              рассматривать возможность снижения дозы препарата Эрлеада исходя
              из его переносимости (см. раздел 4.2.). Предполагается, что слабые
              или умеренные ингибиторы изофермента CYP3A4 не влияют на
              фармакокинетику апалутамида.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Индукторы изофермента CYP3A4 или CYP2C8
            </p>
            <p>
              Эффекты индукторов изоферментов CYP3A4 или CYP2C8 на
              фармакокинетику апалутамида в исследованиях in vivo не
              оценивались. Основываясь на результатах исследования
              взаимодействия с мощными ингибиторами изофермента CYP3A4 и CYP2C8,
              индукторы CYP3A4 или CYP2C8, как ожидается, не будут клинически
              значимо влиять на фармакокинетику апалутамида и действующего
              вещества, поэтому при совместном применении препарата Эрлеада с
              индукторами CYP3A4 или CYP2C8 не требуется коррекция дозы.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Влияние апалутамида на экспозицию других лекарственных средств
            </p>
            <p>
              Апалутамид является мощным индуктором ферментов и увеличивает
              синтез многих ферментов и переносчиков; поэтому ожидается
              взаимодействие апалутамида со многими распространенными
              лекарственными средствами, которые являются субстратами ферментов
              или переносчиков. Снижение их концентрации в плазме может быть
              существенным и приводить к потере или снижению клинического
              эффекта. Существует также риск повышенного образования активных
              метаболитов.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Влияние апалутамида на ферменты, метаболизирующие лекарственные
              средства
            </p>
            <p>
              Исследования in vitro показали, что апалутамид и
              N-дезметилапалутамид являются умеренными или мощными индукторами
              изоферментов CYP3A4 и CYP2B6, умеренными ингибиторами изоферментов
              CYP2B6 и CYP2C8 и слабыми ингибиторами изоферментов CYP2C9,
              CYP2C19 и CYP3A4. Апалутамид и N-дезметилапалутамид не влияют на
              изоферменты CYP1A2 и CYP2D6 в терапевтически значимых
              концентрациях. Влияние 8 апалутамида на субстраты изофермента
              CYP2B6 не было оценено in vivo, и конечный результат в настоящее
              время неизвестен. Когда субстраты изофермента CYP2B6 (например,
              эфавиренз) применяются совместно с препарата Эрлеада, должен
              проводиться контроль за нежелательными реакциями и потерей
              эффективности субстрата, а также может быть необходима
              корректировка дозы субстрата для поддержания оптимальных
              концентраций в плазме.
            </p>
            <p>
              У человека апалутамид является мощным индуктором изоферментов
              CYP3A4 и CYP2C19 и слабым индуктором изофермента CYP2C9. В
              исследовании лекарственного взаимодействия с использованием
              «коктейльного» подхода совместный прием препарата Эрлеада с
              однократным пероральным приемом чувствительных субстратов CYP
              приводил к снижению AUC мидазолама (субстрата CYP3A4) на 92%,
              снижению AUC омепразола (субстрата CYP2C19) на 85% и снижению AUC
              S-варфарина (субстрата CYP2C9) на 46%. Препарат Эрлеада не вызывал
              клинически значимого воздействия на субстрат CYP2C8. Совместный
              прием препарата Эрлеада с лекарственными препаратами, которые
              метаболизируются в основном изоферментами CYP3A4 (например,
              дарунавир, фелодипин, мидазолам, симвастатин), CYP2C19 (например,
              диазепам, омепразол) или CYP2C9 (например, варфарин, фенитоин),
              может привести к ослаблению действия этих препаратов. По
              возможности рекомендуется заменить эти препараты, либо
              осуществлять контроль на предмет снижения их эффективности, если
              терапию решено продолжать. При совместном приеме препарата Эрлеада
              с варфарином следует контролировать уровень международного
              нормализованного отношения (МНО).
            </p>
            <p>
              Индукция изофермента CYP3A4 апалутамидом позволяет предполагать,
              что посредством активации ядерного прегнан-Х-рецептора (PXR) также
              может происходить индукция УДФ- глюкуронозилтрансферазы (УДФ-ГТ).
              Совместный прием препарата Эрлеада с препаратами, которые являются
              субстратами УДФ-ГТ (например, левотироксин, вальпроевая кислота),
              может привести к снижению экспозиции этих препаратов. При
              совместном применении препарата Эрлеада с субстратами УДФ-ГТ
              должна оцениваться потеря эффективности субстрата, а также может
              быть необходима корректировка дозы субстрата для поддержания
              оптимальных концентраций в плазме.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Влияние апалутамида на транспортеры лекарственных средств
            </p>
            <p>
              Показано, что апалутамид является слабым индуктором
              P-гликопротеина (P-gp), белка резистентности рака молочной железы
              (BCRP) и полипептида, транспортирующего органические анионы 1B1
              (OATP1B1). Исследование лекарственного взаимодействия с
              использованием «коктейльного» подхода показало, что совместный
              прием препарата Эрлеада с однократными пероральными дозами
              чувствительных субстратов транспортеров привел к снижению AUC
              фексофенадина (субстрата P-gp) на 30% и снижению AUC розувастатина
              (субстрата BCRP / OATP1B1) на 41%, но не повлиял на Cmax.
              Совместный прием препарата Эрлеада с препаратами, которые являются
              субстратами P-gp (например, колхицин, дабигатрана этексилат,
              дигокcин), BCRP или OATP1B1 (например, лапатиниб, метотрексат,
              розувастатин, репаглинид), может привести к ослаблению действия
              этих препаратов. При совместном приеме препарата Эрлеада с
              субстратами P-gp, BCRP или OATP1B1 необходимо оценивать потерю
              эффективности субстрата, а также может быть необходима
              корректировка дозы субстрата для поддержания оптимальных
              концентраций в плазме.
            </p>
            <p>
              Исходя из данных in vitro, нельзя исключить ингибирование
              апалутамидом и его N- дезметиловым метаболитом транспортера
              органических катионов 2 (OCT2), транспортера органических анионов
              3 (OAT3) и белков экструзии лекарственных препаратов и токсинов
              (MATE). Ингибирования in vitro транспортера органических анионов 1
              (OAT1) не наблюдалось.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Аналог гонадотропин-рилизинг-гормона (ГнРГ)
            </p>
            <p>
              У пациентов с мГЧРПЖ, получавших лейпрорелина ацетат (аналог
              ГнРГ), одновременное применение с препаратом Эрлеада не оказывало
              видимого эффекта на экспозицию лейпрорелина в равновесном
              состоянии.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Лекарственные препараты, удлиняющие интервал QT
            </p>
            <p>
              Поскольку андроген депривационная терапия может способствовать
              удлинению интервала QT, необходимо тщательно оценивать возможность
              одновременного применения препарата Эрлеада с другими
              лекарственными препаратами, в отношении которых известно, что они
              удлиняют интервал QT или способны вызывать возникновение пируэтной
              тахикардии, такими как антиаритмические препараты класса IA
              (например, хинидин, дизопирамид) или класса III (например,
              амиодарон, соталол, дофетилид, ибутилид), метадон, моксифлоксацин,
              нейролептики (например, галоперидол) и т.д. (см. раздел 4.4).
            </p>
            <p style={{ textDecoration: 'underline' }}>Дети</p>
            <p>Исследования взаимодействия проведены только у взрослых.</p>
            <h3 className="mt-2">4.6. Фертильность, беременность и лактация</h3>
            <p style={{ textDecoration: 'underline' }}>
              Контрацепция у мужчин и женщин
            </p>
            <p>
              Препарат Эрлеада может оказывать неблагоприятное влияние на
              развивающийся плод. Пациенты, имеющие половые контакты с
              партнершами с детородным потенциалом, должны использовать
              презервативы в сочетании с дополнительным высокоэффективным
              методом контрацепции во время терапии и в течение 3 месяцев после
              приема последней дозы препарата Эрлеада.
            </p>
            <p style={{ textDecoration: 'underline' }}>Беременность</p>
            <p>
              Препарат Эрлеада противопоказан к применению у беременных женщин
              или у женщин, с детородным потенциалом (см. раздел 4.3).
              Основываясь на исследовании репродуктивной токсичности на животных
              и механизме действия препарата, Эрлеада может оказать
              неблагоприятное влияние на плод и привести к прерыванию
              беременности при применении у беременной женщины. Данные о
              применении препарата Эрлеада у беременных женщин отсутствуют.
            </p>
            <p style={{ textDecoration: 'underline' }}>Лактация</p>
            <p>
              Сведения о проникновении апалутамида или его метаболитов в грудное
              молоко человека отсутствуют. Риск для детей, находящихся на
              грудном вскармливании не исключен. Препарат Эрлеада не следует
              применять в период грудного вскармливания.
            </p>
            <p style={{ textDecoration: 'underline' }}>Фертильность</p>
            <p>
              Основываясь на исследовании репродуктивной токсичности на
              животных, препарат Эрлеада может приводить к снижению фертильности
              у мужчин с репродуктивным потенциалом (см. раздел 5.3).
            </p>
            <h3 className="mt-2">
              4.7. Влияние на способность управлять транспортными средствами и
              работать с механизмами
            </h3>
            <p>
              Исследования по изучению влияния препарата Эрлеада на способность
              управлять транспортными средствами или работать с механизмами не
              проводились. Нет сведений, что препарат Эрлеада влияет на
              способность управлять транспортными средствами и работать с
              механизмами. Учитывая профиль побочных эффектов, в том числе
              возникновение судорог, следует предупредить пациентов о
              существующем риске в отношении управления транспортными средствами
              и выполнении других потенциально опасных видов деятельности,
              требующих повышенной концентрации внимания и быстроты
              психомоторных реакций.
            </p>
            <h3 className="mt-2">4.8. Нежелательные реакции</h3>
            <p style={{ textDecoration: 'underline' }}>
              Резюме профиля безопасности
            </p>
            <p>
              Наиболее распространенными нежелательными реакциями являются:
              утомляемость (26%), кожная сыпь (26% любой степени и 6% 3 или 4
              степени), гипертензия (22%), приливы жара (18%), артралгия (17%),
              диарея (16%), падения (13%) и снижение массы тела (13%). Другими
              важными нежелательными реакциями являются переломы (11%) и
              гипотиреоз (8%).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Табличное резюме нежелательных реакций
            </p>
            <p>
              Нежелательные реакции, наблюдаемые во время клинических
              исследований, приведены в таблице ниже и разделены на группы по
              частоте. По частоте реакции классифицируются следующим образом:
              очень часто (≥ 1/10); часто (≥ 1/100, но &lt; 1/10); нечасто (≥
              1/1 000, но &lt; 1/100); редко (≥ 1/10 000, но &lt; 1/1 000);
              очень редко ( &lt; 1/10 000); частота неизвестна (на основании
              имеющихся данных оценить невозможно).
            </p>
            <p>
              В каждой частотной группе нежелательные реакции представлены в
              порядке снижения степени тяжести.
            </p>
            <h3 className="mt-2">
              Таблица 1. Нежелательные реакции, выявленные в клинических
              исследованиях
            </h3>
            <img src={table1} style={{ width: '100%' }} className="mt-2" />

            <p>
              a Представленная частота нежелательных реакций основана на данных
              плацебо-контролируемого периода клинических исследований.
            </p>
            <p>
              b Включает гипотиреоз, повышение тиреотропного гормона в крови,
              снижение тироксина, аутоиммунный тиреоидит, снижение уровня
              свободного тироксина, снижение уровня трийодтиронина.
            </p>
            <p>
              c Включает транзиторную ишемическую атаку, острое нарушение
              мозгового кровообращения, расстройство мозгового кровообращения,
              ишемический инсульт, артериосклероз сонной артерии, стеноз сонной
              артерии, гемипарез, лакунарный инфаркт, лакунарный инсульт,
              тромботический инфаркт головного мозга, сосудистую энцефалопатию,
              инфаркт мозжечка, церебральный инфаркт и церебральную ишемию
            </p>
            <p>d Включает прикусывание языка.</p>
            <p>
              e Включает стенокардию, нестабильную стенокардию, инфаркт
              миокарда, острый инфаркт миокарда, окклюзию коронарной артерии,
              стеноз коронарной артерии, острый коронарный синдром,
              артериосклероз коронарной артерии, аномалии при проведении
              сердечного стресс-теста, повышение уровня тропонина, ишемию
              миокарда.
            </p>
            <p>
              f См. «Кожная сыпь» в разделе «Описание отдельных нежелательных
              реакций»
            </p>
            <p>
              g Нежелательные реакции, выявленные в пострегистрационном периоде.
            </p>
            <p>h См. раздел 4.4.</p>
            <p>
              i Включает перелом ребра, перелом позвонка поясничного отдела
              позвоночника, компрессионный перелом позвоночника, перелом
              позвоночника, перелом стопы, перелом тазобедренного сустава,
              перелом плечевой кости, перелом позвонка грудного отдела
              позвоночника, перелом верхней конечности, перелом крестца, перелом
              руки, перелом лонной кости, перелом вертлужной впадины, перелом
              лодыжки, компрессионный перелом, перелом хрящевой части ребра,
              перелом лицевой кости черепа, перелом нижней конечности,
              остеопоротический перелом, перелом костей запястья, отрывной
              перелом, перелом малоберцовой кости, перелом копчиковой кости,
              перелом костей таза, перелом лучевой кости, перелом грудины,
              стрессовый перелом, травматический перелом, перелом шейного отдела
              позвоночника, перелом шейки бедра, перелом большеберцовой кости.
              См. ниже подраздел Падения и переломы.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Описание отдельных нежелательных реакций
            </p>
            <p style={{ fontStyle: 'italic' }}>Кожная сыпь</p>
            <p>
              Кожная сыпь, связанная с применением апалутамида, чаще всего
              описывалась как макулярная или макулопапулезная сыпь. У пациентов
              регистрировались следующие варианты кожной сыпи: сыпь,
              макулопапулезная сыпь, генерализованная сыпь, крапивница, зудящая
              сыпь, макулезная сыпь, конъюнктивит, мультиформная эритему,
              папулезная сыпь, шелушение кожи, сыпь на половых органах,
              эритематозная сыпь, стоматит, лекарственная сыпь, язвенный
              стоматит, пустулезная сыпь, пузыри, папулы, пемфигоид, эрозия
              кожи, дерматит и везикулярная сыпь. Нежелательные реакции в виде
              кожной сыпи отмечались у 26% пациентов, получавших апалутамид.
              Сыпь 3 степени тяжести (определенная как покрывающая &gt; 30% всей
              площади поверхности тела) была отмечена у 6% пациентов, получавших
              апалутамид.
            </p>
            <p>
              Медиана количества дней до появления кожной сыпи составила 83 дня.
              У 78% пациентов сыпь разрешалась в среднем за 78 дней.
              Лекарственные средства для коррекции сыпи включали местные формы
              кортикостероидов, пероральные антигистаминные препараты, 19%
              пациентов получали системные кортикостероиды. Среди пациентов с
              кожной сыпью временно прекратили прием препарата 28% и снизили
              дозы препарата – 14% (см. раздел 4.2). Кожная сыпь рецидивировала
              у 59% пациентов, у которых препарат был применен повторно после
              временного прекращения терапии. Прием апалутамида из-за кожной
              сыпи был прекращен у 7% пациентов.
            </p>
            <p style={{ fontStyle: 'italic' }}>Падения и переломы</p>
            <p>
              В клиническом исследовании ARN-509-003 переломы отмечались у 11,7%
              пациентов, получавших апалутамид, и у 6,5% пациентов, получавших
              плацебо. В обеих группах лечения у половины пациентов были
              зарегистрированы случаи падения в течение 7 дней до перелома.
              Случаи падения наблюдались у 15,6% пациентов, получавших
              апалутамид, и у 9,0% пациентов, получавших плацебо (см. раздел
              4.4).
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Ишемическая болезнь сердца и ишемические цереброваскулярные
              нарушения
            </p>
            <p>
              В рандомизированном клиническом исследовании (SPARTAN) у пациентов
              с нмКРРПЖ ишемическая болезнь сердца наблюдалась у 4% пациентов,
              получавших апалутамид, и у 3% пациентов, получавших плацебо. В
              рандомизированном клиническом исследовании (TITAN) у пациентов с
              мГЧРПЖ ишемическая болезнь сердца наблюдалась у 4% пациентов,
              получавших апалутамид, и у 2% пациентов, получавших плацебо. В
              исследованиях SPARTAN и TITAN, 6 пациентов (0,5%), получавших
              апалутамид, и 2 пациента (0,2%), получавших плацебо, умерли от
              ишемической болезни сердца (см. раздел 4.4).
            </p>
            <p>
              В исследовании SPARTAN с медианами длительности терапии
              апалутамида и плацебо 32,9 и 11,5 месяца, соответственно,
              ишемические цереброваскулярные нарушения наблюдались у 4 % и 1 %
              пациентов, получавших апалутамид и плацебо (см. выше). В
              исследовании TITAN ишемические цереброваскулярные нарушения
              отмечались в одинаковом соотношении у пациентов в группе
              апалутамида (1,5 %) и плацебо (1,5 %). По результатам исследований
              SPARTAN и TITAN, 2 пациента (0,2 %), получавших апалутамид, умерли
              от ишемического цереброваскулярного нарушения, в то время как в
              группе плацебо ни один пациент не умер вследствие этих явлений
              (см. раздел 4.4).
            </p>
            <p style={{ fontStyle: 'italic' }}>Гипотиреоз</p>
            <p>
              Гипотиреоз, по результатам исследования тиреотропного гормона
              (ТТГ) через каждые 4 месяца, был зарегистрирован у 8% пациентов,
              получавших апалутамид, и у 2% пациентов, получавших плацебо.
              Нежелательные явления 3 или 4 степени тяжести отсутствовали.
              Развитие гипотиреоза наблюдалось у 30% пациентов, уже получавших
              заместительную терапию гормонами щитовидной железы, в группе
              апалутамида и у 3% пациентов в группе плацебо. У пациентов без
              заместительной гормональной терапии гипотиреоз развивался у 7%
              пациентов, получавших апалутамид, и у 2% пациентов, получавших
              плацебо. При наличии клинических показаний необходимо начать
              заместительную терапию гормонами щитовидной железы или
              скорректировать ее дозу (см. раздел 4.5).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Сообщения о подозреваемых нежелательных реакциях
            </p>
            <p>
              Важно сообщать о подозреваемых нежелательных реакциях после
              регистрации лекарственного препарата с целью обеспечения
              непрерывного мониторинга соотношения «польза-риск» лекарственного
              препарата. Медицинским работникам рекомендуется сообщать о любых
              подозреваемых нежелательных реакциях лекарственного препарата
              через национальные системы сообщения о нежелательных реакциях
              государств-членов Евразийского экономического союза.
            </p>
            <p style={{ textDecoration: 'underline' }}>Российская Федерация</p>
            <p>Адрес: 109012, г. Москва, Славянская площадь, д. 4, стр. 1</p>
            <p>
              Федеральная служба по надзору в сфере здравоохранения
              (Росздравнадзор)
            </p>
            <p>Тел.: (495) 698-45-38, (499) 578-02-30</p>
            <p>Эл. почта: pharm@roszdravnadzor.gov.ru</p>
            <p>Сайт: https://roszdravnadzor.gov.ru/</p>
            <p style={{ textDecoration: 'underline' }}>Республика Казахстан</p>
            <p>Адрес: 010000, г. Астана, ул. А. Иманова, д. 13</p>
            <p>
              РГП на ПХВ «Национальный центр экспертизы лекарственных средств и
              медицинских изделий» Комитета медицинского и фармацевтического
              контроля Министерства здравоохранения Республики Казахстан
            </p>
            <p>Тел.: (7172) 78-98-28</p>
            <p>Эл.почта: pdlc@dari.kz</p>
            <p>Сайт: http://www.ndda.kz</p>
            <h3 className="mt-2">4.9. Передозировка</h3>
            <p>
              Специфический антидот апалутамида отсутствует. При дозе, равной
              480 мг 1 раз в сутки (в 2 раза выше рекомендованной суточной
              дозы), дозолимитирующей токсичности отмечено не было.
            </p>
            <p style={{ textDecoration: 'underline' }}>Симптомы</p>
            <p>
              Побочные реакции в случае передозировки пока не наблюдались,
              предполагается, что реакции будут аналогичны побочным реакциям,
              перечисленным в разделе 4.8.
            </p>
            <p style={{ textDecoration: 'underline' }}>Лечение</p>
            <p>
              В случае передозировки необходимо прекратить прием препарата
              Эрлеада и начать общее поддерживающее лечение до уменьшения
              явлений клинической токсичности или ее разрешения.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="5. ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА">
            <h3>5.1. Фармакодинамические свойства</h3>
            <p>
              Фармакотерапевтическая группа: Противоопухолевые гормональные
              препараты и антагонисты гормонов; антагонисты гормонов и
              родственные соединения; антиандрогены. Код АТХ: L02BB05
            </p>
            <p style={{ textDecoration: 'underline' }}>Механизм действия</p>
            <p>
              Апалутамид – это принимаемый перорально селективный ингибитор
              андрогенового рецептора, который напрямую связывается с
              лиганд-связывающим доменом андрогенового рецептора. Апалутамид
              препятствует ядерной транслокации андрогенового рецептора,
              ингибирует связывание с ДНК, нарушает опосредованную андрогеновым
              рецептором транскрипцию и не обладает активностью в качестве
              агониста андрогеновых рецепторов (по данным доклинических
              исследований). В мышиных моделях рака предстательной железы
              введение апалутамида приводило к снижению пролиферации опухолевых
              клеток и увеличению апоптоза, что сопровождалось значительной
              противоопухолевой активностью. Активность основного метаболита,
              N-дезметилапалутамида, составляла одну треть от in vitro
              активности апалутамида.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Электрофизиология сердца
            </p>
            <p>
              Влияние апалутамида в дозе по 240 мг 1 раз в сутки на интервал QTc
              изучалось в открытом, неконтролируемом, многоцентровом
              исследовании интервала QT в одной группе из 45 пациентов с КРРПЖ.
              В равновесном состоянии максимальное среднее изменение интервала
              QTcF по сравнению с исходным составило 12,4 мс (верхняя граница
              двустороннего 90% ДИ: 16,0 мс). При анализе взаимосвязи системной
              экспозиции и интервала QT было высказано предположение о зависимом
              от концентрации апалутамида и его активного метаболита удлинении
              интервала QTcF.
            </p>
            <h3 className="mt-2">5.2. Фармакокинетические свойства</h3>
            <p>
              На фоне многократного приема 1 раз в сутки было отмечено
              пропорциональное дозе увеличение экспозиции апалутамида (Сmax и
              площади под кривой «концентрация – время» [AUC]) в диапазоне доз
              от 30 до 480 мг. После приема препарата в дозе 240 мг 1 раз в
              сутки, равновесные уровни апалутамида достигались через 4 недели,
              а среднее отношение кумуляции в сравнении с однократным приемом
              равнялось 5. В равновесном состоянии средние значения (CV%) Cmax и
              AUC для апалутамида составили 6 мкг/мл (28%) и 100 мкг×ч/мл (32%),
              соответственно. Суточные колебания плазменных концентраций
              апалутамида были небольшими со средним отношением между пиковой и
              минимальной концентрациями, равным 1,63. При повторном приеме было
              отмечено увеличение кажущегося клиренса (CL/F), что, вероятно,
              связано с индукцией апалутамидом собственного метаболизма.
            </p>
            <p>
              В равновесном состоянии средние значения (CV%) Cmax и AUC для
              основного активного метаболита, N-дезметилапалутамида, составили
              5,9 мкг/мл (18%) и 124 мкг×ч/мл (19%), соответственно. Для
              N-дезметилапалутамида характерен горизонтальный профиль
              зависимости концентрации от времени в равновесном состоянии со
              средним отношением пиковой и минимальной концентраций, равным
              1,27. Среднее (CV%) соотношение AUC метаболита/исходного препарата
              для N-дезметилапалутамида после многократного приема составило
              примерно 1,3 (21%). Исходя из системной экспозиции, относительной
              активности и фармакокинетических свойств, вероятно, что
              N-дезметилапалутамид вносит вклад в клиническую активность
              апалутамида.
            </p>
            <p style={{ textDecoration: 'underline' }}>Абсорбция</p>
            <p>
              После приема препарата перорально медиана времени до достижения
              пиковой концентрации в плазме (tmax) составила 2 часа (диапазон:
              от 1 до 5 часов). Средняя абсолютная биодоступность при
              пероральном приеме составила примерно 100%, что соответствует
              полному всасыванию апалутамида после приема препарата перорально.
              Прием апалутамида здоровыми добровольцами натощак и после приема
              пищи с высоким содержанием жиров не сопровождался клинически
              значимыми изменениями Cmax и AUC. После приема препарата с пищей
              было отмечено увеличение медианы времени до достижения tmax
              примерно на 2 часа (см. раздел 4.2).
            </p>
            <p>
              После перорального приема 4 таблеток апалутамида по 60 мг,
              диспергированных в яблочном пюре, Cmax и AUC были на 28% и 5% выше
              соответственно, чем при приеме 4 таблеток по 60 мг целиком натощак
              (см. раздел 4.2).
            </p>
            <p style={{ textDecoration: 'underline' }}>Распределение</p>
            <p>
              Средний кажущийся объем распределения апалутамида в равновесном
              состоянии составляет примерно 276 л. Объем распределения
              апалутамида превышает общий объем жидкости организма, что
              свидетельствует о значительном внесосудистом распределении.
              Апалутамид и N-дезметилапалутамид на 96% и на 95% соответственно
              связаны с белками плазмы, связывание преимущественно происходит с
              альбумином плазмы и не зависит от концентрации.
            </p>
            <p style={{ textDecoration: 'underline' }}>Биотрансформация</p>
            <p>
              После однократного приема меченного 14C-апалутамида перорально в
              дозе 240 мг большая часть 14C-радиоактивного вещества в плазме
              представляла собой апалутамид, его активный метаболит
              N-дезметилапалутамид и неактивное производное карбоксильной
              кислоты (соответственно 45%, 44% и 3% от общего значения 14C-AUC).
            </p>
            <p>
              Метаболизм является основным путем элиминации апалутамида.
              Метаболизм преимущественно осуществляется изоферментами CYP2C8 и
              CYP3A4 с формированием N- дезметилапалутамида. Далее апалутамид и
              N-дезметилапалутамид под действием карбоксилэстеразы
              метаболизируются до неактивного производного карбоксильной
              кислоты. Вклад изоферментов CYP2C8 и CYP3A4 в метаболизм
              апалутамида составляет 58% и 13% после однократного приема, а при
              многократном приеме при равновесном состоянии он составляет
              соответственно 40% и 37%.
            </p>
            <p style={{ textDecoration: 'underline' }}>Элиминация</p>
            <p>
              Апалутамид выводится, преимущественно в виде метаболитов, в
              основном с мочой. После однократного приема меченного
              радиоактивной меткой апалутамида перорально выведение 89% дозы
              происходило на протяжении вплоть до 70 дней после приема
              препарата; 65% дозы выводилось с мочой (1,2% в виде исходного
              вещества, 2,7% в виде N- дезметилапалутамида), а 24% с фекалиями
              (1,5% в виде неизмененного апалутамида, 2% в виде
              N-дезметилапалутамида).
            </p>
            <p>
              После однократного приема значение CL/F апалутамида составило 1,3
              л/ч с увеличением этого значения до 2 л/ч в равновесном состоянии
              после приема препарата 1 раз в сутки. Средний эффективный период
              полувыведения апалутамида в равновесном состоянии составляет около
              3 дней.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Особые группы пациентов
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Пациенты с нарушением функции почек и печени
            </p>
            <p>
              Не было отмечено значимых различий фармакокинетики апалутамида и
              N- дезметилапалутамида у пациентов с нарушением функции почек
              легкой степени (расчетная скорость клубочковой фильтрации (рСКФ)
              60-89 мл/мин/1,73 м2 ) или нарушением функции почек средней
              степени (рСКФ 30-59 мл/мин/1,73 м2 ), нарушением функции печени
              легкой степени (класс А по классификации Чайлд – Пью) или
              нарушением функции печени средней степени (класс В по
              классификации Чайлд – Пью), для возраста в диапазоне от 18 до 94
              лет и для различных рас.
            </p>
            <p>
              Потенциальное влияние нарушения функции почек тяжелой степени или
              последней стадии почечной недостаточности (рСКФ ≤ 29 мл/мин/1,73
              м2 ) не было установлено в связи с недостаточным объемом данных.
              Для пациентов с нарушением функции печени тяжелой степени (класс С
              по классификации Чайлд – Пью) клинические и фармакокинетические
              данные недоступны.
            </p>
          </Accordion>
          <div className="mt-2">
            <Accordion title="6. ФАРМАЦЕВТИЧЕСКИЕ СВОЙСТВА">
              <h3>6.1. Перечень вспомогательных веществ</h3>
              <p style={{ textDecoration: 'underline' }}>Ядро таблетки</p>
              <ul style={{ listStyle: 'inside' }}>
                <li>Гипромеллозы ацетата сукцинат</li>
                <li>Кремния диоксид коллоидный</li>
                <li>Кроскармеллоза натрия</li>
                <li>Целлюлоза микрокристаллическая (тип 101)</li>
                <li>Целлюлоза микрокристаллическая (силанизированная)</li>
                <li>Магния стеарат</li>
              </ul>
              <p style={{ textDecoration: 'underline' }}>
                Пленочная оболочка «Опадрай® II 85F210036 зеленый»
              </p>
              <ul style={{ listStyle: 'inside' }}>
                <li>Поливиниловый спирт, частично гидролизованный</li>
                <li>Титана диоксид (Е171)</li>
                <li>Макрогол</li>
                <li>Тальк</li>
                <li>Железа оксид желтый (Е172)</li>
                <li>Железа оксид черный (Е172)</li>
              </ul>
              <h3 className="mt-2">6.2. Несовместимость</h3>
              <p>Не применимо</p>
              <h3 className="mt-2">6.3. Срок годности (срок хранения)</h3>
              <p>3 года</p>
              <h3 className="mt-2">
                6.4. Особые меры предосторожности при хранении
              </h3>
              <p>
                Хранить при температуре не выше 30 °С в оригинальной упаковке
                (флаконе или блистере).
              </p>
              <h3 className="mt-2">
                6.5. Характер и содержание первичной упаковки
              </h3>
              <p>
                По 120 таблеток во флаконе из полиэтилена высокой плотности,
                запечатанном контролем первого вскрытия и укупоренном
                полипропиленовой крышкой с системой защиты от вскрытия детьми.
                Флакон также содержит осушитель. По 1 флакону вместе с
                инструкцией по медицинскому применению (листком-вкладышем) в
                пачке картонной;
              </p>
              <p>или</p>
              <p>
                По 24 таблетки в блистере с внешним неотделимым картонным слоем
                и внутренним слоем из поливинилхлорид-полихлортрифторэтилена
                (ПВХ-ПХТФЭ) с продавливаемой алюминиевой фольгой.
              </p>
              <p>
                По 1 блистеру в картонном футляре. По 5 картонных футляров
                вместе с инструкцией по медицинскому применению
                (листком-вкладышем) в пачке картонной. Допускается наличие
                контроля первого вскрытия на пачке картонной. Не все размеры
                упаковок могут быть доступны для реализации.
              </p>
              <h3 className="mt-2">
                6.6. Особые меры предосторожности при уничтожении
                использованного лекарственного препарата или отходов, полученных
                после применения лекарственного препарата и другие манипуляции с
                препаратом
              </h3>
              <p>
                Весь оставшийся лекарственный препарат и отходы следует
                уничтожить в соответствии с установленными национальным
                законодательством требованиями.
              </p>
            </Accordion>
          </div>
          <div className="mt-2">
            <Accordion title="7. ДЕРЖАТЕЛЬ РЕГИСТРАЦИОННОГО УДОСТОВЕРЕНИЯ">
              <p>Российская Федерация</p>
              <p>ООО «Джонсон & Джонсон»</p>
              <p>121614, г. Москва, ул. Крылатская, д. 17, корп. 2</p>
              <p>Тел.: (495) 755-83-57</p>
              <p>Факс: (495) 755-83-58</p>
              <p>Эл. почта: DrugSafetyRU@its.jnj.com</p>
              <h3>
                7.1. Представитель держателя регистрационного удостоверения
              </h3>
              <p style={{ textDecoration: 'underline' }}>
                Претензии потребителей направлять по адресу:
              </p>
              <p style={{ fontStyle: 'italic' }}>Российская Федерация</p>
              <p>ООО «Джонсон & Джонсон»</p>
              <p>121614, г. Москва, ул. Крылатская, д. 17, корп. 2</p>
              <p>Тел.: (495) 755-83-57</p>
              <p>Факс: (495) 755-83-58</p>
              <p>Эл. почта: DrugSafetyRU@its.jnj.com</p>
              <p style={{ fontStyle: 'italic' }}>Республика Казахстан</p>
              <p>Филиал ООО «Джонсон & Джонсон» в Республике Казахстан</p>
              <p>050040, г. Алматы, ул. Тимирязева, 42, павильон 23 «А»</p>
              <p>Тел.: (727) 356-88-11</p>
              <p>Факс: (727) 356-88-13</p>
              <p>Эл. почта: DrugSafetyKZ@its.jnj.com</p>
            </Accordion>
          </div>
          <div className="mt-2">
            <Accordion title="8. НОМЕР РЕГИСТРАЦИОННОГО УДОСТОВЕРЕНИЯ">
              <p>ЛП-№(003123)-(РГ-RU)</p>
            </Accordion>
          </div>
          <div className="mt-2">
            <Accordion title="9. ДАТА ПЕРВИЧНОЙ РЕГИСТРАЦИИ (ПОДТВЕРЖДЕНИЯ РЕГИСТРАЦИИ, ПЕРЕРЕГИСТРАЦИИ)">
              <p>01.09.2023</p>
            </Accordion>
          </div>
          <div className="mt-2">
            <Accordion title="10. ДАТА ПЕРЕСМОТРА ТЕКСТА">
              <p>
                Общая характеристика лекарственного препарата Эрлеада доступна
                на информационном портале Евразийского экономического союза в
                информационно-коммуникационной сети «Интернет»
                http://eec.eaeunion.org/ .
              </p>
            </Accordion>
          </div>
          <div className="mt-2">
            <Accordion title="Источники">
              <ul>
                <li>
                  Общая характеристика лекарственного препарата Эрлеада,
                  ЛП-№(003123)-(РГ-RU) от 01.09.2023 г.
                </li>
              </ul>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default ErleadaInstruction;
