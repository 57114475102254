<template>
  <div>
    <!-- Показываем только если showResults равно false -->
    <div v-if="!showResults">
      <div ref="section" class="form-view-component">
        <h2 class="title">Группа пациентов</h2>
        <div class="wrap">
          <common-questions class="common-questions" @cardSelected="handleCardSelection" />
        </div>
        <app-button
          ref="app-button"
          text="Показать результат"
          @click="showResult"
          @keyup.enter="showResult"
        />
      </div>
    </div>
    <!-- Результаты будут отображаться только после нажатия на кнопку "Показать результат" -->
    <div v-if="showResults" class="result-container">
      <!-- Используем данные из selectedCard -->
      <div v-if="selectedCard" class="result-container__main">
        <h2 class="title">
          Оценка общей выживаемости в течение 5 лет на
          основе данных реальной клинической практики
        </h2>
        <img v-if="selectedCard" :src="selectedCard.chart" alt="График">
      </div>
      <div v-if="selectedCard" class="result-container__aside">
        <h2 class="title">Группа пациентов</h2>
        <div class="result-container__aside-radio">{{ selectedCard.radio }}</div>
        <h2 class="title title_mt">Подгруппа пациентов</h2>
        <div v-if="selectedCard.gleasonIndex" class="result-container__aside-data">
          <h5>Локализация метастазов:</h5>
          <p>{{ selectedCard.description }}</p>
          <h5>Индекс Глисона:</h5>
          <p>{{ selectedCard.gleasonIndex }}</p>
          <h5>Возраст:</h5>
          <p>{{ selectedCard.age }}</p>
        </div>
        <div v-if="selectedCard.doublingTime" class="result-container__aside-data">
          <h5>Уровень ПСА на момент установки диагноза:</h5>
          <p>{{ selectedCard.description }}</p>
          <h5>Возраст:</h5>
          <p>{{ selectedCard.age }}</p>
          <h5>Время удвоения ПСА:</h5>
          <p>{{ selectedCard.doublingTime }}</p>
        </div>
        <p class="result-container__aside-text">на момент индексного события (диагноз мРПЖ)</p>
        <!-- Добавляем обработчик нажатия на кнопку "Выбрать другую группу пациентов" -->
        <app-button
          ref="app-button-1"
          class="result-container__btn"
          text="Выбрать другую группу пациентов"
          @click="resetSelection"
          @keyup.enter="resetSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommonQuestions from '@/components/common/form-view/CommonQuestions';
import AppButton from '@/components/common/AppButton.vue';

export default {
  name: 'FormView',
  components: {
    CommonQuestions,
    AppButton,
  },
  data() {
    return {
      selectedCard: null,
      showResults: false, // Флаг для отображения результатов
    };
  },
  mounted() {
    this.showResult();
  },
  methods: {
    handleCardSelection(selectedCard) {
      this.selectedCard = selectedCard;
    },
    showResult() {
      // Показываем результаты только если карточка выбрана
      if (this.selectedCard) {
        this.showResults = true;
        this.$emit('show');
        const introComponent = this.$el.closest('.intro-component');
        if (introComponent) {
          introComponent.querySelector('.form').classList.add('form-res');
          introComponent.querySelector('.wrap').classList.add('wrap-res');
        }
      }
    },
    // Метод для сброса выбранной карточки и скрытия результатов
    resetSelection() {
      this.selectedCard = null;
      this.showResults = false;
      this.$emit('show');
      // Удаляем класс .form-res при сбросе выбора карточки
      const introComponent = this.$el.closest('.intro-component');
      if (introComponent) {
        introComponent.querySelector('.form').classList.remove('form-res');
        introComponent.querySelector('.wrap').classList.remove('wrap-res');
      }
    },
    scrollToButton() {
      const button = this.$refs['app-button'];
      if (button) {
        this.$refs.section.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-view-component {
  .title {
    @include _mb-32;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .common-questions,
  .patient-data,
  .choice-therapy,
  .additional-factor {
    @include _mb-32;
  }

  .hint {
    font-size: 12px;
    color: $grey;
    opacity: .7;
  }
}
.result-container {
  display: flex;
  gap: 24px;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }

  &__main {
    background-color: #fff;
    padding: 32px
  }

  &__aside {
    background-color: #fff;
    padding: 32px;
  }

  .title {
    margin-bottom: 16px;
    font-weight: 600;

    &_mt {
      margin-top: 24px;
    }
  }

  &__aside-radio {
    border: 1px solid rgba(51, 51, 51, 0.10);
    border-radius: 8px;
    padding: 16px;
    color: #333;
    font-family: "Verdana Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__aside-text {
    margin-top: 8px;
    margin-bottom: 24px;
    color: rgba(51, 51, 51, 0.60);
    font-family: "Verdana Pro";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
  }
  &__aside-data {
    border: 1px solid rgba(51, 51, 51, 0.10);
    border-radius: 8px;
    padding: 16px;

    h5 {
      padding: 0;
      margin: 0;
      margin-top: 8px;
      color: rgba(51, 51, 51, 0.6);
      font-family: "Verdana Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;

      &:first-child {
        margin-top: 0;
      }
    }
    p {
      color: #333;
      margin-top: 4px;
      padding-bottom: 6px;
      font-family: "Verdana Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      border-bottom: 1px solid #ebebeb;

      &:last-child {
        border-bottom: none;
      }
    }
  }
  &__btn {
    font-size: 12px;
  }
}
</style>
