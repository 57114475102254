import React from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import NavHead from "../../components/navhead";
import GoBtn from "../../components/gobtn";

const ClinicTest = () => {
  const nav = useNavigate();
  const { testId } = useParams();

  return (
    <div className="content_x__block">
      <div className="content_x">
        <NavHead title="Клинические случаи" />
        <div className="content mt-2 pl-2 pr-2">
          <h3>Выберите сложность</h3>
          <div className="mt-2 ">
            <button
              className="gobtnarrow flex align-center justify-between"
              onClick={() => nav(`/clinic/:${testId.slice(1)}/:BERGINNER`)}
            >
              Новичок
            </button>
          </div>
          <div className="mt-2 ">
            <button
              className="gobtnarrow flex align-center justify-between"
              onClick={() => nav(`/clinic/:${testId.slice(1)}/:EXPERT`)}
            >
              Эксперт
            </button>
          </div>
        </div>
      </div>
      <div className="mt-2 pl-2 pr-2 back_btn_x">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ClinicTest;
