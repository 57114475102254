import React, { useEffect, useState } from 'react';
import NavHead from '../../components/navhead';
import { useNavigate } from 'react-router';
import GoBtn from '../../components/gobtn';
import axios from 'axios';
import ym from 'react-yandex-metrika';

const ClinicList = ({ url }) => {
  const nav = useNavigate();
  const [cases, setCases] = useState([]);
  useEffect(() => {
    axios.get(`${url}/api/webapp/clinical_cases`).then((res) => {
      setCases(res.data);
    });
  }, []);
  return (
    <div className="content_x__block">
      <NavHead title="Клинические случаи" />
      <div className="content_x">
        <div className="content mt-2 pl-2 pr-2">
          <p className="mb-1">
            Обычный день в нашей клинике, но только сегодня Вы можете сами
            выбрать того пациента, которого хотите принять. Познакомимся с ними?
          </p>
          <h3>Выберите, кому Вы хотите помочь, кликнув на описание.</h3>
        </div>
        {cases.map((item, index) => (
          <div
            key={index}
            className="clinic-post-item ml-2 mt-2"
            onClick={() => {
              ym('reachGoal', 'page_clinic');
              nav(`/clinic/:${item.id}`);
            }}
          >
            <div className="img">
              <img src={item.picture} alt="img" width={380} />
            </div>
            <div className="topical-post-item__info pb-1">
              <p className="title">
                {item.name}, {item.age}
              </p>
              <p
                className="meta mb-1"
                dangerouslySetInnerHTML={{ __html: item.case_description }}
              >
                {}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2 pl-2 pr-2 back_btn_x">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ClinicList;
