import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import NavHead from "../../components/navhead";
import likeIcon1 from "../../img/like-1.svg";
import likeIcon2 from "../../img/like-2.svg";
import likeIcon3 from "../../img/like-3.svg";
import { Button, Modal } from "antd";
import axios from "axios";

const ClinicTest = ({ url }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [testList, setTestList] = useState();
  const [cases, setCases] = useState([]);
  const [startTest, setStartTest] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [likes, setLikes] = useState([
    likeIcon1,
    likeIcon1,
    likeIcon1,
    likeIcon1,
    likeIcon1,
  ]);
  useEffect(() => {
    axios
      .get(
        `${url}/api/webapp/clinical_cases_questions?clinical_case_id=${params.testId.slice(
          1
        )}`
      )
      .then((res) => {
        setTestList(
          res.data.filter(
            (item) => item.difficulty === params.difficulty.slice(1)
          )
        );
      });
    axios.get(`${url}/api/webapp/clinical_cases`).then((res) => {
      setCases(
        res.data.filter((item) => item.id === Number(params.testId.slice(1)))
      );
    });
  }, [params.difficulty, params.testId]);
  const showAnswer = (e, item) => {
    if (e.target.id.split("_").slice(1)[0] === item.correct_answers[0]) {
      setCorrectAnswer(true);
      setIsModalOpen((isModalOpen) => ({
        ...isModalOpen,
        [String(item.id)]: true,
      }));
      e.target.style.getPropertyValue("background") ===
      "linear-gradient(180deg, #D4F3FC 0%, #D7D5F0 100%)"
        ? e.target.style.removeProperty(
            "background",
            "linear-gradient(180deg, #D4F3FC 0%, #D7D5F0 100%)"
          )
        : e.target.style.setProperty(
            "background",
            "linear-gradient(180deg, #D4F3FC 0%, #D7D5F0 100%)"
          );
    } else {
      setCorrectAnswer(false);
      setIsModalOpen((isModalOpen) => ({
        ...isModalOpen,
        [String(item.id)]: true,
      }));
    }
  };
  const handleOk = (id) => {
    let currentIndex = testList.findIndex((item) => item.id === id);
    if (currentIndex < testList.length - 1) {
      setCurrentQuestion(
        testList.filter((item, index) => index === currentIndex + 1)
      );
      if (correctAnswer) {
        setLikes([
          ...likes.slice(0, currentIndex),
          likeIcon2,
          ...likes.slice(currentIndex + 1),
        ]);
      } else {
        setLikes([
          ...likes.slice(0, currentIndex),
          likeIcon3,
          ...likes.slice(currentIndex + 1),
        ]);
      }
      setIsModalOpen(false);
    } else {
      navigate(`/clinic/test-end/:${params.testId.slice(1)}`);
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="content_x__block">
      <NavHead title="Клинические случаи" />
      <div className="content_x">
        {!startTest ? (
          <div className="test">
            <h3 className="title_0">Встречайте Вашего пациента</h3>
            <p className="mb-2">{cases[0]?.name}</p>
            <img src={cases[0]?.picture} width={350} alt="patient" />
            <p className="ml-2 mb-2" dangerouslySetInnerHTML={{ __html: cases[0]?.case_text }}></p>
            <button
              className="gobtn"
              onClick={() => {
                setStartTest(true);
                setCurrentQuestion(
                  testList.filter((item, index) => index === 0)
                );
              }}
            >
              Помочь пациенту
            </button>
          </div>
        ) : (
          <div className="test">
            <img
              src={cases[0]?.picture}
              width={350}
              alt="patient"
              className="mb-2"
            />
            <div>
              {likes.map((item, index) => (
                <img
                  src={item}
                  className="like ml-1 mr-1"
                  key={index}
                  alt="like"
                />
              ))}
            </div>
            {currentQuestion.map((item) => (
              <div key={item.id} className="test">
                <p className="title_0">{item.text}</p>
                <p
                  className={item.answers_1 ? "answer" : null}
                  onClick={(e) => showAnswer(e, item)}
                  id={Object.keys(item)[6]}
                >
                  {item.answers_1}
                </p>
                <p
                  className={item.answers_2 ? "answer" : null}
                  onClick={(e) => showAnswer(e, item)}
                  id={Object.keys(item)[7]}
                >
                  {item.answers_2}
                </p>
                <p
                  className={item.answers_3 ? "answer" : null}
                  onClick={(e) => showAnswer(e, item)}
                  id={Object.keys(item)[8]}
                >
                  {item.answers_3}
                </p>
                <p
                  className={item.answers_4 ? "answer" : null}
                  onClick={(e) => showAnswer(e, item)}
                  id={Object.keys(item)[9]}
                >
                  {item.answers_4}
                </p>
                <p
                  className={item.answers_5 ? "answer" : null}
                  onClick={(e) => showAnswer(e, item)}
                  id={Object.keys(item)[10]}
                >
                  {item.answers_5}
                </p>
                <p
                  className={item.answers_6 ? "answer" : null}
                  onClick={(e) => showAnswer(e, item)}
                  id={Object.keys(item)[6]}
                >
                  {item.answers_6}
                </p>
                <Modal
                  title={correctAnswer ? null : "Ответ неверный"}
                  open={isModalOpen[item.id]}
                  onCancel={handleCancel}
                  style={{ width: 300 }}
                  footer={[
                    <Button
                      key="continue"
                      className="continue"
                      onClick={() => handleOk(item.id)}
                    >
                      Продолжить
                    </Button>,
                  ]}
                >
                  {!correctAnswer ? (
                    <h3 style={{ color: "#F22486", textAlign: "center" }}>
                      Правильный ответ №{item.correct_answers}
                    </h3>
                  ) : (
                    <h3 style={{ color: "#F22486", textAlign: "center" }}>
                      Правильный ответ!
                    </h3>
                  )}
                  <p>{item.explanation}</p>
                </Modal>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClinicTest;
