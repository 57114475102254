import React, { useEffect, useState } from "react";
import GoBtn from "../components/gobtn";
import NavHead from "../components/navhead";
import axios from "axios";
import { useParams } from "react-router-dom";
import loader from "../../src/img/loader.gif";

const Post = ({ url }) => {
  const { postId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/current_news`)
      .then((res) => {
        setIsLoading(false);
        setPost(res.data[postId.slice(1)]);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [postId]);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <div className="content_x__block">
          <NavHead />
          <div className="content_x">
            <h1 className="title pl-2 pr-2">{post.header}</h1>
            <div className="meta mt-2 pl-2 pr-2">
              {post?.created_date?.slice(0, 10).split("-")[2] +
                "." +
                post?.created_date?.slice(0, 10).split("-")[1] +
                "." +
                post?.created_date?.slice(0, 10).split("-")[0]}
            </div>
            <div className="mt-2 pl-2 pr-2">
              <img src={post.picture} alt="post" style={{ width: "100%" }} />
            </div>
            <div className="content mt-2 pl-2 pr-2">
              <p dangerouslySetInnerHTML={{ __html: post.news_text }}></p>
            </div>
          </div>
          <div className="mt-2 pl-2 pr-2 back_btn_x">
            <GoBtn title="Вернуться на главную" />
          </div>
        </div>
      )}
    </>
  );
};

export default Post;
