import React from 'react';
import Accordion from '../../components/accordion';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import img1 from '../../img/balversa/efficiency1.png';
import img2 from '../../img/balversa/efficiency2.png';
import img3 from '../../img/balversa/efficiency3.png';

const BalversaEfficiency = () => {
  return (
    <>
      <NavHead title="Эффективность" />
      <div className="content mt-2 pl-2 pr-2">
        <p>
          Эффективность препарата Балверса (эрдафитиниб) изучалась в
          сравнительном рандомизированном исследовании III фазы THOR (BLC3001).
        </p>
        <p>Дизайн исследования THOR, 1 когорта:</p>
        <ul style={{ listStyle: 'inside' }}>
          <li>Наличие FGFR-аберраций</li>
          <li>1 или 2 линии предшествующей терапии</li>
          <li>Предшествующая терапия анти-PD-L(1)</li>
          <li>
            Метастатический или нерезектабельный местно-распространенный
            уротелиальный рак
          </li>
          <li>Документированный PD-статус</li>
          <li>ECOG 0-2</li>
          <li>Адекватные функции костного мозга, печени, почек</li>
        </ul>
        <p>Первичная конечная точка: общая выживаемость (ОВ).</p>
        <p>
          Вторичные конечные точки: выживаемость без прогрессирования (ВБП),
          частота объективных ответов (ЧОО), длительность ответа,
          фармакокинетика, безопасность, пациентские оценки динамики симптомов.
        </p>
        <p>
          Исходные характеристики пациентов были хорошо сбалансированы между
          ветвями исследования. Все включенные пациенты имели FGFR-аберрации.
        </p>
        <strong>
          Балверса (эрдафитиниб) снижала риск смерти на 36% по сравнению с
          химиотерапией:
        </strong>
        <p>
          Медиана наблюдения составила 15,9 мес. На основании этих
          предварительных результатов независимый наблюдательный комитет
          рекомендовал остановить исследование, расслепить группы и перевести
          всех пациентов с химиотерапии на эрдафитиниб.
        </p>
        <img src={img1} style={{ width: '100%' }} />
        <strong>
          Балверса (эрдафитиниб) снижала риск прогрессирования или смерти на 42%
          по сравнению с химиотерапией:
        </strong>
        <img src={img2} style={{ width: '100%' }} />
        <strong>
          Частота объективного ответа на Балверсу (эрдафитиниб) в 4 раза выше,
          чем на химиотерапию:
        </strong>
        <img src={img3} style={{ width: '100%' }} />
        <p>
          Балверса (эрдафитиниб) – препарат, показавший преимущество по ОВ, ВБП
          и ЧОО в сравнении с химиотерапией, для пациентов с распространенным
          уротелиальным раком и наличием FGFR-аберраций, получивших
          иммунотерапию ингибиторами PD-(L)1.
        </p>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>
                Loriot Y., et al. N Engl J Med. 2023 Nov 23;389(21):1961-1971.
                doi: 10.1056/NEJMoa2308849. Epub 2023 Oct 21.
              </li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default BalversaEfficiency;
