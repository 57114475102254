import React from "react";

import back from "../../img/back-arrow.svg";
import { Link } from "react-router-dom";

const ProfileEditHeader = () => {
  return (
    <div className="edit-header">
      <Link to="/profile" className="back-button">
        <img src={back} alt="back" />
      </Link>
      <p>Личная информация</p>
    </div>
  );
};

export default ProfileEditHeader;
