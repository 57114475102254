import React from 'react';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';

import memoPDF from '../../files/memo-for-ophthalmologist.pdf';

export default function ForDoctor() {
  return (
    <>
      <NavHead title="Материалы врачу" />
      <div className="content mt-2 pl-2 pr-2">
        <a href={memoPDF} target="_blank" className="link">
          Краткий обзор безопасности – пособие для офтальмологов
        </a>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
}
