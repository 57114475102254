import React, { useRef } from 'react';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import { questions, answers2 } from './dataList';
import { useNavigate } from 'react-router-dom';
import ym from 'react-yandex-metrika';

const LocalRpj = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const setAnswer = (index, value) => {
    answers2[index] = value;
    answers2.filter((item) => item === null).length === 0
      ? ref.current.classList.remove('disabled')
      : ref.current.classList.add('disabled');
  };
  const seeResult = () => {
    ym('reachGoal', 'reco_local_next');
    return answers2[0] === 1 &&
      answers2[1] === 1 &&
      answers2[2] === 1 &&
      answers2[3] === 1 &&
      answers2[4] === 1
      ? navigate('/board/localrpj/:a1')
      : answers2[3] === 4 && answers2[4] === 1
      ? navigate('/board/localrpj/:a4')
      : (answers2[0] === 3 ||
          answers2[1] === 3 ||
          answers2[2] === 3 ||
          answers2[3] === 3) &&
        answers2[4] === 1
      ? navigate('/board/localrpj/:a3')
      : (answers2[0] === 2 ||
          answers2[1] === 2 ||
          answers2[2] === 2 ||
          answers2[3] === 2) &&
        answers2[4] === 1
      ? navigate('/board/localrpj/:a2')
      : navigate('/board/localrpj/:a5');
  };
  return (
    <>
      <NavHead title="Локализованный / местно-распространённый РПЖ" />
      <div className="content mt-2 pl-2 pr-2">
        <h2>Введите данные</h2>
        <form className="board-form mt-2">
          {questions[0].map((item, index) => (
            <div className="board-form-item pd-2 mb-2" key={index}>
              <div className="label">{item.title}</div>
              <div className="inputs mt-2 flex align-center gap-2">
                {item.answers.map((elem, ind) => (
                  <div key={ind}>
                    <input
                      type="radio"
                      name={elem.name}
                      value={elem.value}
                      id={elem.id}
                    />
                    <label
                      htmlFor={elem.id}
                      onClick={() => setAnswer(index, elem.value)}
                    >
                      {elem.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="flex gap-2 mt-2">
            <button className="clear" onClick={() => navigate(0)}>
              Сбросить
            </button>
            <button
              ref={ref}
              type="button"
              className="next disabled"
              onClick={seeResult}
            >
              Продолжить
            </button>
          </div>
        </form>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default LocalRpj;
