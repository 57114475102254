import React from 'react';
import GoBtn from '../../../components/gobtn';
import NavHead from '../../../components/navhead';
import titan from '../../../img/erleada/mMGCHRPJ/titan.svg';
import Accordion from '../../../components/accordion';

const MgchrpjResult = () => {
  return (
    <div className="content_x__block">
      <NavHead title="Результаты исследования" />
      <div className="content_x mt-2 pl-2 pr-2">
        <h2>TITAN. Результаты исследования</h2>
        <p>
          После предварительного анализа было выполнено расслепление
          исследования, и 40% пациентов из группы плацебо были переведены на
          апалутамид+АДТ.
        </p>
        <h2>
          Долгосрочные результаты лечения апалутамидом у пациентов с мГЧРПЖ
        </h2>
        <p>~4 года медиана наблюдения</p>
        <p className="mb-2">40% пациентов перешли из группы ПБО в группу </p>
        <img src={titan} style={{ width: '100%' }} alt="titan-reslut" />
        <h2>Доказанные преимущества апалутамида:</h2>
        <ul style={{ listStyle: 'inside' }}>
          <li>Отдаление кастрационной резистентности</li>
          <li>Улучшение ВБП2</li>
          <li>Поддержание КЖС3</li>
        </ul>

        <h2>При длительном наблюдении:</h2>
        <ul style={{ listStyle: 'inside' }}>
          <li>
            Профиль безопасности апалутамида соответствовал первичному анализу
            исследования
          </li>
          <li>Не было выявлено новых нежелательных явлений</li>
        </ul>
        <div className="mt-2">
          <Accordion title="Сокращения">
            <ul>
              <li>
                IPCW – взвешенный коэффициент обратной связи цензурирования
              </li>
              <li>ITT – все пациенты, включенные в группу</li>
              <li>АДТ – андроген-депривационная терапия</li>
              <li>ВБП2 – выживаемость без повторного прогрессирования</li>
              <li>КЖСЗ – качество жизни, связанное со здоровьем</li>
              <li>
                мГЧРПЖ – метастатический гормоночувствительный рак
                предстательной железы
              </li>
              <li>ПБО – плацебо</li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>
                Kim N. Chi et al, Journal of Clinical Oncology 39, no. 20 (July
                10, 2021) 2294-2303.DOI: 10.1200/JCO.20.03488.
              </li>
            </ul>
          </Accordion>
        </div>
      </div>
      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default MgchrpjResult;
