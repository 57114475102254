import React, { useEffect, useState } from "react";

import edit from "../../img/edit.svg";
import { Link } from "react-router-dom";


const ProfileHeader = ({ fName, lName, spec, patronymic }) => {
  const [avatarId, setAvatarId] = useState(1);

  console.log(lName, fName)
  useEffect(() => {
    const avatar = Number(localStorage.getItem("JNJ-avatar"));
    console.log(avatar);
    setAvatarId(avatar || 1);
  }, []);

  return (
    <section className="profile-info">
      <div className="profile-img">
        <img
          src={require(`../../img/avatars/avatar${avatarId}.svg`)}
          alt="avatar"
        />
      </div>
      <div className="profile-text">
        <h1>
          {lName} {fName} {patronymic}
        </h1>
        <p>{spec}</p>
      </div>
      <Link to="edit" className="profile-edit">
        <img src={edit} alt="edit" />
        <p>Редактировать</p>
      </Link>
    </section>
  );
};

export default ProfileHeader;
