import React from 'react';
import Accordion from '../../components/accordion';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import img from '../../img/balversa/123.png';
import table71 from '../../img/balversa/table7.1.png';
import table72 from '../../img/balversa/table7.2.png';
import table9 from '../../img/balversa/table9.png';
const BalversaInstruction = () => {
  return (
    <>
      <NavHead title="ОХЛП (Инструкция)" />
      <div className="content mt-2 pl-2 pr-2">
        <h2>ОБЩАЯ ХАРАКТЕРИСТИКА ЛЕКАРСТВЕННОГО ПРЕПАРАТА БАЛВЕРСА</h2>
        <p>
          ▼ Данный лекарственный препарат подлежит дополнительному мониторингу.
          Это позволит быстро выявить новую информацию по безопасности. Мы
          обращаемся к работникам системы здравоохранения с просьбой сообщать о
          любых подозреваемых нежелательных реакциях. Порядок сообщения о
          нежелательных реакциях представлен в разделе 4.8.
        </p>
        <div className="mt-2">
          <Accordion title="1. НАИМЕНОВАНИЕ ЛЕКАРСТВЕННОГО ПРЕПАРАТА">
            <ul>
              <li>Балверса, 3 мг, таблетки, покрытые пленочной оболочкой.</li>
              <li>Балверса, 4 мг, таблетки, покрытые пленочной оболочкой.</li>
              <li>Балверса, 5 мг, таблетки, покрытые пленочной оболочкой.</li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="2. КАЧЕСТВЕННЫЙ И КОЛИЧЕСТВЕННЫЙ СОСТАВ">
            <p>Действующее вещество: эрдафитиниб</p>
            <ul>
              <li>
                Балверса, 3 мг, таблетки, покрытые пленочной оболочкой. Каждая
                таблетка содержит 3 мг эрдафитиниба.
              </li>
              <li>
                Балверса, 4 мг, таблетки, покрытые пленочной оболочкой. Каждая
                таблетка содержит 4 мг эрдафитиниба.
              </li>
              <li>
                Балверса, 5 мг, таблетки, покрытые пленочной оболочкой. Каждая
                таблетка содержит 5 мг эрдафитиниба.
              </li>
            </ul>
            <p>
              Полный перечень вспомогательных веществ приведен в разделе 6.1.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="3. ЛЕКАРСТВЕННАЯ ФОРМА">
            <p>Таблетки, покрытые пленочной оболочкой.</p>
            <ul>
              <li>
                Балверса, 3 мг, таблетки, покрытые пленочной оболочкой. <br />
                Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой
                желтого цвета, с гравировкой «3» на одной стороне и «EF» на
                другой стороне. Цвет таблетки на поперечном срезе
                зеленовато-желтый.
              </li>
              <li>
                Балверса, 4 мг, таблетки, покрытые пленочной оболочкой.
                <br />
                Круглые двояковыпуклые таблетки, покрытые пленочной оболочкой
                оранжевого цвета, с гравировкой «4» на одной стороне и «EF» на
                другой стороне. Цвет таблетки на поперечном срезе
                зеленовато-желтый.
              </li>
              <li>
                Балверса, 5 мг, таблетки, покрытые пленочной оболочкой.
                <br /> Круглые двояковыпуклые таблетки, покрытые пленочной
                оболочкой коричневого цвета, с гравировкой «5» на одной стороне
                и «EF» на другой стороне. Цвет таблетки на поперечном срезе
                зеленовато-желтый.
              </li>
            </ul>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="4. КЛИНИЧЕСКИЕ ДАННЫЕ">
            <h3 className="mt-2"> 4.1. Показания к применению</h3>
            <p>
              Препарат Балверса показан для лечения взрослых пациентов с
              местнораспространенным или метастатическим уротелиальным раком
              (УР), в опухолевой ткани которых имеются определенные мутации
              и/или слияния генов рецепторов фактора роста фибробластов (FGFR)2
              или FGFR3, и у которых наблюдается прогрессирование заболевания во
              время или после по крайней мере одной линии предшествующей
              химиотерапии, в том числе в течение 12 месяцев после
              неоадъювантной или адъювантной химиотерапии (см. раздел 5.1.,
              подраздел «Клиническая эффективность и безопасность»).
            </p>

            <h3 className="mt-2">4.2. Режим дозирования и способ применения</h3>

            <p>
              Терапию препаратом Балверса должен назначать и проводить врач,
              имеющий опыт терапии противоопухолевыми препаратами. Перед приемом
              препарата Балверса у пациентов должно быть подтверждено наличие
              определенных мутаций и/или слияний генов FGFR2 или FGFR3 по
              результатам молекулярно-генетического тестирования (см. раздел
              5.1., подраздел «Клиническая эффективность и безопасность»).
            </p>
            <p style={{ textDecoration: 'underline' }}>Режим дозирования</p>
            <p style={{ fontStyle: 'italic' }}>Взрослые</p>
            <p>
              Рекомендованная начальная доза препарата Балверса составляет 8 мг
              1 раз в сутки с увеличением дозы до 9 мг 1 раз в сутки в
              зависимости от концентрации фосфатов в сыворотке крови и
              переносимости, оценку которых проводят в период между 14 и 21
              днями после начала терапии препаратом (см. пункт «Коррекция
              дозы»). Лечение следует продолжать вплоть до прогрессирования
              заболевания или развития неприемлемой токсичности.
            </p>
            <p style={{ fontStyle: 'italic' }}> Пропуск дозы</p>
            <p>
              В случае пропуска дозы препарата Балверса ее необходимо принять
              как можно быстрее. На следующий день необходимо возобновить
              плановый ежедневный прием препарата Балверса. Не следует принимать
              дополнительные таблетки для восполнения пропущенной дозы. Если
              когда-либо после приема препарата Балверса произойдет рвота,
              очередную дозу следует принять на следующий день.
            </p>
            <p style={{ fontStyle: 'italic' }}>Коррекция дозы</p>
            <p>
              Проводится титрация с повышением доз под фармакодинамическим
              контролем на основании концентраций фосфата в сыворотке. Следует
              провести измерение концентрации фосфата (PO&#x2084;) в сыворотке в
              промежутке между 14 и 21 днями после начала терапии. Если
              концентрация фосфата (PO&#x2084;) в сыворотке крови составляет
              меньше 5,5 мг/дл, и в отсутствие лекарственной токсичности
              (офтальмологических нарушений или нежелательных реакций степени 2
              и выше), дозу следует повысить до 9 мг/сут.
            </p>
            <p style={{ fontStyle: 'italic' }}>Снижение дозы</p>
            <p>
              Возможные снижения дозы и действия при развитии нежелательных
              реакций описаны в таблицах с 1 по 4.
            </p>
            <h2>Таблица 1. Порядок снижения дозы препарата Балверса</h2>
            <table>
              <tr>
                <td style={{ border: '1px solid black' }}>Доза</td>
                <td style={{ border: '1px solid black' }}>
                  Первое снижение дозы
                </td>
                <td style={{ border: '1px solid black' }}>
                  Второе снижение дозы
                </td>
                <td style={{ border: '1px solid black' }}>
                  Третье снижение дозы
                </td>
                <td style={{ border: '1px solid black' }}>
                  Четвертое снижение дозы
                </td>
                <td style={{ border: '1px solid black' }}>
                  Пятое снижение дозы
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>9 мг</td>
                <td style={{ border: '1px solid black' }}>8 мг</td>
                <td style={{ border: '1px solid black' }}>6 мг</td>
                <td style={{ border: '1px solid black' }}>5 мг</td>
                <td style={{ border: '1px solid black' }}>4 мг</td>
                <td style={{ border: '1px solid black' }}>
                  Остановить терапию
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>8 мг</td>
                <td style={{ border: '1px solid black' }}>6 мг</td>
                <td style={{ border: '1px solid black' }}>5 мг</td>
                <td style={{ border: '1px solid black' }}>4 мг</td>
                <td style={{ border: '1px solid black' }}>
                  Остановить терапию
                </td>
                <td style={{ border: '1px solid black' }}></td>
              </tr>
            </table>
            <p>
              Гиперфосфатемия является ожидаемым и транзиторным отклонением на
              терапии ингибиторами FGFR (см. раздел 5.1., подраздел
              «Фармакодинамические эффекты»). Следует ежемесячно проводить
              мониторинг концентрации фосфата в сыворотке крови. При повышении
              концентрации фосфата у пациентов, получающих препарат Балверса,
              следует руководствоваться указаниями по коррекции дозы в таблице
              2. При стойком повышении концентрации фосфата можно рассмотреть
              вопрос о добавлении не содержащего кальций препарата, связывающего
              фосфат (например, севеламера карбоната).
            </p>
            <h2>
              Таблица 2. Рекомендуемые коррекции дозы на основании концентрации
              фосфата в сыворотке крови при приеме препарата Балверса уже после
              проведенной титрации с повышением доз
            </h2>
            <table>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Концентрация фосфата в сыворотке</h3>
                </td>
                <td style={{ border: '1px solid black' }}>
                  <h3>Коррекция дозы препарата Балверса</h3>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <p style={{ width: '200%' }}>
                  У всех пациентов следует ограничить потребление фосфатов до
                  600-800 мг в сутки. При концентрации фосфатов в сыворотке
                  крови выше 7,0 мг/дл следует рассмотреть возможность
                  добавления фосфат-связывающего препарата до снижения
                  концентрации фосфатов в сыворотке крови до 5,5 мг/дл.
                </p>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  5,6-6,9 мг/дл (1,8-2,3 ммоль/л)
                </td>
                <td style={{ border: '1px solid black' }}>
                  Продолжать терапию препаратом Балверса в текущей дозе
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  7,0-9,0 мг/дл (2,3-2,9 ммоль/л)
                </td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить прием препарата Балверса с еженедельной оценкой
                  до возвращения концентрации фосфатов до менее 5,5 мг/дл (или
                  до исходного). Затем возобновить терапию препаратом Балверса в
                  той же дозе. При сохранении гиперфосфатемии на протяжении
                  более 1 недели возможно снижение дозы.
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  {' '}
                  &gt; 9,0 мг/дл (&gt;2,9 ммоль/л)
                </td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить терапию препаратом Балверса с еженедельной
                  оценкой до возвращения концентрации фосфатов до &lt;5,5 мг/дл
                  (или до исходного). Затем можно возобновить терапию препаратом
                  Балверса в дозе на один уровень ниже.
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  &gt; 10,0 мг/дл (&gt; 3,2 ммоль/л) или значительное изменение
                  функции почек по сравнению с исходной или гиперкальциемия
                  степени 3
                </td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить терапию препаратом Балверса с еженедельной
                  оценкой до возвращения концентрации фосфатов до &lt;5,5 мг/дл
                  (или до исходного). Затем можно возобновить терапию препаратом
                  Балверса в дозе на два уровня ниже.
                </td>
              </tr>
            </table>
            <p style={{ fontStyle: 'italic' }}>
              Действия при развитии офтальмологических нарушений
            </p>
            <p>
              Перед началом терапии препаратом Балверса следует провести
              исходное офтальмологическое обследование, включающее тест с сеткой
              Амслера, осмотр глазного дна, оценку остроты зрения и, при наличии
              такой возможности, оптическую когерентную томографию (ОКТ).
            </p>
            <p>
              Для профилактики и лечения синдрома сухого глаза следует
              использовать искусственную слезу, часто наносить увлажняющие или
              смазывающие глазные гели, по крайней мере каждые 2 часа на
              протяжении времени бодрствования. При тяжелом течении синдрома
              сухого глаза, связанного с терапией, требуется консультация
              офтальмолога.
            </p>
            <p>
              Далее необходимо проводить ежемесячный контроль за состоянием
              пациента, включая выполнение теста с сеткой Амслера, а при
              выявлении каких-либо отклонений – руководствоваться указаниями в
              таблице 3.
            </p>
            <h2>
              Таблица 3. Действия в случае развития офтальмологических нарушений
              на терапии препаратом Балверса
            </h2>
            <table>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Оценка тяжести</h3>
                </td>
                <td style={{ border: '1px solid black' }}>
                  <h3>Коррекция дозы препарата Балверса</h3>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Степень 1: </h3>Бессимптомная; выявляется только при
                  клиническом или диагностическом обследовании
                </td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить до нормализации. При нормализации в течение 4
                  недель возобновить терапию в дозе на один уровень ниже. Затем,
                  при отсутствии возобновления реакции в течение одного месяца,
                  рассмотреть возможность повторного повышения дозы. При
                  стабильном состоянии на протяжении двух последовательных
                  офтальмологических обследований, но при отсутствии
                  нормализации, следует возобновить терапию в дозе на один
                  уровень ниже.
                </td>
              </tr>

              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Степень 2: </h3>Острота зрения 20/40 или выше, или
                  снижение зрения на 3 линии и менее от исходного
                </td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить до нормализации. При нормализации в течение 4
                  недель возобновить в дозе на один уровень ниже.
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Степень 3: </h3>Острота зрения ниже 20/40 или снижение
                  зрения на более 3 линий от исходного
                </td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить до нормализации. При разрешении в течение 4
                  недель можно восстановить терапию в дозе на два уровня ниже.
                  При возобновлении явления следует рассмотреть окончательное
                  прекращение терапии.
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Степень 4: </h3>Острота зрения 20/200 или ниже в
                  пораженном глазе
                </td>
                <td style={{ border: '1px solid black' }}>
                  Окончательно прекратить терапию.
                </td>
              </tr>
            </table>
            <p style={{ fontStyle: 'italic' }}>
              Коррекция дозы при других нежелательных реакциях
            </p>
            <p>
              При применении препарата Балверса отмечались изменения со стороны
              кожи, слизистых оболочек и ногтей. Следует руководствоваться
              указаниями по коррекции дозы, изложенными в таблице 4.
            </p>
            <h2>
              Таблица 4. Рекомендованные коррекции дозы в случае нежелательных
              реакций на терапии препаратом Балверса
            </h2>
            <table>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  <h3>Тяжесть нежелательной реакцииа</h3>
                </td>
                <td style={{ border: '1px solid black' }}>
                  <h3>Коррекция дозы препарата Балверса</h3>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>Степень 3</td>
                <td style={{ border: '1px solid black' }}>
                  Приостановить терапию препаратом Балверса до разрешения до
                  степени 1 или до исходных значений с последующим
                  возобновлением терапии в дозе на один уровень ниже.
                </td>
              </tr>

              <tr>
                <td style={{ border: '1px solid black' }}>Степень 4</td>
                <td style={{ border: '1px solid black' }}>
                  Окончательно прекратить терапию.
                </td>
              </tr>
            </table>
            <p>
              <sup>a</sup> Коррекция дозы проводится по степеням тяжести,
              основанным на Общих критериях терминологии нежелательных явлений
              Национального института рака (NCI CTCAE)
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Особые группы пациентов
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Лица пожилого возраста (пациенты в возрасте от 65 лет и старше)
            </p>
            <p>
              В целом, различий в безопасности и эффективности препарата между
              лицами пожилого возраста и лицами более молодого возраста не
              наблюдалось. У лиц пожилого возраста какой-либо специальной
              коррекции дозы не требуется (см. раздел 5.2.).
            </p>
            <p style={{ fontStyle: 'italic' }}>Нарушение функции почек</p>
            <p>
              На основании данных популяционных фармакокинетических анализов, у
              пациентов с нарушением функции почек легкой или средней степени
              коррекции дозы не требуется (см. раздел 5.2.). Данные у пациентов
              с тяжелым нарушением функции почек отсутствуют.
            </p>
            <p style={{ fontStyle: 'italic' }}>Нарушение функции печени</p>
            <p>
              На основании данных фармакокинетических анализов, у пациентов с
              нарушением функции печени легкой или средней степени коррекции
              дозы не требуется (см. раздел 5.2.). Данные у пациентов с
              нарушением функции печени тяжелой степени ограничены.
            </p>
            <p style={{ textDecoration: 'underline' }}>Дети</p>
            <p>
              Безопасность и эффективность препарата Балверса у детей в возрасте
              от 0 до 18 лет не установлены. Данные отсутствуют.
            </p>
            <p style={{ textDecoration: 'underline' }}>Способ применения</p>
            <p>
              Таблетки следует проглатывать целиком примерно в одно и то же
              время каждый день; допускается прием как с пищей, так и без нее.
            </p>

            <h3 className="mt-2">4.3. Противопоказания</h3>
            <p>
              Гиперчувствительность к эрдафитинибу или к любому из
              вспомогательных веществ, перечисленных в разделе 6.1.
            </p>

            <h3 className="mt-2">
              4.4. Особые указания и меры предосторожности при применении
            </h3>
            <p style={{ textDecoration: 'underline' }}>
              Нарушения со стороны органа зрения
            </p>
            <p>
              Как и при применении других ингибиторов тирозинкиназ, применение
              препарата Балверса может приводить к развитию нарушений со стороны
              органа зрения. Наиболее часто отмечавшимися проявлениями
              центральной серозной ретинопатии были хориоретинопатия (8%),
              отслойка сетчатки (5%), а также отслойка пигментного эпителия
              сетчатки (5%). Центральная серозная ретинопатия отмечалась у 23
              пациентов (23%), получавших препарат Балверса в исследовании
              BLC2001 ежедневно в дозе 8 мг. У большинства (70%) пациентов с
              центральной серозной ретинопатией, в основном – степени 1 и 2,
              были выявлены отклонения в тесте с сеткой Амслера. В исследовании
              BLC2001 центральная серозная ретинопатия у 12 пациентов
              разрешилась, а у 11 пациентов явления продолжались, во многих
              случаях со снижением тяжести, и большинство явлений были степени
              1. В связи с центральной серозной ретинопатией имели место
              прерывания терапии и снижения дозы у 8,1% и 13,1% пациентов,
              соответственно, а у 3 пациентов (3%) препарат Балверса был
              отменен. У 55% пациентов развивались нарушения со стороны органа
              зрения, отличные от центральной серозной ретинопатии, включая
              синром сухого глаза (19%) и нечеткость зрения (17%).
            </p>
            <p>
              До начала терапии препаратом Балверса необходимо проводить
              скрининг пациентов на предмет наличия нарушений со стороны органа
              зрения, с проведением теста с сеткой Амслера, осмотром глазного
              дна, оценкой остроты зрения и, при возможности, ОКТ. Для
              профилактики и лечения синдрома сухого глаза следует использовать
              искусственную слезу, часто наносить увлажняющие или смазывающие
              глазные гели, по крайней мере каждые 2 часа на протяжении времени
              бодрствования. При тяжелых случаях синдрома сухого глаза,
              связанного с препаратом, пациента следует направить к офтальмологу
              для проведения обследования. После этого необходимо проводить
              обследование ежемесячно, а при выявлении каких-либо отклонений,
              либо когда пациент сам сообщит о каких-либо явлениях со стороны
              глаз или нарушениях зрения, руководствоваться указаниями в таблице
              3 (см. раздел 4.2.).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Гиперфосфатемия и минерализация мягких тканей
            </p>
            <p>
              Прием препарата Балверса может вызывать гиперфосфатемию,
              приводящую к минерализации мягких тканей, кожному кальцинозу,
              неуремической кальцификации и сосудистой кальцификации. Повышение
              концентрации фосфатов в сыворотке крови представляет собой
              фармакодинамический эффект препарата Балверса (см. раздел 5.1.,
              подраздел «Фармакодинамические эффекты»). О развитии нежелательной
              лекарственной реакции в виде гиперфосфатемии сообщалось у 77%
              пациентов, получавших препарат Балверса. Медиана времени до
              развития гиперфосфатемии любой степени составила 20 дней
              (диапазон: 8-116 дней) после начала терапии препаратом Балверса. В
              период терапии препаратом Балверса 32% пациентов получали
              фосфат-связывающие препараты. Кожный кальциноз, неуремическая
              кальцифилаксия и сосудистая кальцификация наблюдались у 0,3%
              пациентов, получающих препарат Балверса.
            </p>
            <p>
              Следует проводить мониторинг гиперфосфатемии на протяжении всего
              лечения. У всех пациентов следует ограничить потребление фосфатов
              до 600-800 мг в сутки. При концентрации фосфатов в сыворотке крови
              выше 7,0 мг/дл следует рассмотреть возможность добавления
              фосфат-связывающего препарата до снижения концентрации фосфатов в
              сыворотке крови до &lt;5,5 мг/дл. Следует приостановить прием,
              снизить дозу или окончательно прекратить терапию препаратом
              Балверса на основании продолжительности и тяжести гиперфосфатемии
              (см. раздел 4.2., подраздел «Режим дозирования», пункт «Коррекция
              дозы», таблица 2).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Нарушения со стороны кожи, слизистых оболочек и ногтей
            </p>
            <p>
              Стоматит, сухость во рту, сухость кожи, алопеция, синдром
              ладонно-подошвенной эритродизестезии, онихолизис, паронихии,
              дистрофия ногтей отмечались у более 15% пациентов. Наиболее часто
              отмечаемые нежелательные лекарственные реакции степени 3 или выше
              (&gt;1%) включали стоматит, дистрофию ногтей, синдром
              ладонно-подошвенной эритродизестезии, паронихии, поражение ногтей,
              кератит, онихолизис (см. раздел 4.8.). Следует проинформировать
              пациентов о необходимости обращения к лечащему врачу при развитии
              прогрессирующих или непереносимых нарушений со стороны кожи,
              слизистых оболочек и ногтей.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Токсичность в отношении эмбриона и плода
            </p>
            <p>
              Результаты исследований репродукции у животных позволяют говорить,
              что эрдафитиниб может причинить вред плоду в случае его приема
              беременной женщиной. В исследовании токсичности в отношении
              эмбриона и плода, проведенном у крыс, эрдафитиниб обладал
              эмбриотоксическими и тератогенными свойствами при показателях
              экспозиции ниже тех показателей экспозиции, которые отмечаются у
              человека на всех изученных дозах (см. раздел 4.2.). Беременных
              женщин необходимо предупредить о потенциальном риске для плода.
              Пациенткам с сохраненным репродуктивным потенциалом следует
              рекомендовать использовать высокоэффективные методы контрацепции
              до и во время терапии, а также на протяжении 3 месяцев после
              завершающей дозы (см. раздел 4.6.).
            </p>

            <h3 className="mt-2">
              4.5. Взаимодействие с другими лекарственными препаратами и другие
              виды взаимодействия
            </h3>
            <p style={{ textDecoration: 'underline' }}>
              Эффект прочих препаратов в отношении препарата Балверса
            </p>
            <p>
              В Таблице 5 представлены варианты взаимодействия с другими
              лекарственными препаратами, влияющие на экспозицию препарата
              Балверса или на концентрацию фосфатов в сыворотке крови, и тактика
              их клинического ведения.
            </p>
            <h2>
              Таблица 5. Взаимодействие с другими лекарственными препаратами,
              влияющее на препарат Балверса
            </h2>
            <table>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Умеренные ингибиторы изофермента CYP2C9 или сильные ингибиторы
                  изофермента CYP3A4
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Совместное применение препарата Балверса с умеренными
                      ингибиторами изофермента CYP2C9 (например, флуконазолом)
                      или сильными ингибиторами изофермента CYP3A4 (например,
                      итраконазолом) приводило к повышению концентраций
                      эрдафитиниба в плазме крови.
                    </li>
                    <li>
                      Повышение концентраций эрдафитиниба в плазме крови может
                      привести к увеличению связанной с препаратом токсичности
                      (см. раздел 4.4.).
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Рассмотреть возможность применения альтернативной терапии
                      с применением препаратов, не являющихся умеренными
                      ингибиторами изофермента CYP2C9 или сильными ингибиторами
                      изофермента CYP3A4, в период лечения препаратом Балверса.
                    </li>
                    <li>
                      Если совместное применение с умеренными ингибиторами
                      изофермента CYP2C9 или сильными ингибиторами изофермента
                      CYP3A4 неизбежно, следует проводить тщательный мониторинг
                      на предмет наличия нежелательных лекарственных реакций и
                      рассмотреть возможность коррекции дозы соответственно (см.
                      раздел 4.2.). При отмене умеренного ингибитора изофермента
                      CYP2C9 или сильного ингибитора изофермента CYP3A4 доза
                      препарата Балверса может быть повышена при отсутствии
                      связанной с препаратом токсичности.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Сильные индукторы изоферментов CYP2C9 или CYP3A4
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Совместное применение препарата Балверса с сильными
                      индукторами изоферментов CYP2C9 или CYP3A4 (например,
                      рифампицином) может приводить к существенному снижению
                      концентрации эрдафитиниба в плазме крови.
                    </li>
                    <li>
                      Снижение концентраций эрдафитиниба в плазме крови может
                      приводить к снижению активности.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Избегать совместного применения сильных индукторов
                      изоферментов CYP2C9 или CYP3A4 с препаратом Балверса.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Умеренные индукторы изоферментов CYP2C9 или CYP3A4
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Совместное применение препарата Балверса с умеренными
                      индукторами изоферментов CYP2C9 или CYP3A4 может приводить
                      к снижению концентрации эрдафитиниба в плазме крови.
                    </li>
                    <li>
                      Снижение концентраций эрдафитиниба в плазме может
                      приводить к снижению активности.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      При необходимости совместного применения умеренного
                      индуктора изоферментов CYP2C9 или CYP3A4 в начале лечения
                      препаратом Балверса следует применять дозу препарата
                      Балверса в соответствии с рекомендациями (8 мг 1 раз в
                      сутки с возможностью повышения до 9 мг 1 раз в сутки с
                      учетом концентрации фосфатов в сыворотке крови, измеренных
                      на 14-21 день, и переносимости).
                    </li>
                    <li>
                      При необходимости совместного применения умеренного
                      индуктора изоферментов CYP2C9 или CYP3A4 после периода
                      повышения стартовой дозы на основе концентрации фосфата в
                      сыворотке крови и переносимости следует повысить дозу
                      препарата Балверса до 9 мг.
                    </li>
                    <li>
                      При отмене умеренного индуктора изоферментов CYP2C9 или
                      CYP3A4 следует продолжить применение препарата Балверса в
                      той же дозе при отсутствии связанной с препаратом
                      токсичности.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Препараты, изменяющие концентрацию фосфатов в сыворотке крови
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Совместное применение препарата Балверса с другими
                      препаратами, изменяющими концентрацию фосфатов в сыворотке
                      крови, может вызвать повышение или снижение концентрации
                      фосфатов в сыворотке крови (см. раздел 5.1.).
                    </li>
                    <li>
                      Изменение концентрации фосфатов в сыворотке крови в связи
                      с применением препаратов, влияющих на концентрацию
                      фосфатов в сыворотке крови (помимо эрдафитиниба), может
                      отразиться на концентрации фосфатов в сыворотке крови –
                      показателе, необходимом для оценки необходимости повышения
                      стартовой дозы с учетом концентрации фосфатов в сыворотке
                      крови (см. раздел 4.2.).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Избегать совместного применения препаратов, изменяющих
                      концентрацию фосфатов в сыворотке крови, с препаратом
                      Балверса до периода повышения стартовой дозы с учетом
                      концентрации фосфатов в сыворотке крови (дни 14 - 21) (см.
                      раздел 4.2.).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Препараты, уменьшающие кислотность среды
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Эрдафитиниб является веществом с адекватной растворимостью
                      в диапазоне значений pH от 1 до 7,4. Предполагается, что
                      препараты, уменьшающие кислотность среды (например,
                      антацидные средства, H2-антагонисты или ингибиторы
                      протонной помпы) не будут влиять на биодоступность
                      эрдафитиниба.
                    </li>
                    <li>
                      Коррекция дозы препарата Балверса при совместном
                      применении с препаратами, уменьшающим кислотность среды,
                      не требуется.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Избегать совместного применения препаратов, изменяющих
                      концентрацию фосфатов в сыворотке крови, с препаратом
                      Балверса до периода повышения стартовой дозы с учетом
                      концентрации фосфатов в сыворотке крови (дни 14 - 21) (см.
                      раздел 4.2.).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Препараты, воздействующие на транспортные системы
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Эрдафитиниб является субстратом и ингибитором P-
                      гликопротеина. Предполагается, что ингибиторы P-
                      гликопротеина не будут оказывать клинически значимого
                      влияния на экспозицию эрдафитиниба.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Коррекция дозы препарата Балверса при совместном
                      применении с препаратами, воздействующими на транспортные
                      системы, не требуется.
                    </li>
                  </ul>
                </td>
              </tr>

              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>Севеламер</h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Клинически значимых различий в фармакокинетике
                      эрдафитиниба у пациентов, получающих севеламер, не
                      наблюдалось.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Коррекция дозы препарата Балверса при совместном
                      применении с севеламером не требуется.
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
            <p style={{ textDecoration: 'underline' }}>
              Эффект препарата Балверса в отношении других лекарственных
              препаратов
            </p>
            <p>
              В таблице 6 описано влияние препарата Балверса на другие препараты
              и клиническое ведение в таких ситуациях.
            </p>
            <h2>
              Таблица 6. Взаимодействие с другими лекарственными препаратами,
              влияющее на препарат Балверса
            </h2>
            <table>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>Субстраты изофермента CYP3A4</h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Совместное применение препарата Балверса с субстратами
                      изофермента CYP3A4 может привести к изменению концентраций
                      субстратов изофермента CYP3A4 в плазме крови.
                    </li>
                    <li>
                      Изменение концентраций субстратов изофермента CYP3A4 в
                      плазме крови может привести к утрате активности или
                      повышению токсичности субстратов изофермента CYP3A4.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Рассмотреть альтернативные варианты терапии с применением
                      препаратов, которые не являются субстратами OCT2, или
                      рассмотреть возможность снижения дозы субстратов OCT2
                      (например, метформина) на основе переносимости.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>Субстраты OCT2</h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Эрдафитиниб является ингибитором OCT2 in vitro. Совместное
                      применение препарата Балверса с субстратами OCT2 может
                      привести к изменению концентраций субстратов OCT2 в плазме
                      крови.
                    </li>
                    <li>
                      Повышение концентраций субстратов OCT2 в плазме крови
                      может привести к повышению токсичности субстратов OCT2.
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Рассмотреть альтернативные варианты терапии с применением
                      препаратов, которые не являются субстратами OCT2, или
                      рассмотреть возможность снижения дозы субстратов OCT2
                      (например, метформина) на основе переносимости.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ border: '1px solid black', width: '100%' }}>
                <h2 style={{ width: '200%' }}>
                  Субстраты P-гликопротеина (P-gp)
                </h2>
              </tr>
              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая значимость
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Совместное применение препарата Балверса с субстратами P-
                      гликопротеина может приводить к повышению концентраций
                      субстратов P-гликопротеина в плазме крови.
                    </li>
                    <li>
                      Повышение концентраций субстратов P-гликопротеина в плазме
                      крови может приводить к повышению токсичности субстратов
                      P-гликопротеина.
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td style={{ border: '1px solid black' }}>
                  Клиническая тактика
                </td>
                <td style={{ border: '1px solid black' }}>
                  <ul style={{ listStyle: 'inside' }}>
                    <li>
                      Если совместного применения препарата Балверса с
                      субстратами P-гликопротеина избежать не удается, следует
                      применять препарат Балверса по меньшей мере за 6 часов до
                      или через не менее 6 часов после применения субстратов P-
                      гликопротеина с узким терапевтическим индексом.
                    </li>
                  </ul>
                </td>
              </tr>
            </table>

            <h3 className="mt-2">4.6. Фертильность, беременность и лактация</h3>
            <p style={{ textDecoration: 'underline' }}>
              Женщины с детородным потенциалом (контрацепция у мужчин и женщин)
            </p>
            <p>
              У женщин, способных к деторождению, рекомендуется провести
              высокочувствительный тест на беременность перед началом приема
              препарата Балверса. Препарат Балверса при приеме беременными
              женщинами может причинить вред плоду. Пациенткам, способным к
              деторождению, необходимо рекомендовать использовать
              высокоэффективные методы контрацепции до и во время терапии, а
              также на протяжении 3 месяцев после завершающей дозы препарата
              Балверса. Пациенты мужского пола должны применять методы
              контрацепции (например, пользоваться презервативами), а также не
              выступать в качестве доноров спермы и не сохранять сперму во время
              терапии и на протяжении 3 месяцев после завершающей дозы препарата
              Балверса.
            </p>
            <p style={{ textDecoration: 'underline' }}>Беременность</p>
            <p>
              Отсутствуют какие-либо данные, полученные при применении препарата
              у человека, на основании которых можно было бы оценить связанные с
              эрдафитинибом риски. Результаты исследований репродукции у
              животных позволяют говорить, что эрдафитиниб может причинить вред
              плоду в случае его приема беременной женщиной. В исследовании
              токсичности в отношении эмбриона и плода, проведенном у крыс,
              эрдафитиниб обладал эмбриотоксическими и тератогенными свойствами
              при показателях экспозиции ниже тех показателей экспозиции,
              которые отмечаются у человека на всех изученных дозах (см. раздел
              4.2.). Токсичность в отношении плода характеризовалась дефектами
              кистей/стоп, а также мальформациями ряда крупных кровеносных
              сосудов, таких как аорта.
            </p>
            <p>
              забеременеет во время терапии препаратом Балверса, следует
              разъяснить пациентке потенциальную опасность для плода, а также
              предоставить ей сведения о существующих клинических возможностях и
              вариантах дальнейшей терапии. Следует рекомендовать пациенткам
              обратиться к врачу в случае наступления беременности и подозрении
              на беременность во время терапии препаратом Балверса и на
              протяжении 3 месяцев после ее завершения.
            </p>
            <p style={{ textDecoration: 'underline' }}>Лактация</p>
            <p>
              Отсутствуют данные о выделении эрдафитиниба в человеческое молоко,
              об эффектах препарата Балверса в отношении детей, находящихся на
              грудном вскармливании или в отношении выработки молока. Учитывая
              потенциальную возможность серьезных нежелательных реакций,
              связанных с препаратом Балверса, у детей, находящихся на грудном
              вскармливании, женщинам не рекомендуется кормить грудью во время
              терапии препаратом Балверса, а также на протяжении 3 месяцев после
              завершающей дозы препарата Балверса.
            </p>
            <p style={{ textDecoration: 'underline' }}>Фертильность</p>
            <p>
              Данные о влиянии препарата Балверса на фертильность у человека
              отсутствуют. Исходя из результатов исследований на животных (см.
              раздел 5.3.), препарат Балверса может способствовать снижению
              фертильности у женщин.
            </p>

            <h3 className="mt-2">
              4.7. Влияние на способность управлять транспортными средствамии
              работать с механизмами
            </h3>
            <p>
              Исследований, которые позволили бы определить эффекты эрдафитиниба
              в отношении способности управлять транспортными средствами и
              работать с механизмами, не проводилось. Тем не менее, при
              применении ингибиторов FGFR, включая и препарат Балверса, описано
              развитие нарушений со стороны органа зрения, включая центральную
              серозную ретинопатию или кератит. При появлении у пациентов
              нарушений зрения, обусловленных терапией, рекомендуется
              воздержаться от управления автомобилем и другими транспортными
              средствами вплоть до разрешения соответствующих эффектов (см.
              раздел 4.4.).
            </p>

            <h3 className="mt-2">4.8. Нежелательные реакции</h3>
            <p style={{ textDecoration: 'underline' }}>
              Резюме профиля безопасности
            </p>
            <p>
              На всем протяжении этого раздела изложены нежелательные реакции.
              Нежелательные реакции определяются как нежелательные явления,
              которые были расценены на основании комплексной оценки имеющейся о
              них информации как связанные с применением эрдафитиниба. В
              отдельно взятых случаях не представляется возможным достоверно
              определить причинно-следственную связь с применением эрдафитиниба.
              Кроме того, поскольку клинические исследования проводились в
              весьма разнообразных условиях, не представляется возможным
              непосредственно сравнивать частоту нежелательных реакций,
              наблюдаемую в клинических исследованиях какого-либо одного
              препарата, с аналогичной частотой в клинических исследованиях
              другого препарата; кроме того, эти показатели могут не
              соответствовать частоте, наблюдаемой в клинической практике.
            </p>
            <p>
              Ниже изложены данные по безопасности, отражающие опыт применения
              препарата Балверса в исследовании 2 фазы BLC2001, где участвовали
              99 пациентов с местнораспространенным или метастатическим УР, в
              опухолях которых имели место определенные мутации и/или слияния
              генов FGFR2 и FGFR3, выявленные в центральной лаборатории с
              использованием аналитической методики, разработанной для
              клинического исследования, и у которых произошло прогрессирование
              заболевания во время или после по крайней мере одной линии
              предшествующей химиотерапии, в том числе в течение 12 месяцев
              после неоадъювантной или адъювантной химиотерапии. Пациенты
              получали препарат Балверса в дозе 8 мг внутрь 1 раз в сутки, с
              титрацией в виде повышения дозы, под фармакодинамическим
              контролем, до 9 мг при концентрациях фосфата в сыворотке крови
              &lt;5,5 мг/дл. Медиана длительности терапии составила 5,3 месяца
              (с разбросом от 0 до 17 месяцев).
            </p>
            <p>
              Наиболее распространенными нежелательными реакциями,
              встречавшимися с частотой ≥15%, были гиперфосфатемия (77%),
              стоматит (58%), сухость во рту (45%), снижение аппетита (38%),
              сухость кожи (32%), алопеция (29%), синдром ладонно- подошвенной
              эритродизестезии (23%), синдром сухого глаза (19%), онихолизис
              (18%), паронихии (17%) и дистрофия ногтей (16%). Наиболее
              распространенными нежелательными реакциями степени 3,
              встречавшимися с частотой &gt;1%, были стоматит (10%), дистрофия
              ногтей (6%), синдром ладонно-подошвенной эритродизестезии (5%),
              паронихии (3%), нарушения со стороны ногтей (3%), кератит (3%),
              онихолизис (2%) и гиперфосфатемия (2%). Нежелательные реакции, по
              поводу которых потребовалось снизить дозу препарата, имели место у
              52% пациентов, включая нарушения со стороны органа зрения у
              двадцати пациентов (20%). Нежелательные реакции, приводившие к
              отмене терапии, имели место лишь у девяти пациентов (9%), включая
              нарушения со стороны органа зрения у трех пациентов (3%).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Табличное резюме нежелательных реакций
            </p>
            <p>
              В таблице 7 представлены нежелательные реакции, встречавшиеся у
              ≥1% пациентов, получавших препарат Балверса ежедневно в дозе 8 мг
              в исследовании BLC2001. По частоте реакции классифицируются
              следующим образом: очень часто (≥1/10), часто (≥1/100, но
              &lt;1/10).
            </p>
            <h2>
              Таблица 7. Нежелательные реакции, отмечавшиеся у получавших
              препарат Балверса
            </h2>
            <img src={table71} style={{ width: '100%' }} />
            <img src={table72} style={{ width: '100%' }} />
            <p>
              Нежелательные явления кодировали при помощи MedDRA, версия 20.0. В
              MedDRA, версия 20.0, предпочтительные термины «паронихии» и
              «конъюнктивит» относятся к системно-органному классу «инфекции и
              инвазии». В этом анализе они были отнесены к системно-органным
              классам «Нарушения со стороны кожи и подкожных тканей» и
              «Нарушения со стороны органа зрения», соответственно.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Описание отдельных нежелательных реакций
            </p>
            <p>
              Ниже описаны нежелательные реакции, отмечавшиеся при приеме
              препарата Балверса в исследовании BLC2001 и в других
              исследованиях.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Центральная серозная ретинопатия (ЦСР)
            </p>
            <p>
              При применении препарата Балверса, как их других ингибиторов FGFR,
              описано развитие ЦСР . Нежелательные реакции, представляющие ЦСР ,
              отмечались у 23% пациентов; ЦСР включает хориоретинопатию,
              отслойку сетчатки, отслойку пигментного эпителия в области макулы,
              отслойку пигментного эпителия сетчатки, отек сетчатки, ретинопатию
              и отслойку стекловидного тела (см. раздел 4.4.).
            </p>
            <p style={{ fontStyle: 'italic' }}>Нарушения со стороны ногтей</p>
            <p>
              Нарушения со стороны ногтей отмечались у 51% пациентов и включали
              онихолизис, паронихии, дистрофию ногтей, изменения цвета ногтей,
              онихалгию, бугристость ногтей, онихоклазию, онихомадезис,
              кровоизлияния в ногтевом ложе и дискомфорт в области ногтей.
              Распространенность нарушений со стороны ногтей повышалась по мере
              увеличения длительности терапии. Медиана времени до развития
              нарушений со стороны ногтей (любой степени) составила 57 дней.
            </p>
            <p style={{ fontStyle: 'italic' }}>Нарушения со стороны кожи</p>
            <p>
              Нарушения со стороны кожи отмечались у 51% пациентов и включали
              сухость кожи, синдром ладонно-подошвенной эритродизестезии, зуд,
              кожные трещины, экзему, гиперкератоз, шелушение кожи, кожные
              очаги, ксеродермию, кожную атрофию, нуммулярную экзему и кожную
              токсичность. Медиана времени до развития нарушений со стороны кожи
              (любой степени) составила 40 дней.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Гиперфосфатемия и минерализация мягких тканей
            </p>
            <p>
              Препарат Балверса может вызывать гиперфосфатемию, приводящую к
              минерализации мягких тканей, кальцинозу кожи, неуремической
              кальцифилаксии и кальцификации сосудов. Повышение концентраций
              фосфата является ожидаемым и транзиторным лабораторным отклонением
              (см. раздел 5.1., подраздел «Фармакодинамические эффекты»).
              Гиперфосфатемия отмечалась в качестве нежелательного явления у 72%
              пациентов, получавших препарат Балверса. Эпизодов гиперфосфатемии,
              которые были бы расценены как серьезные, не было. Медиана времени
              до развития эпизодов гиперфосфатемии (любой степени) составила 15
              дней. Средний интервал до достижения максимальных концентраций
              фосфата составлял 6 недель после начала терапии препаратом
              Балверса, с последующим снижением до концентрации ниже 4,5 мг/дл
              примерно к месяцу 5. Кальциноз кожи, неуремическая кальцифилаксия
              и кальцификация сосудов наблюдались у 0,3% пациентов, получавших
              препарат Балверса.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Сообщение о подозреваемых нежелательных реакциях
            </p>
            <p>
              Важно сообщать о подозреваемых нежелательных реакциях после
              регистрации лекарственного препарата с целью обеспечения
              непрерывного мониторинга соотношения «польза – риск»
              лекарственного препарата. Медицинским работникам рекомендуется
              сообщать о любых подозреваемых нежелательных реакциях
              лекарственного препарата через национальные системы сообщения о
              нежелательных реакциях государств – членов Евразийского
              экономического союза.
            </p>

            <p style={{ textDecoration: 'underline' }}>Российская Федерация</p>
            <p>
              Федеральная служба по надзору в сфере здравоохранения
              (Росздравнадзор)
            </p>
            <p>Адрес: 109012, г. Москва, Славянская площадь, д. 4, стр. 1</p>
            <p> Тел.: +7 (495) 698-45-38, +7 (499) 578-02-30 </p>
            <p>Эл. почта: info@roszdravnadzor.gov.ru </p>
            <p>Сайт: https://roszdravnadzor.gov.ru/</p>
            <p style={{ textDecoration: 'underline' }}>Республика Казахстан</p>
            <p>
              РГП на ПХВ «Национальный центр экспертизы лекарственных средств и
              медицинских изделий» Комитета медицинского и фармацевтического
              контроля Министерства здравоохранения Республики Казахстан
            </p>
            <p>Адрес: 010000, г. Астана, ул. А.Иманова, д. 13</p>
            <p>Тел.: + 7 (7172) 78-98-28</p>
            <p>Эл.почта: pdlc@dari.kz</p>
            <p>Сайт: http://www.ndda.kz</p>

            <h3 className="mt-2">4.9. Передозировка</h3>
            <p>
              В случае передозировки необходимо прекратить прием препарата
              Балверса и провести симптоматическую терапию до уменьшения
              выраженности или разрешения клинической токсичности.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="5. ФАРМАКОЛОГИЧЕСКИЕ СВОЙСТВА">
            <h3>5.1. Фармакодинамические свойства</h3>
            <p>
              Фармакотерапевтическая группа: противоопухолевые средства;
              ингибиторы протеинкиназ; ингибиторы тирозинкиназы рецепторов
              фактора роста фибробластов.
            </p>
            <p>Код ATХ: L01EN01</p>
            <p style={{ textDecoration: 'underline' }}>Механизм действия</p>
            <p>
              Эрдафитиниб – ингибитор киназ, связывающийся с FGFR1, FGFR2, FGFR3
              и FGFR4 и ингибирующий их ферментную активность на основе данных
              in vitro. Эрдафитиниб также связывается с RET, CSF1R, PDGFRA,
              PDGFRB, FLT4, KIT и VEGFR2. Эрдафитиниб ингибировал
              фосфорилирование и сигнальную активность FGFR и снижал
              жизнеспособность клеток в клеточных линиях с экспрессией
              генетически измененного FGFR, включая точечные мутации,
              амплификации и слияния. Для эрдафитиниба показана
              противоопухолевая активность на клеточных линиях и
              моделях-ксенотрансплантатах с FGFR-зависимым онкогенезом,
              полученных из различных типов опухолей, включая рак мочевого
              пузыря.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Фармакодинамические эффекты
            </p>
            <p style={{ fontStyle: 'italic' }}>Электрофизиология сердца</p>
            <p>
              На основе оценки интервала QTc в открытом исследовании с
              повышением дозы и применением в достигнутой дозе у 187 пациентов
              со злокачественными новообразованиями эрдафитиниб не показал
              выраженного эффекта в отношении интервала QTc (т.е. не вызывал
              удлинения &gt;20 мс).
            </p>
            <p style={{ fontStyle: 'italic' }}>Фосфат в сыворотке крови</p>
            <p>
              Эрдафитиниб повышал концентрацию фосфатов в сыворотке крови в
              результате ингибирования FGFR. Доза препарата Балверса должна быть
              увеличена до максимально рекомендуемой для достижения таргетной
              концентрации фосфатов в сыворотке, составляющей 5,5-7,0 мг/дл, в
              ранних циклах с продолжительным ежедневным применением (см. раздел
              4.2.).
            </p>
            <p>
              В клинических исследованиях эрдафитиниба было запрещено
              использование препаратов, которые могут повышать концентрацию
              фосфатов в сыворотке, например, добавок, содержащих фосфат калия,
              добавок, содержащих витамин D, антацидов, использования клизм или
              слабительных средств, содержащих фосфаты, а также препаратов, в
              состав которых в качестве вспомогательных веществ входят фосфаты,
              за исключением ситуаций, когда отсутствуют альтернативы данным
              препаратам. При повышении концентрации фосфатов разрешено
              использование фосфат-связывающих средств. Следует избегать
              совместного применения с препаратами, способными изменить
              концентрацию фосфатов в сыворотке крови, до стартового периода
              повышения дозы на основе концентрации фосфатов в сыворотке крови
              (см. раздел 4.5.).
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Клиническая эффективность и безопасность
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Уротелиальный рак с отдельными мутациями и/или слияниями генов
              FGFR
            </p>
            <p>
              Исследование BLC2001 (NCT02365597) – многоцентровое, открытое,
              несравнительное исследование с целью оценки эффективности и
              безопасности препарата Балверса у пациентов с
              местнораспространенным или метастатическим УР. Мутационный статус
              FGFR для скрининга и включения пациентов в исследование
              определялся посредством лабораторной методики, валидированной для
              клинических исследований. Популяция по оценке эффективности
              включает 87 пациентов, включенных в исследование, поскольку у них
              имела место опухоль, которая прогрессировала на фоне применения по
              меньшей мере одной предшествующей схемы химиотерапии или после ее
              завершения при наличии по меньшей мере одного из следующих
              изменений генов: точечных мутаций гена FGFR3 (R248C, S249C, G370C,
              Y373C) или слияний генов FGFR (FGFR3-TACC3, FGFR3 BAIAP2L1,
              FGFR2-BICC1, FGFR2-CASP7), которые выявлялись посредством
              лабораторной методики, валидированной для клинических исследований
              и выполнявшейся в центральной лаборатории.
            </p>
            <p>
              Пациенты получали стартовую дозу препарата Балверса 8 мг 1 раз в
              сутки с повышением дозы до 9 мг 1 раз в сутки у пациентов, у
              которых концентрация фосфатов в сыворотке крови была ниже целевого
              значения 5,5 мг/дл в период между днями 14 и 17; такое повышение
              дозы имело место у 41% пациентов. Препарат Балверса применялся до
              прогрессирования болезни или наступления неприемлемой токсичности.
              Основные показатели исходов по эффективности включали частоту
              объективных ответов (ЧОО) и длительность ответа, которые
              определялись Комитетом по независимой оценке в слепом режиме
              (BIRC) в соответствии с критериями RECIST версии 1.1.
            </p>
            <p>
              Медиана возраста составила 67 лет (диапазон: 36-87 лет), 79%
              пациентов составили мужчины и 74% - пациенты европеоидной расы. У
              большинства пациентов (92%) исходный функциональный статус по
              шкале Восточной объединенной группы онкологов (ECOG) составил 0
              или 1. У 66% пациентов отмечались метастазы во внутренние органы.
              84 (97%) пациента ранее получили по меньшей мере одну дозу
              цисплатина или карбоплатина. 56% пациентов получали предшествующие
              схемы только на основе цисплатина, 29% - только на основе
              карбоплатина и 10% - на основе цисплатина и карбоплатина. У 3 (3%)
              пациентов отмечено прогрессирование заболевания после
              предшествующей неоадъювантной или адъювантной терапии только на
              основе препаратов платины. 24% пациентов получали предшествующую
              терапию антагонистами PD-L1/PD-1.
            </p>
            <p>
              Результаты оценки эффективности представлены в Таблицах 8 и 9.
              Частота объективных ответов составила 32,2%. К числу ответивших
              относились пациенты, ранее не отвечавшие на терапию антагонистами
              PD-L1/PD-1.
            </p>
            <h3 className="mt-2">Таблица 8. Результаты оценки эффективности</h3>
            <img src={img} style={{ width: '100%' }} />
            <p>ЧОО = ПО + ЧО</p>
            <p>ДИ = доверительный интервал</p>
            <h3 className="mt-2">
              Таблица 9. Результаты оценки эффективности в зависимости от
              изменений генов FGFR
            </h3>
            <img src={table9} style={{ width: '100%' }} />
            <p>
              * - У обоих ответивших пациентов отмечалось слияние FGFR3-TACC3_V1
            </p>
            <p>
              ** - Один пациент со слиянием FGFR2-CASP7/FGFR3-TACC3_V3 был
              указан и в группе слияния FGFR2, и в группе слияния FGFR3.
            </p>
            <p>ЧОО = ПО + ЧО</p>
            <p>ДИ = доверительный интервал</p>
            <h3 className="mt-2">5.2. Фармакокинетические свойства</h3>
            <p>
              После приема препарата в дозе 8 мг 1 раз в сутки средняя
              (коэффициент вариации [CV%]) максимальная наблюдаемая концентрация
              эрдафитиниба в плазме крови в равновесном состоянии (Cmax),
              площадь под кривой (AUCtau) и минимальная наблюдаемая концентрация
              в плазме крови (Cmin) составили 1399 нг/мл (51%), 29268 нг•ч/мл
              (60%) и 936 нг/мл (65%), соответственно.
            </p>
            <p>
              После однократного и многократного приема 1 раз в сутки экспозиция
              эрдафитиниба (максимальная наблюдаемая концентрация в плазме
              [Cmax] и площадь под кривой концентрация-время [AUC]) повышалась
              дозопропорционально в диапазоне доз от 0,5 до 12 мг (составляющих
              0,06-1,3 от максимальной рекомендуемой дозы). Равновесное
              состояние достигалось после 2 недель приема препарата 1 раз в
              сутки, а средний коэффициент кумуляции составлял 4.
            </p>
            <p style={{ textDecoration: 'underline' }}>Абсорбция</p>
            <p>
              Медиана времени достижения максимальных концентраций в плазме
              (tmax) составила 2,5 ч (с разбросом от 2 до 6 ч).
            </p>
            <p style={{ fontStyle: 'italic' }}>Эффект приема пищи</p>{' '}
            <p>
              Прием эрдафитиниба здоровыми лицами натощак и вместе с
              высококалорийной пищей, содержащей большое количество жира
              (800-1000 калорий, приблизительно на 50% определяемых содержанием
              жира), не приводил к клинически значимым изменениям
              фармакокинетики эрдафитиниба.
            </p>
            <p style={{ textDecoration: 'underline' }}>Распределение</p>{' '}
            <p>
              Средний наблюдаемый объем распределения эрдафитиниба у пациентов
              составлял 29 л. У пациентов связывание эрдафитиниба с белками
              плазмы человека составляло 99,8%, преимущественно – с кислым
              α1-гликопротеином.
            </p>
            <p style={{ textDecoration: 'underline' }}>Биотрансформация</p>
            <p>
              Эрдафитиниб, главным образом, метаболизируется изоферментами
              CYP2C9 и CYP3A4. Вклад изоферментов CYP2C9 и CYP3A4 в общий
              клиренс эрдафитиниба по оценке составляет 39% и 20%,
              соответственно. В плазме крови, главным образом, эрдафитиниб
              содержится в неизмененном виде, циркулирующих метаболитов выявлено
              не было.
            </p>
            <p style={{ textDecoration: 'underline' }}>Элиминация</p>
            <p>
              Средний общий наблюдаемый клиренс (CL/F) эрдафитиниба у пациентов
              составил 0,362 л/ч.
            </p>
            <p>
              Средний эффективный период полувыведения эрдафитиниба составил 59
              ч.
            </p>
            <p style={{ textDecoration: 'underline' }}>Экскреция</p>
            <p>
              После однократного приема внутрь эрдафитиниба, меченного
              радиоизотопом, 69% дозы обнаруживалось в кале (19% в виде
              неизмененного эрдафитиниба), а 19% - в моче (13% в виде
              неизмененного эрдафитиниба).
            </p>
            <p style={{ textDecoration: 'underline' }}>Особые популяции</p>
            <p>
              Не наблюдалось клинически значимых различий фармакокинетики
              эрдафитиниба в зависимости от возраста (в диапазоне от 21 года до
              88 лет), пола, расовой принадлежности, массы тела (в диапазоне от
              36 до 132 кг), нарушения функции печени легкой (класс А по
              классификации Чайлда – Пью) или средней степени (класс В по
              классификации Чайлда – Пью), нарушения функции почек легкой или
              средней степени (рСКФ 30-89 мл/мин/1,73 м²). Имеются ограниченные
              данные у пациентов с нарушением функции печени тяжелой степени
              (класс С по классификации Чайлда – Пью). Для пациентов с
              нарушением функции почек тяжелой степени и нарушением функции
              почек, требующим диализа, фармакокинетические данные недоступны.
            </p>
            <p style={{ textDecoration: 'underline' }}>Фармакогеномика</p>
            <p>
              Активность изофермента CYP2C9 снижается у индивидуумов с
              генетическими вариантами, например, с полиморфизмами CYP2C9*2 и
              CYP2C9*3. Экспозиция эрдафитиниба была сходной у участников с
              генотипами CYP2C9*1/*2 и *1/*3 по сравнению с участниками с
              генотипом CYP2C9*1/*1 (дикого типа). Данных по участникам,
              характеризующимся другими генотипами (например, *2/*2, *2/*3,
              *3/*3), не представлено. Моделирование позволило предположить
              отсутствие клинически значимых отличий экспозиции эрдафитиниба у
              участников с генотипами CYP2C9*2/*2 и *2/*3. Предполагается, что
              экспозиция эрдафитиниба будет на 50% выше у участников с генотипом
              CYP2C9*3/*3, частота которого в популяции в различных этнических
              группах по оценке составляет от 0,4% до 3%.
            </p>
            <p style={{ textDecoration: 'underline' }}>Дети</p>
            <p>
              Фармакокинетика эрдафитиниба у педиатрических пациентов не
              изучена.
            </p>
            <h3 className="mt-2">5.3. Данные доклинической безопасности</h3>
            <p>
              В токсикологических исследованиях при многократном применении у
              крыс и собак наблюдались нарушения гомеостаза фосфатов (что
              характеризовалось повышением концентраций в сыворотке в первую
              очередь фосфата, FGF-23 и 1,25-дигидроксивитамина D3) при
              показателях экспозиции ниже экспозиции у человека на всех
              изучаемых дозах (см. раздел 4.2.). Основными проявлениями
              лекарственной токсичности у животных являлась дисплазия хрящевой
              ткани и минерализация мягких тканей на фоне гиперфосфатемии. При
              добавлении в пищу крыс севеламера – вещества, связывающего фосфат,
              проявления минерализации мягких тканей уменьшались. Отмечалась
              атрофия железистых и эпителиальных структур (изменения зубов,
              истончение эпителия роговицы, атрофические изменения слезных
              желез, изменения волосяного покрова и ногтей).
            </p>
            <p>
              Минерализация мягких тканей (кроме минерализации аорты у собак), а
              также хондроидная дисплазия у крыс и собак и атрофия молочных
              желез у крыс была частично или полностью обратима к концу
              4-недельного периода восстановления без применения препарата.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Канцерогенность, мутагенность и влияние на фертильность
            </p>
            <p>
              Долгосрочных исследований, которые позволили бы оценить
              канцерогенный потенциал эрдафитиниба, у животных не проводилось.
              Эрдафитиниб не вызывал мутации в тесте обратных мутаций у бактерий
              (тесте Эймса); кроме того, он не обладал генотоксичностью ни в
              тесте с формированием микронуклеусов in vitro, ни в тесте с
              формированием микронуклеусов в костном мозге крыс in vivo.
              Отдельных исследований влияния эрдафитиниба на фертильность у
              животных не проводилось. Тем не менее, в 3- месячном исследовании
              общей токсичности эрдафитиниб продемонстрировал эффекты в
              отношении женских половых органов (некроз желтых тел) у крыс при
              значениях площади под кривой концентрация-время, приближающихся к
              таковым у пациентов на максимальной рекомендованной дозе в 9 мг 1
              раз в сутки.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Репродуктивная токсикология
            </p>
            <p>
              Эрдафитиниб при применении у крыс в дозах ≥4 мг/кг/сут обладал
              тератогенностью и эмбриотоксичностью, при этом показатели
              экспозиции были ниже достигаемых у человека на всех изученных
              дозах (см. раздел 4.2.). Токсичность в отношении плода
              характеризовалась дефектами кистей и стоп, а также мальформацией
              некоторых крупных кровеносных сосудов, таких как аорта.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="6. ФАРМАЦЕВТИЧЕСКИЕ СВОЙСТВА">
            <h3>6.1. Перечень вспомогательных веществ</h3>
            <p style={{ textDecoration: 'underline' }}>Ядро таблетки:</p>
            <p>Маннитол</p>
            <p>Целлюлоза микрокристаллическая</p>
            <p>Меглюмин</p>
            <p>Кроскармеллоза натрия</p>
            <p>Магния стеарат</p>
            <p style={{ textDecoration: 'underline' }}>Пленочная оболочка</p>
            <p style={{ fontStyle: 'italic' }}>
              Балверса, 3 мг, таблетки, покрытые пленочной оболочкой
            </p>
            <p>
              Opadry® amb II 88A120003 Желтый (поливиниловый спирт частично
              гидролизованный, титана диоксид, тальк, краситель железа оксид
              желтый, глицерол монокаприлокапрат Тип 1, натрия лаурилсульфат).
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Балверса, 4 мг, таблетки, покрытые пленочной оболочкой
            </p>
            <p>
              Opadry® amb II 88A130001 Оранжевый (поливиниловый спирт частично
              гидролизованный, титана диоксид, тальк, краситель железа оксид
              желтый, краситель железа оксид красный, глицерол монокаприлокапрат
              Тип 1, натрия лаурилсульфат).
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Балверса, 5 мг, таблетки, покрытые пленочной оболочкой
            </p>
            <p>
              Opadry® amb II 88A165000 Коричневый (поливиниловый спирт частично
              гидролизованный, титана диоксид, тальк, краситель железа оксид
              красный, краситель железа оксид желтый, краситель железа оксид
              черный / закись-окись железа, глицерол монокаприлокапрат Тип 1,
              натрия лаурилсульфат).
            </p>
            <h3 className="mt-2">6.2. Несовместимость</h3>
            <p>Не применимо</p>
            <h3 className="mt-2">6.3. Срок годности (срок хранения)</h3>
            <p>3 года.</p>
            <h3 className="mt-2">
              6.4. Особые меры предосторожности при хранении
            </h3>
            <p>
              Лекарственный препарат не требует специальных условий хранения.
            </p>
            <h3 className="mt-2">
              6.5. Характер и содержание первичной упаковки
            </h3>
            <p>
              По 14, 28 или 42 таблетки, покрытые пленочной оболочкой, в блистер
              с внешним неотделимым картонным слоем и внутренним слоем из
              поливинилхлоридполихлортрифторэтилена (ПВХ-ПХТФЭ) с продавливаемой
              алюминиевой фольгой.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Балверса, 3 мг, таблетки, покрытые пленочной оболочкой
            </p>
            <p>
              По 1 блистеру с 28 или 42 таблетками в картонном футляре. По 2
              картонных футляра с листком-вкладышем в пачке картонной. Возможно
              наличие контроля первого вскрытия на пачке картонной.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Балверса, 4 мг, таблетки, покрытые пленочной оболочкой
            </p>
            <p>
              По 1 блистеру с 14 или 28 таблетками в картонном футляре. По 1
              картонному футляру с 14 таблетками с листком-вкладышем в пачке
              картонной. По 1 или 2 картонных футляра с 28 таблетками с
              листком-вкладышем в пачке картонной. Возможно наличие контроля
              первого вскрытия на пачке картонной.
            </p>
            <p style={{ textDecoration: 'underline' }}>
              Балверса, 5 мг, таблетки, покрытые пленочной оболочкой
            </p>
            <p>
              По 1 блистеру с 28 таблетками в картонном футляре. По 1 картонному
              футляру с листком-вкладышем в пачке картонной. Возможно наличие
              контроля первого вскрытия на пачке картонной.
            </p>
            <p>Не все размеры упаковок могут быть доступны для реализации</p>
            <h3 className="mt-2">
              6.6. Особые меры предосторожности при уничтожении использованного
              лекарственного препарата или отходов, полученных после применения
              препарата и другие манипуляции с препаратом
            </h3>
            <p>
              Весь оставшийся лекарственный препарат и отходы следует уничтожить
              в установленном порядке.
            </p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="7. ДЕРЖАТЕЛЬ РЕГИСТРАЦИОННОГО УДОСТОВЕРЕНИЯ">
            <p>Российская Федерация</p>
            <p>ООО «Джонсон & Джонсон»</p>
            <p>121614, г. Москва, ул. Крылатская, д. 17, корп. 2</p>
            <p>Контактные телефоны:</p>
            <p>Тел.: (495) 755-83-57</p>
            <p>Факс: (495) 755-83-58</p>
            <p>Эл. почта: DrugSafetyRU@its.jnj.com</p>
            <h3 className="mt-2">
              7.1. Представитель держателя регистрационного удостоверения
            </h3>
            <p style={{ textDecoration: 'underline' }}>
              Претензии потребителей направлять по адресу:
            </p>
            <p style={{ fontStyle: 'italic' }}>Российская Федерация</p>
            <p>ООО «Джонсон & Джонсон»</p>
            <p>121614, г. Москва, ул. Крылатская, д. 17, корп. 2</p>
            <p>Контактные телефоны:</p>
            <p>Тел.: +7 (495) 755 83 57</p>
            <p>Факс: +7 (495) 755 83 58</p>
            <p>Эл. почта: DrugSafetyRU@its.jnj.com</p>
            <p style={{ fontStyle: 'italic' }}>Республика Казахстан</p>
            <p>Филиал ООО «Джонсон & Джонсон» в Республике Казахстан</p>
            <p>050040, г. Алматы, ул. Тимирязева, 42, павильон 23 «А»</p>
            <p>Контактные телефоны:</p>
            <p>Тел.: +7 (727) 356 88 11</p>
            <p>Факс: +7 (727) 356 88 13</p>
            <p>Эл. почта: DrugSafetyKZ@its.jnj.com</p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="8. НОМЕР РЕГИСТРАЦИОННОГО УДОСТОВЕРЕНИЯ">
            <p>ЛП-№(003143)-(РГ-RU)</p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion
            title="9. ДАТА ПЕРВИЧНОЙ РЕГИСТРАЦИИ (ПОДТВЕРЖДЕНИЯ РЕГИСТРАЦИИ,
          ПЕРЕРЕГИСТРАЦИИ)"
          >
            <p>Дата первичной регистрации: 07.09.2023</p>
          </Accordion>
        </div>
        <div className="mt-2">
          <Accordion title="10. ДАТА ПЕРЕСМОТРА ТЕКСТА">
            <p>
              Общая характеристика лекарственного препарата Балверса доступна на
              информационном портале Евразийского экономического союза в
              информационно-коммуникационной сети «Интернет»
              http://eec.eaeunion.org/.
            </p>
            <p>
              Данный лекарственный препарат зарегистрирован по процедуре
              «условной регистрации», и по этому лекарственному препарату
              ожидается представление дополнительных данных. Министерство
              здравоохранения Российской Федерации будет проводить ежегодно
              экспертизу новых сведений о препарате, а данная общая
              характеристика лекарственного препарата будет обновляться по мере
              необходимости
            </p>
          </Accordion>
        </div>
      </div>

      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </>
  );
};

export default BalversaInstruction;
