<template>
  <div class="question">
    <div class="title">{{ content.title }}</div>
    <!-- eslint-disable -->
    <ul class="wrapper question__list" @change="handleRadioChange">
      <li
        v-for="item in content.questions"
        :key="item.key"
        :ref="item.key"
        :label="item.key"
        class="ratio"
        @click="handleRadioChange(item.key)"
      >
        {{ item.value }}
      </li>
    </ul>
    <div
      v-if="filteredPatientData && filteredPatientData.length > 0"
      class="patient-data"
    >
      <h3 class="title">Выберите подгруппу пациентов</h3>
      <div
        class="patient-data_cards"
        :style="{ marginBottom: patientDataDescMargin }"
        @scroll="handleScroll"
      >
        <div
          v-for="(item, index) in filteredPatientData"
          :key="'patientData_' + index"
          class="patient-data_card"
          :class="{ active: activeIndex === index }"
          @click="handleCardClicked(item, index)"
        >
          <!-- Отображаем комментарий для nmCRPC -->
          <div v-if="value === 'nmCRPC'">
            <h5>Уровень ПСА на момент установки диагноза:</h5>
            <p>{{ item.description }}</p>
            <h5>Возраст:</h5>
            <p>{{ item.age }}</p>
            <h5>Время удвоения ПСА:</h5>
            <p>{{ item.doublingTime }}</p>
          </div>
          <!-- Отображаем комментарий для mHSPC -->
          <div v-else-if="value === 'mHSPC'">
            <h5>Локализация метастазов:</h5>
            <p>{{ item.description }}</p>
            <h5>Индекс Глисона:</h5>
            <p>{{ item.gleasonIndex }}</p>
            <h5>Возраст:</h5>
            <p>{{ item.age }}</p>
          </div>
        </div>
      </div>
      <div v-show="!isScrolledToEnd" class="patient-data__desc">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="#317CD2" />
          <path
            d="M8.944 6.71652L7.11196 6.96899L7.04636 7.30328L7.40637
            7.37629C7.64157 7.43787 7.68797 7.53112 7.63677 7.78887L7.04636
            10.8397C6.89116 11.6288 7.13036 12 7.69278 12C8.12878 12 8.63519
            11.7783 8.8648 11.4739L8.9352 11.108C8.7752 11.2628 8.54159 11.3244
            8.38639 11.3244C8.16638 11.3244 8.08638 11.1546 8.14318 10.8555L8.944
            6.71652ZM9 4.8797C9 5.11301 8.91571 5.33677 8.76568 5.50174C8.61565
            5.66672 8.41216 5.7594 8.19998 5.7594C7.98781 5.7594 7.78432 5.66672
            7.63429 5.50174C7.48426 5.33677 7.39997 5.11301 7.39997 4.8797C7.39997
            4.64639 7.48426 4.42263 7.63429 4.25766C7.78432 4.09268 7.98781 4 8.19998
            4C8.41216 4 8.61565 4.09268 8.76568 4.25766C8.91571 4.42263 9 4.64639 9 4.8797Z"
            fill="white"
          />
        </svg>
        <span>Пролистайте, чтобы увидеть больше</span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @desc Сущность для отображения конкретного запроса
 *  @vue-computed {String} value - значение выбранного вопроса
 *  @vue-computed {Object} therapy - мок данные дополнительного фактора
 *  @vue-computed {Object} currentList - данные выбранного доп фактора
 */

import patientData from '@/mock/patient-data.json';

export default {
  name: 'QuestionView',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: null,
      patientData,
      activeIndex: -1,
      isScrolledToEnd: false,
    };
  },
  computed: {
    filteredPatientData() {
      if (this.value === 'nmCRPC') {
        return patientData.nmCRPC || [];
      }
      if (this.value === 'mHSPC') {
        return patientData.mHSPC || [];
      }
      return [];
    },
    patientDataDescMargin() {
      return this.isScrolledToEnd ? '33px' : '0';
    },
  },
  methods: {
    handleRadioChange(val) {
      if (val === 'nmCRPC') {
        this.$refs.nmCRPC[0].classList.add('_checked');
        if (this.$refs.mHSPC[0].classList.contains('_checked')) {
          this.$refs.mHSPC[0].classList.remove('_checked');
        }
      } else if (val === 'mHSPC') {
        this.$refs.mHSPC[0].classList.add('_checked');
        if (this.$refs.nmCRPC[0].classList.contains('_checked')) {
          this.$refs.nmCRPC[0].classList.remove('_checked');
        }
      }
      this.value = val;
      this.activeIndex = null;
    },
    handleCardClicked(item, index) {
      this.activeIndex = index;
      this.$emit('cardSelected', item);
    },
    handleScroll(event) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.isScrolledToEnd = true;
      } else {
        this.isScrolledToEnd = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-data {
  background-color: #f8fbff;
  padding: 24px;
  border-radius: 16px;

  &_cards {
    max-height: 398px;
    overflow-y: auto;
    display: grid;
    padding: 2px;
    margin-bottom: 33px;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(33.333% - 16px), 1fr)
    );
    grid-gap: 16px;
    justify-content: space-between;
    @media (max-width: 960px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &_card {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #ebebeb;

    &:hover {
      box-shadow: 0 0 0 2px #317dd2;
    }

    &.active {
      background-color: #317dd2;
      border-color: #317dd2;
      p {
        color: #fff;
        border-color: #458ad6;
      }
      h5 {
        color: #adcbed;
      }
    }

    h5 {
      padding: 0;
      margin: 0;
      margin-top: 8px;
      color: rgba(51, 51, 51, 0.6);
      font-family: "Verdana Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;

      &:first-child {
        margin-top: 0;
      }
    }
    p {
      color: #333;
      margin-top: 4px;
      padding-bottom: 6px;
      font-family: "Verdana Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      border-bottom: 1px solid #ebebeb;

      &:last-child {
        border-bottom: none;
      }
    }
  }
  &__desc {
    text-align: center;
    margin-top: 16px;
    color: rgba(51, 51, 51, 0.6);
    font-family: "Verdana Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    display: flex;
    justify-content: center;

    svg {
      margin-right: 7px;
    }
  }
}
.el-radio-group {
  margin-bottom: 24px;
}
.question {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 19px;
    color: $grey;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  &__list {
    margin-bottom: 24px;

    li {
      font-size: 14px;
      position: relative;
      cursor: pointer;
      padding-left: 23px;
      transition: all 0.2s ease-in;
      color: rgba(0, 0, 0, 0.9);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border: 1px solid #DCDFE6;
        border-radius: 50%;
        width: 14px;
        height: 14px;
      }
      &._checked {
        color: rgba(0, 0, 0, 1);

        &:before {
          border: 5px solid #3478CC;
        }
      }
      &:hover:before {
        border-color: #3478CC;
      }
    }
  }

  .ratio {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    ::v-deep {
      .el-radio__original {
        margin-right: 7px;
      }
    }
  }
}
</style>
