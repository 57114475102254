import React from "react";

import drHouse from "../../img/profile/achievements/dr-house.svg";
import expertRMP from "../../img/profile/achievements/expert-rmp.svg";
import expertRPJ from "../../img/profile/achievements/expert-rpj.svg";
import maraphonPodcast from "../../img/profile/achievements/marathon-podcast.svg";
import topLevelAnalyst from "../../img/profile/achievements/top-level-analyst.svg";
import videoTourman from "../../img/profile/achievements/video-tourman.svg";
import lostConnoisseur from "../../img/profile/achievements/lost-connoisseur.svg";
import regularViewer from "../../img/profile/achievements/regular-viewer.svg";
import firstStep from "../../img/profile/achievements/first-step.svg";
import communityMouthpiece from "../../img/profile/achievements/community-mouthpiece.svg";

import closedAchievement from "../../img/achievement-closed.svg";

const achievements = [
  {
    id: 1,
    icon: drHouse,
    unlock: true,
    header: "Доктор Хаус 2.0",
    description: "Вы успешно изучили все клинические кейсы",
  },
  {
    id: 2,
    icon: topLevelAnalyst,
    unlock: true,
    header: "Аналитик высшего уровня",
    description: "Вы активно использовали калькуляторы для точных расчетов",
  },
  {
    id: 3,
    icon: maraphonPodcast,
    unlock: false,
    header: "Подкаст-марафонец",
    description: "Вы активно смотрели видеоматериалы",
  },
  {
    id: 4,
    icon: videoTourman,
    unlock: false,
    header: "Видеогурман",
    description: "Вы прослушали все подкасты",
  },
  {
    id: 5,
    icon: expertRPJ,
    unlock: false,
    header: "Знаток РПЖ",
    description: "Вы ознакомились со всеми материалами по препарату Эрлеада",
  },
  {
    id: 6,
    icon: expertRMP,
    unlock: false,
    header: "Знаток РМП",
    description: "Вы ознакомились со всеми материалами по препарату Балверса",
  },
  {
    id: 7,
    type: "closed",
    icon: firstStep,
    unlock: false,
    header: "Первый шаг",
    description: "Добро пожаловать в веб-бот!",
  },
  {
    id: 8,
    type: "closed",
    icon: regularViewer,
    unlock: false,
    header: "Постоянный зритель",
    description: "Вы активно посещали веб-бот на этой неделе",
  },
  {
    id: 9,
    type: "closed",
    icon: lostConnoisseur,
    unlock: false,
    header: "Заблудший знаток",
    description: "С возвращением в веб-бот!",
  },
  {
    id: 10,
    type: "closed",
    icon: communityMouthpiece,
    unlock: false,
    header: "Рупор сообщества",
    description: "Вы впервые поучаствовали в опросе",
  },
];

const Achievements = () => {
  return (
    <section className="profile-progress">
      <h2>Достижения</h2>
      <div className="achievements">
        {achievements.filter((ach) => ach.type !== 'closed').map((ach) => (
          <div className="achievement-item" key={ach.id}>
            <img src={ach.unlock ? ach.icon : closedAchievement} alt="unlock" />
            <div className="achievement-text">
              <h3>{ach.header}</h3>
              <p>{ach.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Achievements;
