import React from 'react';
import saveIcon from '../img/save.svg';
import ym from 'react-yandex-metrika';

const SaveBtn = (props) => {
  return (
    <>
      {props.url ? (
        <a href={props.url} target="_blank" className="savebtn">
          <img src={saveIcon} alt="save-icon" />
          <p>Сохранить</p>
        </a>
      ) : (
        <button
          type="button"
          target="_blank"
          className="savebtn"
          onClick={() => {
            ym('reachGoal', 'save_result');
          }}
        >
          <img src={saveIcon} alt="save-icon" />
          <p>Сохранить</p>
        </button>
      )}
    </>
  );
};

export default SaveBtn;
