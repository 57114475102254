import React, { useEffect, useState } from "react";
import NavHead from "../components/navhead";
import GoBtn from "../components/gobtn";
import { Link } from "react-router-dom";
import axios from "axios";
import loader from "../../src/img/loader.gif";

const Topical = ({ navHead, refresh, url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/current_news`)
      .then((res) => {
        setIsLoading(false);
        setPosts(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <div className="content_x__block">
          {navHead ? <NavHead title="Актуальное" /> : null}
          <div className="content_x">
            <div className="posts">
              {posts.map((item, index) => (
                <Link
                  to={`/post/:${index}`}
                  className="posts-item gap-1 pt-1 pb-1"
                  key={item.header}
                >
                  <div className="content">
                    <div className="meta">
                      {item.created_date.slice(0, 10).split("-")[2] +
                        "." +
                        item.created_date.slice(0, 10).split("-")[1] +
                        "." +
                        item.created_date.slice(0, 10).split("-")[0]}
                    </div>
                    <div className="title">{item.header}</div>
                  </div>
                  <div className="img">
                    <img src={item.picture} alt="post" />
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="mt-2 pl-2 pr-2 back_btn_x">
            <GoBtn title="Вернуться на главную" refresh={refresh} />
          </div>
        </div>
      )}
    </>
  );
};

export default Topical;
