import React, { useState, useEffect } from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Home from './pages';
import Blogs from './pages/blogs';
import Erleada from './pages/erleada';
import ClinicList from './pages/clinicTest/clinicList';
import ClinicDifficulty from './pages/clinicTest/clinicDifficulty';
import ClinicTest from './pages/clinicTest/clinicTest';
import ClinicEnd from './pages/clinicTest/clinicEnd';
import Media from './pages/media';
import Topical from './pages/topical';
import Post from './pages/post';
import Blog from './pages/blogg';
import Medications from './pages/medications';
import ErleadaAbout from './pages/erleada/about';
import ErleadaInstruction from './pages/erleada/instruction';
import ErleadaApplication from './pages/erleada/application';
import ErleadaMgchrpj from './pages/erleada/mgchrpj';
import MgchrpjAbout from './pages/erleada/mgchrpj/about';
import MgchrpjSurvival from './pages/erleada/mgchrpj/survival';
import MgchrpjResult from './pages/erleada/mgchrpj/result';
import ErleadaNmkrrpj from './pages/erleada/nmkrrpj';
import NmkrrpjAbout from './pages/erleada/nmkrrpj/about';
import NmkrrpjSurvival from './pages/erleada/nmkrrpj/survival';
import NmkrrpjResult from './pages/erleada/nmkrrpj/result';
import VideoList from './pages/media/videos';
import Video from './pages/media/video';
// import Audio from "./pages/media/audio";
import PodcastList from './pages/media/audios';
import Podcast from './pages/media/audio';
import Board from './pages/board';
import MetaRpj from './pages/board/metarpj';
import LocalRpj from './pages/board/localrpj';
import ResultRpj from './pages/board/resultrpj';
import Calc from './pages/calc';
import axios from 'axios';
import NoAccess from './pages/noAccess';
import loader from './img/loader.gif';
import { YMInitializer } from 'react-yandex-metrika';
import Balversa from './pages/balversa';
import BalversaDiagnostics from './pages/balversa/diagnostics';
import BalversaMechanism from './pages/balversa/mechanism';
import BalversaInstruction from './pages/balversa/instruction';
import BalversaSafety from './pages/balversa/safety';
import BalversaEfficiency from './pages/balversa/efficiency';
import ProstateAnalysis from './pages/prostate-analysis';
import ForDoctor from './pages/balversa/for-doctor';
import Profile from './pages/profile';
import ProfileEdit from './pages/profile-edit';

const tg = window.Telegram.WebApp;

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const url = window.location.protocol + '//' + window.location.hostname;
  const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
  //const url = `http://localhost:8000`;
  //const userId = 801384711;

  useEffect(() => {
    tg.expand();
    axios
      .get(`${url}/api/webapp/check_user_id?user_id=${userId}`)
      .then((res) => {
        setIsLoggedIn(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  });

  useEffect(() => {
    setIsLoading(true);
    tg.expand();
    axios
      .get(
        `https://jnj-bot.test.cleverbots.ru/api/webapp/check_user_id?user_id=${userId}`
      )
      .then((res) => {
        setIsLoggedIn(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  }, []);

  return (
    <div className="App">
      <YMInitializer accounts={[96110260]} />
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <Routes>
          <Route
            path="/"
            element={isLoggedIn ? <Home url={url} /> : <NoAccess />}
            exact
          />
          <Route path="/profile" element={<Profile url={url} userId={userId} />} />
          <Route path="/profile/edit" element={<ProfileEdit url={url} userId={userId} />} />
          <Route path="/board" element={<Board />} />
          <Route path="/board/metarpj" element={<MetaRpj />} />
          <Route path="/board/localrpj" element={<LocalRpj />} />
          {['/board/metarpj/:id', '/board/localrpj/:id'].map((path, index) => {
            return (
              <Route
                path={path}
                element={<ResultRpj url={url} />}
                key={index}
              />
            );
          })}
          <Route path="/blogs" element={<Blogs navHead={true} url={url} />} />
          <Route path="/prostate-analysis" element={<ProstateAnalysis />} />
          <Route path="/blog/:blogId" element={<Blog url={url} />} />
          <Route path="/calc" element={<Calc url={url} />} />
          <Route path="/meds" element={<Medications url={url} />} />
          <Route path="/erleada">
            <Route index element={<Erleada />} />
            <Route path="about" element={<ErleadaAbout />} />
            <Route path="instruction" element={<ErleadaInstruction />} />
            <Route path="application" element={<ErleadaApplication />} />
            <Route path="mgchrpj">
              <Route index element={<ErleadaMgchrpj />} />
              <Route path="about" element={<MgchrpjAbout />} />
              <Route path="survival" element={<MgchrpjSurvival />} />
              <Route path="result" element={<MgchrpjResult />} />
            </Route>
            <Route path="nmkrrpj">
              <Route index element={<ErleadaNmkrrpj />} />
              <Route path="about" element={<NmkrrpjAbout />} />
              <Route path="survival" element={<NmkrrpjSurvival />} />
              <Route path="result" element={<NmkrrpjResult />} />
            </Route>
          </Route>
          <Route path="/balversa">
            <Route index element={<Balversa />} />
            <Route path="diagnostics" element={<BalversaDiagnostics />} />
            <Route path="instruction" element={<BalversaInstruction />} />
            <Route path="mechanism" element={<BalversaMechanism />} />
            <Route path="safety" element={<BalversaSafety />} />
            <Route path="efficiency" element={<BalversaEfficiency />} />
            <Route path="for-doctor" element={<ForDoctor />} />
          </Route>
          <Route path="/clinic" element={<ClinicList url={url} />} />
          <Route path="/clinic/:testId" element={<ClinicDifficulty />} />
          <Route
            path="/clinic/:testId/:difficulty"
            element={<ClinicTest url={url} />}
          />
          <Route
            path="/clinic/test-end/:testId"
            element={<ClinicEnd url={url} />}
          />
          <Route path="/media">
            <Route index element={<Media url={url} />} />
            <Route
              path="videoList"
              element={<VideoList navHead={true} url={url} />}
            />
            <Route path="videoList/:videoId" element={<Video url={url} />} />
            {/* <Route path="audio" element={<Audio />} /> */}
            <Route
              path="podcastList"
              element={<PodcastList navHead={true} url={url} />}
            />
            <Route
              path="podcastList/:podcastId"
              element={<Podcast url={url} />}
            />
          </Route>
          <Route
            path="/topical"
            element={<Topical navHead={true} url={url} />}
          />
          <Route path="/post/:postId" element={<Post url={url} />} />
          <Route path="*" element={<p>Path not resolved</p>} />
        </Routes>
      )}
    </div>
  );
}

export default App;
