<template>
  <div
    role="button"
    :class="['app-button-component', { _disabled: isDisabled }]"
    :tabIndex="0"
    v-on="$listeners"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    text: {
      type: String,
      default: 'Начать',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-button-component {
  @include _br4;
  background-color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: $white;
  text-align: center;
  cursor: pointer;
  padding: 8px;
  border: 2px solid $blue;

  &._disabled {
    opacity: .5;
    pointer-events: none;
  }
}
</style>
