<template>
  <div class="greeting-view-component">
    <h2 class="title">Уважаемый коллега!</h2>
    <p class="description">
      Предлагаем вам инструмент, который поможет оценить
      продолжительность жизни больных мГЧРПЖ и нмКРРПЖ в
      зависимости от эпидемиологических данных и характеристик опухоли.
    </p>
    <app-button class="button" @click="handleClick" />
    <p class="content">
      <span>
        <span class="abbreviated">мГЧРПЖ</span> — метастатический гормоночувствительный рак предстательной железы.
      </span>
      <span>
        <span class="abbreviated">нмКРРПЖ</span> — неметастатический кастрационно-резистентный рак предстательной
        железы.
      </span>
    </p>
  </div>
</template>

<script>
/**
 * @desc Экран приветствия
 */
import AppButton from '@/components/common/AppButton.vue';

export default {
  name: 'GreetingView',
  components: {
    AppButton,
  },
  methods: {
    /**
     * Обрабатывает событие отображения выбора данных
     */
    handleClick() {
      this.$store.commit('SET_SHOW_FORM', true, { root: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.greeting-view-component {
  @include _padding-around;
  @include _br4;
  background-color: $white;

  .title {
    @include _mb-32;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .description {
    @include _mb-32;
  }

  .button {
    @include _mb-32;
    max-width: 140px;
  }

  .content {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 22px;
    color: $black;
    opacity: 0.5;

    .abbreviated {
      font-weight: 700;
    }
  }
}
</style>
