import React from 'react';
import GoBtn from '../../components/gobtn';
import NavHead from '../../components/navhead';
import Accordion from '../../components/accordion';

const ErleadaAbout = () => {
  const content = (
    <p>
      Инструкция по медицинскому применению препарата Эрлеада, РУ ЛП-005797,
      посл. изм. от 23.10.2020
    </p>
  );
  return (
    <div className="content_x__block">
      <NavHead title="Об Эрлеаде" />
      <div className="content_x mt-2 pl-2 pr-2">
        <h2>
          Эрлеада — селективный ингибитор андрогеновых рецепторов (АР),
          специально разработанный для приема внутрь.
        </h2>
        <p>
          Эрлеада ингибирует сигнальные пути, опосредованные АР, за счет
          нескольких компонентов механизма действия:
        </p>
        <ul style={{ listStyle: 'inside' }}>
          <li>Прямое связывание с АР</li>
          <li>Ослабление ядерной транслокации АР</li>
          <li>Подавление процесса связывания с ДНК</li>
          <li>Подавление АР-опосредованной транскрипции</li>
        </ul>

        {/* <p>
          Рекомендуемая доза препарата Эрлеада составляет <strong>240 мг (4 таблетки по 60 мг),</strong> препарат принимают внутрь{" "}
          <strong>один раз в день, независимо от приема пищи.</strong>
        </p> */}
        {/* <h2>
          Рекомендуемая доза препарата ЭРЛЕАДА составляет 240 мг (4 таблетки по
          60 мг) один раз в сутки внутрь
          <Popover content={content}>
            <sup style={{ fontSize: 12, verticalAlign: "top" }}>[1]</sup>
          </Popover>
        </h2> */}
        <div className="content mt-2 pl-2 pr-2 mb-2">
          <div style={{ position: 'relative', display: 'block' }}>
            <div style={{ paddingTop: '56%' }}>
              <iframe
                src="https://players.brightcove.net/1475651728001/default_default/index.html?videoId=6345932830112"
                allowfullscreen=""
                allow="encrypted-media"
                style={{
                  position: 'absolute',
                  top: '1px',
                  right: '1px',
                  bottom: '1px',
                  left: '1px',
                  width: '98%',
                  height: '98%',
                }}
              ></iframe>
            </div>
          </div>
        </div>
        <strong>Высокая активность:</strong>
        <p className="mb-1">
          Аффинность в 7–10 раз выше, чем у антиандрогенов 1-го поколения,
          таких, как бикалутамид (по данным анализа на цельных клетках у
          препарата Эрлеада IC50 = 16 нмоль/л, у бикалутамида IC50 = 160
          нмоль/л)
        </p>
        <strong>Селективное связывание:</strong>
        <p className="mb-1">
          Эрлеада селективно связывается с АР, в отличие от других ядерных
          гормональных рецепторов
        </p>
        <strong>Антагонизм:</strong>
        <p>Эрлеада не является агонистом АР</p>
        <div className="mt-2">
          <Accordion title="Источники">
            <ul>
              <li>
                Clegg NJ, Wongvipat J, Joseph JD, et al. ARN-509: a novel
                antiandrogen for prostate cancer treatment. Cancer Res.
                2012;72(6):1494–1503
              </li>
              <li>
                Общая характеристика лекарственного препарата Эрлеада,
                ЛП-№(003123)-(РГ-RU) от 01.09.2023
              </li>
            </ul>
          </Accordion>
        </div>
      </div>

      <div className="mt-2 mb-2 pl-2 pr-2">
        <GoBtn title="Вернуться на главную" />
      </div>
    </div>
  );
};

export default ErleadaAbout;
