import React from 'react';
import GoBtn from '../components/gobtn';
import GoBtnArrow from '../components/gobtnarrow';
import NavHead from '../components/navhead';

const Medications = () => {
  return (
    <>
      <div className="content_x__block">
        <div className="content_x">
          <NavHead title="Препараты" />
          <div className="content mt-2 pl-2 pr-2">
            <div>
              <GoBtnArrow
                url="/erleada"
                title="Эрлеада"
                ymt="page_mechan"
              />
            </div>
            <div className="mt-2">
              <GoBtnArrow
                url="/balversa"
                title="Балверса"
                ymt="page_instruction"
              />
            </div>
            
          </div>
        </div>
        <div className="mt-2 pl-2 pr-2 back_btn_x">
          <GoBtn title="Вернуться на главную" />
        </div>
      </div>
    </>
  );
};

export default Medications;
